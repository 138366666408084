// constants/pages/company/GetCompanySameSector.ts
export const FETCH_EMPRESAS_SAME_SECTOR_REQUEST = 'FETCH_EMPRESAS_SAME_SECTOR_REQUEST';
export const FETCH_EMPRESAS_SAME_SECTOR_SUCCESS = 'FETCH_EMPRESAS_SAME_SECTOR_SUCCESS';
export const FETCH_EMPRESAS_SAME_SECTOR_FAILURE = 'FETCH_EMPRESAS_SAME_SECTOR_FAILURE';

export interface FetchEmpresasSameSectorRequestAction {
  type: typeof FETCH_EMPRESAS_SAME_SECTOR_REQUEST;
}

export interface FetchEmpresasSameSectorSuccessAction {
  type: typeof FETCH_EMPRESAS_SAME_SECTOR_SUCCESS;
  payload: { data: any[] };
}

export interface FetchEmpresasSameSectorFailureAction {
  type: typeof FETCH_EMPRESAS_SAME_SECTOR_FAILURE;
  payload: string;
}

export type EmpresasSameSectorActionTypes =
  | FetchEmpresasSameSectorRequestAction
  | FetchEmpresasSameSectorSuccessAction
  | FetchEmpresasSameSectorFailureAction;
