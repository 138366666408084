import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Button, Row, Col } from 'antd'; // Importa Row y Col de Ant Design
import logo from '../../../assets/logo/logo.png';
import IconNotification from '../../../assets/icons/notification.svg';
import '../../styles/pages/principalNav/PrincipalNav.css';
import Employment from '../../../pages/employment/Employment';
import Company from '../../../pages/company/company/Company';
import MyApplications from '../../../pages/myApplications/MyApplications';
import Portal from '../../../pages/myPortal/MyPortal'; 
import Home from '../../../pages/home/Home';
import ValuationModal from '../../../components/pages/modals/ModalQR';
import { AppDispatch } from '../../../redux/store/store';
import Notifications from '../../../pages/notifications/NotificationsRoute';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotificationsReducer } from '../../../redux/actions/common/notificacions/Notifications';

const PrincipalNav = () => {

  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  const { rex_user } = useSelector(({ auth }: any) => auth);

  const isActive = (path: any) => location.pathname.startsWith(path);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => { 
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  }

  useEffect(() => {
    // dispatch(fetchNotificationsReducer())
  },[])

  return (
    <div className="principal-nav">
      <header className="principal-nav-header">
        <Row  justify="space-between" align="middle" className="principal-nav-header-row mb-4">

          <Col xs={24} sm={24} md={3} lg={4} xxl={4} className="principal-nav-logo-col">
            <Link to="/home">
              <img src={logo} alt="Merere Logo" className="principal-nav-logo" />
            </Link>
          </Col>

          <Col xs={24} sm={24} md={21} lg={20} xxl={14} className="principal-nav-links-col">
            <nav className="principal-nav-nav ok">
              <Row gutter={[16,16]} className='w-full justify-center'>
                <Col className='flex items-center justify-center basis-1/2 md:basis-0'>
                  <Link
                    to="/employment/recommendations"
                    className={`principal-nav-link w-max ${isActive('/employment') ? 'active' : ''}`}
                  >
                    Empleo
                  </Link>

                </Col>
                <Col className='flex items-center justify-center basis-1/2 md:basis-0'>
                  <Link
                    to="/company"
                    className={`principal-nav-link w-max ${isActive('/company') ? 'active' : ''}`}
                  >
                    Empresas
                  </Link>

                </Col>
                  
                <Col className='flex items-center justify-center basis-1/2 md:basis-0'>
                  <Link
                    to="/myApplications/applications"
                    className={`principal-nav-link flex text-center justify-center w-max ${isActive('/myApplications') ? 'active' : ''}`}
                  >
                    Mis Candidaturas
                  </Link>
                </Col>
                <Col className='flex items-center justify-center basis-1/2 md:basis-0'>
                  <Link
                    to="/myPortal/profile"
                    className={`principal-nav-link w-max ${isActive('/myPortal') ? 'active' : ''}`}
                  >
                    Mi Portal
                  </Link>
                </Col>
              </Row>


            </nav>
          </Col>

          
          <Col xs={24} sm={24} md={24} lg={24} xxl={6} className="principal-nav-action-buttons-col">
            <Row justify="end" className="principal-nav-action-buttons">
              <Col>
                <Link to="/notifications/all">
                  <Button className="principal-nav-bell-button-1 !w-[44px] h-[44px]" icon={<img src={IconNotification} alt="Notification" />} />
                </Link>
              </Col>
              <Col>
                <Button
                  className="principal-nav-notify-button"
                  onClick={showModal}
                >
                  Permite que te valoren
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </header>

      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/employment" element={<Employment />} />
        <Route path="/company" element={<Company />} />
        <Route path="/myApplications" element={<MyApplications />} />
        <Route path="/myPortal" element={<Portal />} />
      </Routes>


      <ValuationModal
        visible={isModalVisible}
        onClose={closeModal}
        link={rex_user?.link_valoracion}
        tipoUsuarioId={rex_user?.tipo_usuario_id}// Adjust this based on your logic
      />

    </div>
  );
};

export default PrincipalNav;
