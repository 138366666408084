import React, { useState, useEffect } from 'react';
import { Row, Col, Affix } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { GetApplicationsReducer } from '../../../redux/actions/applications/GetApplications';
import { RootState } from '../../../redux/store/store';
import CardEmpleo from '../../../components/cards/CardEmployment';
import InformationNotInterested from '../../../components/pages/myApplications/applications/InformationApplication';
import FilterButtons from '../../../components/pages/myApplications/applications/FilterButtonsApplications';

interface CardEmpleoExampleProps {
  searchInitiated: boolean;
  selectedSectores?: string | null; // Añadir sector
  ubi_poblacion?: string | null; // Añadir ubicación población
  ubi_provincia?: string | null; // Añadir ubicación provincia
}

const CardEmpleoExample: React.FC<CardEmpleoExampleProps> = ({
  searchInitiated,
  selectedSectores,
  ubi_poblacion,
  ubi_provincia,
}) => {
  const dispatch = useDispatch();
  const { rex_data: applications, rex_loading: loading } = useSelector((state: RootState) => state.applications);
  const { rex_applications: searchResults, rex_loading: searchLoading } = useSelector((state: RootState) => state.searchApplication);

  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);
  const [appliedCards, setAppliedCards] = useState<{ [key: number]: boolean }>({});
  const [loadingCards, setLoadingCards] = useState<{ [key: number]: boolean }>({});
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false);

  useEffect(() => {
    if (!searchInitiated) {
      dispatch(GetApplicationsReducer() as any); // Cargar todas las aplicaciones al inicio si no hay búsqueda
    }
  }, [searchInitiated]);

  useEffect(() => {
    if (!loading && applications.length) {
      const initialLoadingCards = applications.reduce((acc: any, _, index: number) => {
        acc[index] = false;
        return acc;
      }, {});
      setLoadingCards(initialLoadingCards);

      const initialAppliedCards = applications.reduce((acc: any, _, index: number) => {
        acc[index] = true; // Todas las tarjetas están aplicadas
        return acc;
      }, {});
      setAppliedCards(initialAppliedCards);
    }
  }, [applications, loading]);

  const handleCardClick = (index: number) => {
    setLoadingInfo(true);
    setSelectedCardIndex(index);
    setLoadingInfo(false);
    // setTimeout(() => {
    // }, 2000); // Simulación de 2 segundos de carga
  };

  const handleApply = (index: number | null) => {
    if (index !== null) {
      setAppliedCards((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    }
  };

  const handleCategoriaClick = (valor: string) => {
    alert(`Categoría seleccionada: ${valor}`);
  };

  const categorias = [
    { valor: 'todas-las-candidaturas', etiqueta: 'Todas las candidaturas' },
    { valor: 'Visto-por-la-empresa', etiqueta: 'Visto por la empresa' },
    { valor: 'cv-descargado', etiqueta: 'CV descargado' },
    { valor: 'avanzas-en-el-proceso', etiqueta: 'Avanzas en el proceso' },
    { valor: 'descartado', etiqueta: 'Descartado' },
  ];

  // Filtrar aplicaciones según los parámetros de búsqueda
  const applicationsToShow = searchInitiated ? searchResults : applications;

  // Aquí podrías agregar lógica para filtrar 'applicationsToShow' si tienes los parámetros `selectedSectores`, `ubi_poblacion`, y `ubi_provincia`
  // Filtrar aplicaciones según los parámetros de búsqueda
  const filteredApplications = applicationsToShow.filter(application => {
    const matchesSector = selectedSectores
      ? application.ofertas?.sectores?.some((sector: { sector: string }) => sector.sector === selectedSectores)
      : true;

    const matchesUbicacionPoblacion = ubi_poblacion ? application.ofertas?.ubi_poblacion === ubi_poblacion : true;
    const matchesUbicacionProvincia = ubi_provincia ? application.ofertas?.ubi_provincia === ubi_provincia : true;

    return matchesSector && matchesUbicacionPoblacion && matchesUbicacionProvincia;
  });

  return (
    <div style={{ padding: '10px 0' }}>
      <FilterButtons
        categorias={categorias}
        alClicEnCategoria={handleCategoriaClick}
      />
      <Row gutter={[16, 16]}>
        <Col xs={24} md={10}>
          {(searchLoading || loading) ? (
            <div>Cargando datos...</div>
          ) : filteredApplications.length === 0 ? (
            <div>No se encontraron resultados para el término de búsqueda.</div>
          ) : (
            filteredApplications.map((application, index) => (
              <div
                key={index}
                className={`cursor-pointer mb-5 p-4 rounded-lg transition-shadow duration-300 ${selectedCardIndex === index
                  ? "shadow-lg" // Sombra intensa si la card está seleccionada
                  : "hover:shadow-md" // Sombra leve cuando se hace hover sobre la card
                  }`}
                onClick={() => {
                  // console.log(application);
                  handleCardClick(index)
                }}
              >
                {/* <CardEmpleo
                  companyName={application.ofertas.empresas.empresa}
                  jobTitle={application.ofertas?.cargo || `Oferta ID: ${application.oferta_id}`}
                  location={application.ofertas?.ubi_poblacion || 'Ubicación no disponible'}
                  salary={application.ofertas?.sal_min !== null ? `${application.ofertas.sal_min}€ - ${application.ofertas.sal_max}€` : 'Salario no disponible'}
                  applied={true}
                  loading={loadingCards[index]}
                  currentStep={application.oferta_id}
                  schedule={application.ofertas.jornada_laboral}
                  ratings={application.ofertas.empresas._count.valoraciones_empresas + " valoraciones"}
                  activeOffers={application.ofertas.empresas._count.ofertas + " ofertas activas"}
                  followers={application.ofertas.empresas._count.empresa_seguida + " seguidores"}
                  description={application.ofertas?.descripcion || 'Descripción no disponible'}
                  postedTime="Subida hace 10 días"
                  createdAt={application?.ofertas?.createdAt}
                /> */}

                <div className='hidden md:flex'>
                  <CardEmpleo
                    companyName={application.ofertas.empresas.empresa}
                    jobTitle={application.ofertas?.cargo || `Oferta ID: ${application.oferta_id}`}
                    location={application.ofertas?.ubi_poblacion || 'Ubicación no disponible'}
                    salary={application.ofertas?.sal_min !== null ? `${application.ofertas.sal_min}€ - ${application.ofertas.sal_max}€` : 'Salario no disponible'}
                    applied={true}
                    loading={loadingCards[index]}
                    currentStep={application.oferta_id}
                    schedule={application.ofertas.jornada_laboral}
                    ratings={application.ofertas.empresas._count.valoraciones_empresas + " valoraciones"}
                    activeOffers={application.ofertas.empresas._count.ofertas + " ofertas activas"}
                    followers={application.ofertas.empresas._count.empresa_seguida + " seguidores"}
                    description={application.ofertas?.descripcion || 'Descripción no disponible'}
                    postedTime=""
                    createdAt={application?.ofertas?.createdAt}
                    styleType="type4"
                    dataComplete={application}
                    avatar={application?.ofertas?.empresas?.logo}
                  />
                </div>

                <div className='flex md:hidden'>

                  {
                    selectedCardIndex === index
                      ? <InformationNotInterested
                        title={` ${applications[selectedCardIndex]?.ofertas?.cargo || 'Cargo no disponible'}`}
                        company={`${applications[selectedCardIndex]?.ofertas.empresas.empresa || 'ID de empresa no disponible'}`}
                        location={applications[selectedCardIndex]?.ofertas?.ubi_poblacion || 'Ubicación no disponible'}
                        employmentType={applications[selectedCardIndex]?.ofertas?.jornada_laboral || 'Tipo de empleo no disponible'}
                        salary={
                          filteredApplications[selectedCardIndex]?.ofertas?.sal_min !== null
                            ? `${filteredApplications[selectedCardIndex].ofertas.sal_min}€ - ${filteredApplications[selectedCardIndex].ofertas.sal_max}€`
                            : 'Salario no disponible'
                        }
                        comments={0}
                        applicantsCount={applications[selectedCardIndex]?.ofertas?._count.postulaciones + " Inscritos"}
                        introText="Auxilium comparo odit. Vado vomica laboriosam similique cattus teres dolorum video nostrum derideo. Cresco solum coniuratio pectus ventito cohibeo spiculum animi debitis."
                        requirements={[
                          {
                            title: "Estudios mínimos",
                            requirement: applications[selectedCardIndex]?.ofertas.estudios_minimos,
                          },
                          // {
                          //   title: "Jornada laboral",
                          //   requirement: applications[selectedCardIndex]?.ofertas.jornada_laboral,
                          // },
                          // {
                          //   title: "Tipo de contrato",
                          //   requirement: applications[selectedCardIndex]?.ofertas.tipo_contrato,
                          // },
                          {
                            title: "Años de experiencia",
                            requirement: applications[selectedCardIndex]?.ofertas.anios_experiencia == 0 ? "Sin experiencia" : applications[selectedCardIndex]?.ofertas.anios_experiencia,
                          }
                        ]}
                        responsibilities={
                          [
                            applications[selectedCardIndex]?.ofertas.descripcion || 'Descripción no disponible'
                          ]
                        }
                        extraText={[""]}
                        extraText2={["Muchos más te están esperando, ¡descúbrelos!"]}
                        postedTime="Subida hace x días"
                        applied={!!appliedCards[selectedCardIndex]}
                        loading={loadingInfo}
                        onApply={() => handleApply(selectedCardIndex)}
                        createdAt={applications[selectedCardIndex]['createdAt']}
                        sector={applications[selectedCardIndex].ofertas.sectores.sector}
                        aptitudes={applications[selectedCardIndex].ofertas.aptitudes_oferta}
                        avatar={applications[selectedCardIndex]?.ofertas?.empresas?.logo}
                      />
                      : <CardEmpleo
                        companyName={`Empresa ID: ${application.empresa_id}`}
                        jobTitle={application.ofertas?.cargo || `Oferta ID: ${application.oferta_id}`}
                        location={application.ofertas?.ubi_poblacion || 'Ubicación no disponible'}
                        salary={application.ofertas?.sal_min !== null ? `${application.ofertas.sal_min}€ - ${application.ofertas.sal_max}€` : 'Salario no disponible'}
                        applied={true}
                        loading={loadingCards[index]}
                        currentStep={application.oferta_id}
                        schedule="9:00 am - 5:00 pm"
                        ratings="0 valoraciones"
                        activeOffers="0 ofertas activas"
                        followers="0 seguidores"
                        description={application.ofertas?.descripcion || 'Descripción no disponible'}
                        postedTime="Subida hace 10 días"
                        createdAt={application?.ofertas?.createdAt}
                        dataComplete={application}
                        avatar={application?.ofertas?.empresas?.logo}
                      // postedTime={`Subida hace ${Math.floor(Math.random() * 30)} días`} // Estático
                      />
                  }
                </div>
              </div>
            ))
          )}
        </Col>
        <Col xs={0} md={14}>
          {selectedCardIndex !== null && (
            <Affix
              offsetTop={140}
            >
              <InformationNotInterested
                title={` ${applications[selectedCardIndex]?.ofertas?.cargo || 'Cargo no disponible'}`}
                company={`${applications[selectedCardIndex]?.ofertas.empresas.empresa || 'ID de empresa no disponible'}`}
                location={applications[selectedCardIndex]?.ofertas?.ubi_poblacion || 'Ubicación no disponible'}
                employmentType={applications[selectedCardIndex]?.ofertas?.jornada_laboral || 'Tipo de empleo no disponible'}
                salary={
                  filteredApplications[selectedCardIndex]?.ofertas?.sal_min !== null
                    ? `${filteredApplications[selectedCardIndex].ofertas.sal_min}€ - ${filteredApplications[selectedCardIndex].ofertas.sal_max}€`
                    : 'Salario no disponible'
                }
                comments={0}
                applicantsCount={applications[selectedCardIndex]?.ofertas?._count.postulaciones + " Inscritos"}
                introText="Auxilium comparo odit. Vado vomica laboriosam similique cattus teres dolorum video nostrum derideo. Cresco solum coniuratio pectus ventito cohibeo spiculum animi debitis."
                requirements={[
                  {
                    title: "Estudios mínimos",
                    requirement: applications[selectedCardIndex]?.ofertas.estudios_minimos,
                  },
                  // {
                  //   title: "Jornada laboral",
                  //   requirement: applications[selectedCardIndex]?.ofertas.jornada_laboral,
                  // },
                  // {
                  //   title: "Tipo de contrato",
                  //   requirement: applications[selectedCardIndex]?.ofertas.tipo_contrato,
                  // },
                  {
                    title: "Años de experiencia",
                    requirement: applications[selectedCardIndex]?.ofertas.anios_experiencia == 0 ? "Sin experiencia" : applications[selectedCardIndex]?.ofertas.anios_experiencia,
                  }
                ]}
                responsibilities={
                  [
                    applications[selectedCardIndex]?.ofertas.descripcion || 'Descripción no disponible'
                  ]
                }
                extraText={[""]}
                extraText2={["Muchos más te están esperando, ¡descúbrelos!"]}
                postedTime="Subida hace x días"
                applied={!!appliedCards[selectedCardIndex]}
                loading={loadingInfo}
                onApply={() => handleApply(selectedCardIndex)}
                createdAt={applications[selectedCardIndex]['createdAt']}
                sector={applications[selectedCardIndex].ofertas.sectores.sector}
                aptitudes={applications[selectedCardIndex].ofertas.aptitudes_oferta}
                avatar={applications[selectedCardIndex]?.ofertas?.empresas?.logo}
              />
            </Affix>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CardEmpleoExample;
