// reducers/ClosedOffersReducer.ts

import {
    SEARCH_CLOSED_OFFERS_REQUEST,
    SEARCH_CLOSED_OFFERS_SUCCESS,
    SEARCH_CLOSED_OFFERS_FAILURE,
    ClosedOfferActionTypes,
  } from '../../../../constants/company/SearchFinishedOffers';
  
  interface ClosedOffersState {
    closedOffers: any[];
    loading: boolean;
    error: string | null;
    meta: { total: number; limit: number; page: number } | null;
  }
  
  const initialState: ClosedOffersState = {
    closedOffers: [],
    loading: false,
    error: null,
    meta: null,
  };
  
const closedOffersReducer = (state = initialState, action: ClosedOfferActionTypes): ClosedOffersState => {
    switch (action.type) {
      case SEARCH_CLOSED_OFFERS_REQUEST:
        return { ...state, loading: true, error: null };
  
      case SEARCH_CLOSED_OFFERS_SUCCESS:
        return {
          ...state,
          closedOffers: action.payload.data,
          loading: false,
          meta: action.payload.meta,
          error: null,
        };
  
      case SEARCH_CLOSED_OFFERS_FAILURE:
        return { ...state, loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  export default closedOffersReducer;