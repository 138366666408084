// reducers/pages/company/ofertasByEmpresaReducer.ts
import {
    FETCH_EMPRESAS_SAME_SECTOR_REQUEST,
    FETCH_EMPRESAS_SAME_SECTOR_SUCCESS,
    FETCH_EMPRESAS_SAME_SECTOR_FAILURE,
    EmpresasSameSectorActionTypes,
  } from '../../../../constants/pages/company/GetCompanySame';
  
  interface EmpresasSameSectorState {
    loading: boolean;
    data: any[];
    error: string | null;
  }
  
  const initialState: EmpresasSameSectorState = {
    loading: false,
    data: [],
    error: null,
  };
  
  const empresasSameSectorReducer = (
    state = initialState,
    action: EmpresasSameSectorActionTypes
  ): EmpresasSameSectorState => {
    switch (action.type) {
      case FETCH_EMPRESAS_SAME_SECTOR_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_EMPRESAS_SAME_SECTOR_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload.data,
        };
      case FETCH_EMPRESAS_SAME_SECTOR_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default empresasSameSectorReducer;
  