import React, { useEffect, useState } from 'react';
import { Input, Row, Col, Button } from 'antd';
import IconSearch from '../../assets/icons/search.svg';
import IconApplications from '../../assets/icons/applications.svg';
import IconSaved from '../../assets/icons/saved.svg';
import IconYourAlerts from '../../assets/icons/alertsC.svg';
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom';
import Applications from '../myApplications/applications/Applications';
import Saved from '../myApplications/saved/Saved';
import YourAlert from '../myApplications/yourAlerts/YourAlerts';
import '../../styles/pages/myApplications/MyApplications.css';
import '../../styles/pages/employment/FiltersEmployment.css';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import { GetSectorsReducer } from "../../redux/actions/common/sectors/Sectors";
import Select from "../../components/pages/offers/CreateOffers/Select";
import SelectBasic from '../../components/pages/offers/CreateOffers/SelectBasic';
import { GetPaisesReducer } from '../../redux/actions/pages/emplotment/searchCountry';
import { searchApplicationsByCargo } from '../../redux/actions/applications/SearchApllications';
import { searchSavedOffers } from '../../redux/actions/applications/SearchSavedApplications';
import { fetchApplicationPhasesReducer } from '../../redux/actions/common/fase/GetApplicationPhases';

const MyApplications: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const {
    rex_loading,
    rex_paises
  } = useSelector(({ paises }: any) => paises);

  const [clickedInput, setClickedInput] = useState<string | null>(null);
  const [selectedSectores, setSelectedSectores] = useState<string | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [availableCities, setAvailableCities] = useState<any[]>([]);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState(''); // Almacena el término de búsqueda
  const [searchInitiated, setSearchInitiated] = useState(false);

  const handleInputClick = (name: string) => {
    setClickedInput(prev => (prev === name ? null : name));
  };

  const handleSectorSelect = (newValue: any) => {
    const selectedItems = Array.isArray(newValue) ? newValue : [newValue];
    const selectedLabels = selectedItems.map(
      (item: any) => item.label || item.value
    );
    setSelectedSectores(selectedLabels[0] || null); // Ajusta para seleccionar solo un sector
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Realiza la búsqueda al hacer clic en "Buscar"
  const handleSearch = () => {
    setSearchInitiated(true);
    const ubi_poblacion = selectedCity || undefined;
    const ubi_provincia = selectedCountry || undefined;

    // Construimos los parámetros de la URL para reflejar los filtros de búsqueda
    const queryParams = new URLSearchParams({
      cargo: searchTerm,
      sector: selectedSectores || '',
      ubi_poblacion: ubi_poblacion || '',
      ubi_provincia: ubi_provincia || '',
    });

    // Verificamos la ruta y ejecutamos la acción de búsqueda
    if (location.pathname === '/myApplications/applications') {
      dispatch(searchApplicationsByCargo(searchTerm, selectedSectores || undefined, ubi_poblacion, ubi_provincia, 1, 10));
      navigate(`/myApplications/applications?${queryParams.toString()}`);
    } else if (location.pathname === '/myApplications/saved') {
      dispatch(
        searchSavedOffers(
          searchTerm,
          selectedSectores || undefined,
          ubi_poblacion,
          ubi_provincia,
          1,
          10
        )
      );
      navigate(`/myApplications/saved?${queryParams.toString()}`);
    }
  };


  useEffect(() => {
    if (selectedCountry && rex_paises.length > 0) {
      const country = rex_paises.find((c: any) => c.pais === selectedCountry);
      setAvailableCities(country ? country.ciudades : []);
    } else {
      setAvailableCities([]);
    }
  }, [selectedCountry, rex_paises]);

  useEffect(() => {
    dispatch(GetPaisesReducer());
    dispatch(fetchApplicationPhasesReducer());
  }, []);

  return (
    <>

      <div className="search-bar-merere">
        <Row gutter={5} align="middle" style={{ width: '100%' }}>
          <Col xl={24} md={24}>
            <Row gutter={[12, 12]} >
              <Col sm={24} md={12} lg={5} className="w-full">
                <Input
                  prefix={<img src={IconSearch} width={24} height={24} />}
                  placeholder="Buscar empleo de..."
                  className={`Input-Filter-Employment  mr-[8px] ${clickedInput === "search" ? "clicked" : ""
                    }`}
                  onClick={() => handleInputClick("search")}
                  value={searchTerm}
                  onChange={handleSearchInput}
                />
              </Col>

              <Col xs={12} sm={12} md={12} lg={4} xxl={3}>
                <Select
                  showSearch
                  placeholder="Sector (Opcional)"
                  size="large"
                  fetchOptions={async (search) => {
                    const response = await dispatch(GetSectorsReducer(search, 1, 5));
                    return response.payload.data.map((sector: any) => ({
                      label: sector.sector,
                      value: sector.id,
                    }));
                  }}
                  onChange={handleSectorSelect}
                  style={{ height: "44px" }}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xxl={3}>
                <SelectBasic
                  placeholder="País"
                  style={{ height: '44px' }}
                  onChange={(value) => setSelectedCountry(value)}
                  loading={rex_loading}
                  options={rex_paises.map((country: any) => ({
                    value: country.pais,
                    label: country.pais
                  }))}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xxl={3}>
                <SelectBasic
                  placeholder="Ciudad"
                  style={{ height: '44px' }}
                  disabled={!selectedCountry}
                  options={availableCities.map((city: any) => ({
                    value: city.ciudad,
                    label: city.ciudad
                  }))}
                />
              </Col>
              <Col xs={12} sm={12} md={24} lg={3} xxl={2}>
                <div
                  className={`Button-Filter-Employment h-[44px] ${clickedInput === "button" ? "clicked" : ""
                    }`}
                  onClick={handleSearch}
                >
                  Buscar
                </div>
              </Col>
            </Row>

          </Col>
        </Row>
      </div>

      <div className='px-[20px]'>
        <div className="tabs-merere">
          <div className="tab-links-merere">
            <button
              className={
                `tab-link-merere ${location.pathname === '/myApplications/applications' ? 'active-tab-merere' : ''}`
              }
              onClick={() => navigate('/myApplications/applications')}
            >
              <img src={IconApplications} className="icon-merere" /> Candidaturas
            </button>
            <button
              className={`tab-link-merere ${location.pathname === '/myApplications/saved' ? 'active-tab-merere' : ''
                }`}
              onClick={() => navigate('/myApplications/saved')}
            >
              <img src={IconSaved} className="icon-merere" /> Guardadas
            </button>
            <button
              className={`tab-link-merere ${location.pathname === '/myApplications/your-alerts' ? 'active-tab-merere' : ''
                }`}
              onClick={() => navigate('/myApplications/your-alerts')}
            >
              <img src={IconYourAlerts} className="icon-merere" /> Tus alertas
            </button>
          </div>
          <div className="tab-content-merere">
            <Routes>
              <Route path="applications" element={<Applications searchInitiated={searchInitiated} />} />
              <Route path="saved" element={<Saved searchInitiated={searchInitiated} />} />
              <Route path="your-alerts" element={<YourAlert />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyApplications;
