import {
  FETCH_CV_GENERAL_REQUEST,
  FETCH_CV_GENERAL_SUCCESS,
  FETCH_CV_GENERAL_FAILURE,
  UPDATE_USER_GRAPH_SOFT_SKILLS,
  SET_FILTER_PARAMS,
  CvGeneralActionTypes,
  CvGeneral,
  SoftSkillsUsersTable,
  LabelsGraphSoftSkills,
  DataSoftSkill,
  UPDATE_CV_GENERAL
} from '../../../../constants/pages/searchCV/GetSearchCV';

interface CvGeneralState {
  loading: boolean;
  cvGeneral: CvGeneral[];
  error: string | null;
  rex_limit: number;
  rex_page: number;
  rex_total: number;
  filterParams: any;
  rex_users_graph_sk : { data : DataSoftSkill[], users:UserGraph[]},
  labels_graph_sk : LabelsGraphSoftSkills[]
}

interface UserGraph {
  nombre:string;
  id:number;
  nameKey:string
}

const initialState: CvGeneralState = {
  loading: false,
  cvGeneral: [],
  error: null,
  rex_limit: 10,
  rex_page: 1,
  rex_total: 20,
  filterParams: {},
  labels_graph_sk : [
    { id : 1, nameKey: '', color: '#FDBCB4' },
    { id : 2, nameKey: '', color: '#9DDEF4' },
    { id : 3, nameKey: '', color: '#C2A5F9' },
    { id : 4, nameKey: '', color: '#FFB3DA' },
    { id : 5, nameKey: '', color: '#73FF70' },
    { id : 6, nameKey: '', color: '#FFE870' },
    { id : 7, nameKey: '', color: '#FF8A70' },
    { id : 8, nameKey: '', color: '#E2D1DA' },
    { id : 9, nameKey: '', color: '#85EFAC' },
    { id : 10, nameKey: '', color: '#B58392' },
    { id : 11, nameKey: '', color: '#FDBA72' },
  ], 
  rex_users_graph_sk: {data : [], users: []}
};


export const cvGeneralReducer = (
  state = initialState,
  action: CvGeneralActionTypes
): CvGeneralState => {
  switch (action.type) {
    case FETCH_CV_GENERAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CV_GENERAL_SUCCESS:
      let data_users_graph
      if(action.payload.resetUserGraph){
        data_users_graph = {data : [], users: []}
      }else{
        data_users_graph = state.rex_users_graph_sk
      }
      return {
        ...state,
        loading: false,
        cvGeneral: action.payload.meta.page == 1 ? [...action.payload.data] : [...state.cvGeneral, ...action.payload.data],
        rex_total: action.payload.meta.total,
        rex_limit: action.payload.meta.limit,
        rex_page: action.payload.meta.page,
        rex_users_graph_sk : data_users_graph,
        error: null,
      };
    case FETCH_CV_GENERAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        cvGeneral: [],
      };
    case UPDATE_CV_GENERAL:
      return {
        ...state,
        cvGeneral: action.payload,
      };
    case UPDATE_USER_GRAPH_SOFT_SKILLS:
      return {
        ...state,
        rex_users_graph_sk : action.payload
      };
    case SET_FILTER_PARAMS: // Agrega el case para manejar los filtros
      return {
        ...state,
        filterParams: action.payload, // Actualiza los filtros
        cvGeneral: [], // Reinicia las empresas al aplicar un nuevo filtro
      };
    default:
      return state;
  }
};

export default cvGeneralReducer;