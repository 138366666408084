// Constantes para las acciones relacionadas con "Notificaciones"
export const FETCH_NOTIFICACTIONS_REQUEST = 'FETCH_NOTIFICACTIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

export const FETCH_RESTRICT_NOTIFICATIONS_REQUEST = 'FETCH_RESTRICT_NOTIFICATIONS_REQUEST';
export const FETCH_RESTRICT_NOTIFICATIONS_SUCCESS = 'FETCH_RESTRICT_NOTIFICATIONS_SUCCESS';
export const FETCH_RESTRICT_NOTIFICATIONS_FAILURE = 'FETCH_RESTRICT_NOTIFICATIONS_FAILURE';

export const FETCH_INFO_RESTRICT_NOTIFICATIONS_REQUEST = 'FETCH_INFO_RESTRICT_NOTIFICATIONS_REQUEST';
export const FETCH_INFO_RESTRICT_NOTIFICATIONS_SUCCESS = 'FETCH_INFO_RESTRICT_NOTIFICATIONS_SUCCESS';
export const FETCH_INFO_RESTRICT_NOTIFICATIONS_FAILURE = 'FETCH_INFO_RESTRICT_NOTIFICATIONS_FAILURE';

export const DELETE_NOTIFICATIONS_REQUEST = 'DELETE_NOTIFICATIONS_REQUEST';
export const DELETE_NOTIFICATIONS_SUCCESS = 'DELETE_NOTIFICATIONS_SUCCESS';
export const DELETE_NOTIFICATIONS_FAILURE = 'DELETE_NOTIFICATIONS_FAILURE';

interface FetchNotificationsRequestAction {
  type: typeof FETCH_NOTIFICACTIONS_REQUEST;
}

interface FetchNotificationsSuccessAction {
  type: typeof FETCH_NOTIFICATIONS_SUCCESS;
  payload: {
    data: any[];
  };
}

interface FetchNotificationsFailureAction {
  type: typeof FETCH_NOTIFICATIONS_FAILURE;
  payload: string;
}

interface DeleteNotificationsRequestAction {
  type: typeof DELETE_NOTIFICATIONS_REQUEST;
}

interface DeleteNotificationsSuccessAction {
  type: typeof DELETE_NOTIFICATIONS_SUCCESS;
  payload: number;
}

interface DeleteNotificationsFailureAction {
  type: typeof DELETE_NOTIFICATIONS_FAILURE;
  payload: string;
}

interface FetchRestrictNotificationRequestAction {
  type: typeof FETCH_RESTRICT_NOTIFICATIONS_REQUEST;
}

interface FetchRestrictNotificationSuccessAction {
  type: typeof FETCH_RESTRICT_NOTIFICATIONS_SUCCESS;
  payload: number;
}

interface FetchRestrictNotificationFailureAction {
  type: typeof FETCH_RESTRICT_NOTIFICATIONS_FAILURE;
  payload: string;
}

interface FetchInfoRestrictNotificationRequestAction {
  type: typeof FETCH_INFO_RESTRICT_NOTIFICATIONS_REQUEST;
}

interface FetchInfoRestrictNotificationSuccessAction {
  type: typeof FETCH_INFO_RESTRICT_NOTIFICATIONS_SUCCESS;
  payload: {
    data: any[];
  };
}

interface FetchInfoRestrictNotificationFailureAction {
  type: typeof FETCH_INFO_RESTRICT_NOTIFICATIONS_FAILURE;
  payload: string;
}

// Union Type para todas las posibles acciones de "Notificaciones"
export type NotificationsActionTypes = 
  | FetchNotificationsRequestAction
  | FetchNotificationsSuccessAction
  | FetchNotificationsFailureAction
  | DeleteNotificationsRequestAction
  | DeleteNotificationsSuccessAction
  | DeleteNotificationsFailureAction
  | FetchRestrictNotificationRequestAction
  | FetchRestrictNotificationSuccessAction
  | FetchRestrictNotificationFailureAction
  | FetchInfoRestrictNotificationRequestAction
  | FetchInfoRestrictNotificationSuccessAction
  | FetchInfoRestrictNotificationFailureAction;