import React, { useState } from 'react';
import { Card, Avatar, Button, Skeleton, Row, Col, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import 'tailwindcss/tailwind.css';
import IconLocation2 from '../../assets/icons/location2.svg';
import IconTypemoney1 from '../../assets/icons/typemoney1.svg';
import IconClock from '../../assets/icons/clock.svg';
import IconUsers from '../../assets/icons/IconUsers.svg';
import IconB from '../../assets/icons/IconB.svg';
import IconCheck from '../../assets/icons/IconCheck.svg';
import IconC from '../../assets/icons/IconC.svg'
import IconSavedOffer from '../../assets/icons/saved.svg'
import DiferenciaFecha from '../../utils/CalculateDifferentTime';
import IconOfertas from '../../assets/icons/ofertas.svg';
import { useSelector } from 'react-redux';

const IMAGE_USER_DEFAULT = process.env.REACT_APP_IMAGE_EMPRESA_DEFAULT;
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

interface CardEmpleoProps {
  companyName: string;
  jobTitle: string;
  location: string;
  salary: string;
  schedule: string;
  ratings: string;
  activeOffers: string;
  followers: string;
  description: string;
  postedTime: string;
  applied: boolean;
  avatarSize?: number;
  // avatarShape?: 'circle' | 'square';
  avatarShape?: string;
  avatar?: string;
  // styleType?: 'default' | 'type1' | 'type2' | 'type4';
  styleType?: string;
  loading?: boolean;
  stepsComponent?: React.ReactNode; // New prop
  currentStep: number; // New prop, required
  createdAt?: string;
  saveOffer?: boolean;
  dataComplete: any
}

const CardEmpleo: React.FC<CardEmpleoProps> = ({
  companyName,
  jobTitle,
  location,
  salary,
  schedule,
  ratings,
  activeOffers,
  followers,
  description,
  postedTime,
  avatarSize = 40,
  // avatarShape = 'circle',
  avatarShape,
  avatar = null,
  applied,
  styleType = 'default',
  loading = false,
  stepsComponent,
  currentStep,
  createdAt,
  saveOffer = false,
  dataComplete
}: any) => {
  const formattedDescription = description?.replace(/(?:\r\n|\r|\n)/g, '<br>');
  const {
    rex_phases,
    rex_loadingp
  } = useSelector(({ getApplicationPhases }: any) => getApplicationPhases);

  return (
    <div className="w-full flex justify-center cursor-pointer">
      <Card
        bordered={false}
        className={`w-full h-auto rounded-lg bg-white pt-3 ${styleType === 'type4' ? 'pb-0' : ''}`}
        style={{ boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)' }}
      >
        {loading ? (
          <Skeleton avatar active paragraph={{ rows: 4 }} />
        ) : (
          <>
            {
              applied
                ? <div className="absolute left-0 top-0 bottom-0 w-2 bg-[#FDBCB4] rounded-l-lg"></div>
                : null
            }
            <div className="flex items-center justify-between mb-2 -mt-7">
              <div className="flex items-center">
                <Avatar
                  size={avatarSize}
                  src={
                    avatar
                      ? API_BASE_URL_EXACT + avatar
                      : IMAGE_USER_DEFAULT
                    // avatar !== null
                    //   ? avatar
                    //   : IconUsers
                  }
                  shape={avatarShape}
                />
                <div className="ml-3">
                  <h3 className="text-sm font-medium m-0">{companyName}</h3>
                  <h4 className="text-base m-0 font-bold">{jobTitle}</h4>
                </div>
              </div>
              <div className="text-xs text-gray-500">
                {
                  saveOffer
                    ? <img src={IconSavedOffer} className="text-sm" />
                    : styleType === 'type4'
                      ? 'Ya no estoy interesado(a)'
                      : styleType === 'type3'
                        ? <img src={IconSavedOffer} className="text-sm" />
                        : <img src={IconC} className="text-lg" />
                }
              </div>
            </div>
            <div className="flex flex-wrap justify-start items-center mb-2 space-x-4">
              <div className="flex items-center font-medium"><img src={IconLocation2} className="p-1" />{location}</div>
              <div className="flex items-center font-medium"><img src={IconTypemoney1} className="p-1" /> {salary}</div>
              <div className="flex items-center font-medium"><img src={IconClock} className="p-1" />{schedule}</div>
            </div>
            <div className="flex flex-wrap justify-start items-center mb-2 gap-2">
              <Button icon={<img src={IconCheck} />} className="border border-gray-300 text-gray-600 text-xs rounded-full pl-2">{ratings}</Button>
              <Button icon={<img src={IconB} />} className="border border-gray-300 text-gray-600 text-xs rounded-full pl-2">{activeOffers}</Button>
              <Button icon={<img src={IconUsers} />} className="border border-gray-300 text-gray-600 text-xs rounded-full pl-2">{followers}</Button>
            </div>

            {
              styleType === 'type4' && (
                <div>
                  <Row>
                    <Col xxl={24} xl={24} md={24}>
                      <div
                        className='flex'
                      >
                        <Col xxl={9} xl={9} md={9}>
                          <div
                            className='flex'
                            style={{
                              alignItems: 'center'
                            }}
                          >
                            <img
                              src={IconOfertas}
                              style={{
                                width: '24px',
                              }}
                              className='mr-1'
                            />
                            <span
                              style={{
                                fontFamily: 'Inter',
                                fontWeight: 'bold',
                                fontSize: '16px'
                              }}
                              className='mr-1'
                            >
                              Inscrito
                            </span>
                            <span
                              style={{
                                color: '#1A1A1A',
                                fontFamily: 'Inter',
                                fontWeight: 'Medium',
                                fontSize: '12px'
                              }}
                            >
                              <DiferenciaFecha fecha={dataComplete.createdAt} />
                              {/* Ahora mismo */}
                            </span>
                          </div>
                        </Col>
                        <Col
                          xxl={15} xl={15} md={15}
                          style={{ alignContent: 'center' }}
                        >
                          <div
                            className="progress-bar-container flex"
                            style={{
                              width: '100%'
                            }}
                          >
                            {
                              rex_phases.map((phase: any, index: number) => {
                                return (
                                  <Tooltip
                                    title={phase.fase}
                                  >
                                    <div
                                      className={`progress-bar h-[8px] rounded-full 
                                  ${index != 0
                                        && "ml-[10px]"
                                        }
                                  ${dataComplete.fases_postulaciones.prioridad >= phase.prioridad
                                          ? "bg-[#006497] w-[100px]"
                                          : "bg-[#E1E1E1] w-[100px]"
                                        }`}
                                    />
                                  </Tooltip>
                                )
                              })
                            }
                            {/* <div
                          className={`progress-bar h-[8px] rounded-full ${dataComplete.fases_postulaciones.prioridad >= 1
                            ? "bg-[#006497] w-[100px]"
                            : "bg-[#E1E1E1] w-[100px]"
                            }`}
                        />
                        <div
                          className={`progress-bar h-[8px] rounded-full ml-[10px] ${dataComplete.fases_postulaciones.prioridad >= 2
                            ? "bg-[#006497] w-[100px]"
                            : "bg-[#E1E1E1] w-[100px]"
                            }`}
                        />
                        <div
                          className={`progress-bar h-[8px] rounded-full ml-[10px] ${dataComplete.fases_postulaciones.prioridad >= 3
                            ? "bg-[#006497] w-[100px]"
                            : "bg-[#E1E1E1] w-[100px]"
                            }`}
                        />
                        <div
                          className={`progress-bar h-[8px] rounded-full ml-[10px] ${dataComplete.fases_postulaciones.prioridad >= 4
                            ? "bg-[#006497] w-[100px]"
                            : "bg-[#E1E1E1] w-[100px]"
                            }`}
                        />
                        <div
                          className={`progress-bar h-[8px] rounded-full ml-[10px] ${dataComplete.fases_postulaciones.prioridad >= 5
                            ? "bg-[#006497] w-[100px]"
                            : "bg-[#E1E1E1] w-[100px]"
                            }`}
                        /> */}
                          </div>
                        </Col>

                      </div>
                    </Col>
                  </Row>
                </div>
              )
            }

            {styleType === 'type4' ? (
              <>
                <p className="text-sm font-sans font-medium mt-2" dangerouslySetInnerHTML={{ __html: formattedDescription }}></p>
                <div className="text-xs text-gray-500 text-right font-medium mt-2">
                </div>
              </>
            ) : (
              <>
                <p className="text-sm font-sans font-medium mt-2" dangerouslySetInnerHTML={{ __html: formattedDescription }}></p>
                <div className="text-xs text-gray-500 text-right font-medium mt-2">
                  {/* {postedTime} */}
                  <DiferenciaFecha fecha={createdAt} />
                </div>
              </>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default CardEmpleo;