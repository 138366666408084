export const SEARCH_SAVED_OFFERS_REQUEST = 'SEARCH_SAVED_OFFERS_REQUEST';
export const SEARCH_SAVED_OFFERS_SUCCESS = 'SEARCH_SAVED_OFFERS_SUCCESS';
export const SEARCH_SAVED_OFFERS_FAILURE = 'SEARCH_SAVED_OFFERS_FAILURE';

// Definición de interfaces para las acciones de `SavedOffers`
interface FetchSavedOffersRequestAction {
  type: typeof SEARCH_SAVED_OFFERS_REQUEST;
}

interface FetchSavedOffersSuccessAction {
  type: typeof SEARCH_SAVED_OFFERS_SUCCESS;
  payload: {
    data: any[];
    meta: { total: number; limit: number; page: number; cargo: string };
  };
}

interface FetchSavedOffersFailureAction {
  type: typeof SEARCH_SAVED_OFFERS_FAILURE;
  payload: string;
}

// Tipo de acción de `SavedOffers` que cubre las tres posibles acciones
export type SavedOfferActionTypes =
  | FetchSavedOffersRequestAction
  | FetchSavedOffersSuccessAction
  | FetchSavedOffersFailureAction;
