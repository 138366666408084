import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { Drawer, Button, Input, Row, Col } from "antd";
import { PlusOutlined, MenuOutlined } from "@ant-design/icons";
import SidebarOffers from "../../components/pages/offers/SidebarOffers";
import IconSearch from "../../assets/icons/search.svg";
import "../../styles/pages/employment/Employment.css";
import "../../styles/pages/employment/FiltersEmployment.css";
import OpenOffers from "./openOffers/OpenOffers";
import SavedOffers from "./savedOffers/SavedOffers";
import FinishedOffers from "./finishedOffers/FinishedOffers";
import { searchOffersAction } from "../../redux/actions/pages/company/SearchOffers";
import { searchSavedOffersAction } from "../../redux/actions/pages/company/SearchSaveOffers";
import { searchClosedOffersAction } from "../../redux/actions/pages/company/SearchFinishedOffers";
import { AppDispatch } from '../../redux/store/store';
import { useDispatch } from 'react-redux';
import "../../components/styles/pages/principalNav/PrincipalNav.css";

const Offers: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInitiated, setSearchInitiated] = useState(false);

  // Este useEffect inicializa las ofertas cuando se monta el componente
  useEffect(() => {
    // Realiza una búsqueda sin ningún término al iniciar
    dispatch(searchOffersAction("", 1, 10));
  }, [dispatch]);

  // Función para manejar la búsqueda al hacer clic en "Buscar"
  const handleSearch = () => {
    setSearchInitiated(true);
    const currentPath = location.pathname;

    if (searchTerm.trim()) {
      if (currentPath.includes("open-offers")) {
        dispatch(searchOffersAction(searchTerm, 1, 10));
        navigate(`/offers/open-offers?cargo=${searchTerm}`);
      } else if (currentPath.includes("saved-offers")) {
        dispatch(searchSavedOffersAction(searchTerm, 1, 10));
        navigate(`/offers/saved-offers?cargo=${searchTerm}`);
      } else if (currentPath.includes("finished-offers")) {
        dispatch(searchClosedOffersAction(searchTerm, 1, 10));
        navigate(`/offers/finished-offers?cargo=${searchTerm}`);
      }
    } else {
      setSearchInitiated(false);
      if (currentPath.includes("open-offers")) {
        dispatch(searchOffersAction("", 1, 10));
      } else if (currentPath.includes("saved-offers")) {
        dispatch(searchSavedOffersAction("", 1, 10));
      } else if (currentPath.includes("finished-offers")) {
        dispatch(searchClosedOffersAction("", 1, 10));
      }
    }
  };


  return (
    <>
      <div className="bg-white px-[24px] py-[48px] relative">
        <div className="flex flex-col md:flex-row">
          <Button
            type="primary"
            className="md:hidden mb-3"
            onClick={() => setDrawerVisible(true)}
            icon={<MenuOutlined />}
          />
          <div style={{ width: "234px", backgroundColor: "white" }} className="hidden md:block p-0">
            <SidebarOffers />
          </div>
          <Drawer
            placement="left"
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
            drawerStyle={{ padding: 0 }}
            width={260}
            className="block md:hidden"
          >
            <SidebarOffers inDrawer />
          </Drawer>

          <div className="flex-1 pl-0 md:pl-[24px]">
            <Row gutter={[16, 16]}>

              <Col md={24} xl={12} className="flex items-start w-full">
                <Input
                  prefix={<img src={IconSearch} width={24} height={24} alt="Buscar" />}
                  placeholder="Buscar oferta por cargo"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="Input-Filter-Employment w-full md:w-[296px] custom-input-company px-2 rounded"
                />
                <Button
                  className="bg-blue3 text-white w-[102px] ml-[18px] principal-nav-notify-button2 rounded-[4px]"
                  onClick={handleSearch}
                >
                  Buscar
                </Button>
              </Col>

              <Col md={24} xl={12} className="flex justify-end w-full">
                <Button
                  className="principal-nav-notify-button w-full md:w-[327px]"
                  onClick={() => navigate("/offer/createOffer")}
                >
                  <PlusOutlined /> Crear una nueva oferta de empleo
                </Button>
              </Col>
            </Row>

            <div className="tabs-merere mt-4">
              <div className="tab-links-merere flex justify-center px-4">
                <button
                  className={`tab-link-merere ${location.pathname === "/offers/open-offers" ? "active-merere" : ""}`}
                  onClick={() => navigate("/offers/open-offers")}
                >
                  <span className="inline">Ofertas Abiertas</span>
                </button>
                <button
                  className={`tab-link-merere ${location.pathname === "/offers/saved-offers" ? "active-merere" : ""}`}
                  onClick={() => navigate("/offers/saved-offers")}
                >
                  Guardadas
                </button>
                <button
                  className={`tab-link-merere ${location.pathname === "/offers/finished-offers" ? "active-merere" : ""}`}
                  onClick={() => navigate("/offers/finished-offers")}
                >
                  Finalizadas
                </button>
              </div>

              <div className="tab-content-merere p-4">
                <Routes>
                  <Route path="open-offers" element={<OpenOffers />} />
                  <Route path="saved-offers" element={<SavedOffers searchInitiated={searchInitiated} />} />
                  <Route path="finished-offers" element={<FinishedOffers searchInitiated={searchInitiated} />} />
                  <Route path="/" element={<Navigate to="open-offers" />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Offers;
