import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
  SEARCH_OFFERS_REQUEST,
  SEARCH_OFFERS_SUCCESS,
  SEARCH_OFFERS_FAILURE,
  OfferActionTypes
} from "../../../../constants/company/SearchOffers";
import FetchWithIP from '../../utils/FetchHeaders';

// Acciones para cambiar el estado de búsqueda
export const fetchOffersRequestReducer = (): OfferActionTypes => ({
  type: SEARCH_OFFERS_REQUEST,
});

export const fetchOffersSuccessReducer = (data: any[], meta: any): OfferActionTypes => ({
  type: SEARCH_OFFERS_SUCCESS,
  payload: { 
    data,
    meta
  },
});

export const fetchOffersFailureReducer = (error: string): OfferActionTypes => ({
  type: SEARCH_OFFERS_FAILURE,
  payload: error,
});

// Thunk Action para buscar ofertas
export const searchOffersAction = (
  searchTerm: string, 
  page: number = 1, 
  limit: number = 10
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {

    // Obtener la empresa del estado de autenticación
    const { rex_user } = getState().auth;
    const empresa_id = rex_user?.empresa?.id;

    if (!empresa_id) {
      console.log("Empresa ID no está disponible. No se puede obtener ofertas.");
      return;
    }

    dispatch(fetchOffersRequestReducer()); // Muestra el estado de carga

    try {
      const response = await FetchWithIP(`ofertas/${empresa_id}/getAllByEmpresaId?sortOrder=desc&cargo=${searchTerm}&page=${page}&limit=${limit}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }

      const responseData = await response.json();

      if (Array.isArray(responseData.data) && responseData.data.length > 0) {
        dispatch(fetchOffersSuccessReducer(responseData.data, responseData.meta));
      } else {
        // Si no hay resultados, enviamos un array vacío
        dispatch(fetchOffersSuccessReducer([], { total: 0 }));
      }

    } catch (error: any) {
      console.error('Error al mostrar las ofertas:', error.message || error);
      dispatch(fetchOffersFailureReducer('Error al mostrar las ofertas'));
    }
  };

