import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Row, Col, Button, Input, Skeleton, Drawer, Card } from "antd";
import { PlusOutlined, MenuOutlined } from "@ant-design/icons";
import Header from "../../components/pages/principalNav/HeaderOffers";
import IconSearch from "../../assets/icons/search.svg";
import IconGPS from "../../assets/icons/IconGPS.svg";
import SidebarSearch from "../../components/pages/searchCV/SidebarSearchCV";
import List from "../../assets/icons/view_list.svg";
import Stream from "../../assets/icons/view_stream.svg";
import SortingOptions from "../../components/pages/searchCV/SortingOptions";
import IconButton from "../../components/pages/searchCV/IconButton";
import TableSearchCV from "../../components/pages/searchCV/TableSearchCV";
import ListSearchCV from "../../components/pages/searchCV/ListSearchCV";
import InfiniteScroll from "react-infinite-scroll-component";
import QuestionModal from "../../components/pages/searchCV/ModalSearch";
import { AppDispatch } from "../../redux/store/store";
import { useDispatch, useSelector } from "react-redux";
import { GetPaisesReducer } from "../../redux/actions/pages/emplotment/searchCountry";
import SelectBasic from "../../components/pages/offers/CreateOffers/SelectBasic";
import { getCvGeneralReducer } from "../../redux/actions/pages/searchCV/GetSearchCV";

interface FilterParams {
  educations?: string[];
  keywords?: string[];
  sectors?: string[];
  tamanioArray?: { desde: number; hasta: number }[];
}

interface TableSearchCVProps {
  educations?: string[];
}

const SearchCV: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const {
    cvGeneral,
    rex_loading,
    rex_paises,
    rex_total,
    rex_limit
  } = useSelector(({ paises }: any) => paises);

  const [selectedView, setSelectedView] = useState<string>("list");
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [availableCities, setAvailableCities] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [savedSearchQuery, setSavedSearchQuery] = useState('')
  const [isSearching, setIsSearching] = useState(false);
  const [countryForTable, setCountryForTable] = useState('');
  const [cityForTable, setCityForTable] = useState('');

  const [loadingMoreData, setLoadingMoreData] = useState(false);

  const offerId = parseInt(id || "0", 10);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    educations: [],
    keywords: [],
    sectors: [],
    tamanioArray: [],
  });


  const handleFilterChange = (newFilters: Partial<FilterParams>) => {
    setFilterParams(prevParams => ({
      ...prevParams,
      ...newFilters,
    }));
  };

  const fetchData = () => {
    const newData = [
      {
        id: "1",
        nombre: "C... D...",
        valoraciones: 3.4,
        numReviews: 12,
        aptitudes: {
          role: "Cajera",
          language: "Inglés",
          level: "C2",
        },
        softSkills: "Ver gráfica",
        ubicacion: "Paterna, Valencia",
        estudios: "Bachillerato de arte",
        experiencia: "De 1 - 3 años",
        rol: "Vendedora de tienda",
      },
      {
        id: "2",
        nombre: "C... D...",
        valoraciones: 3.4,
        numReviews: 12,
        aptitudes: {
          role: "Cajera",
          language: "Inglés",
          level: "C1",
        },
        softSkills: "Ver gráfica",
        ubicacion: "Paterna, Valencia",
        estudios: "Bachillerato de arte",
        experiencia: "ROKIE",
        rol: "Vendedora de tienda",
      },
      {
        id: "3",
        nombre: "C... D...",
        valoraciones: 3.4,
        numReviews: 12,
        aptitudes: {
          role: "Cajera",
          language: "Inglés",
          level: "C1",
        },
        softSkills: "Ver gráfica",
        ubicacion: "Paterna, Valencia",
        estudios: "Bachillerato de arte",
        experiencia: "De 1 - 3 años",
        rol: "Vendedora de tienda",
      },

      // Add more data as needed
    ];
    setData((prevData: any) => [...prevData, ...newData]);
    if (data.length >= 15) setHasMore(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCountry && rex_paises.length > 0) {
      const country = rex_paises.find((c: any) => c.pais === selectedCountry);
      if (country) {
        setAvailableCities(country.ciudades);
      } else {
        setAvailableCities([]);
      }
    } else {
      setAvailableCities([]);
    }
  }, [selectedCountry, rex_paises]);

  useEffect(() => {
    dispatch(GetPaisesReducer());
  }, []);

  const handleViewSelect = (view: string) => {
    setSelectedView(view);
  };

  const handleSortSelect = (option: string) => {
    console.log("Selected sorting option:", option);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleCardClick = (id: number) => {
    navigate(`/searchCV/${id}`);
  };

  const handleSearch = () => {
    setSavedSearchQuery(searchQuery);
    setIsSearching(true);
    setCountryForTable(selectedCountry);
    setCityForTable(selectedCity);
  };

  useEffect(() => {
    if (cvGeneral?.length === 0 || page === 1) {
      setLoading(true);
      const selectedEducations = filterParams.educations && filterParams.educations?.length > 0 ? filterParams.educations : undefined
      dispatch(getCvGeneralReducer(true, 1, rex_limit, selectedEducations, isSearching ? savedSearchQuery : '', selectedCountry, selectedCity)).then(() => {
        setHasMore(true);
        setLoading(false);
      });
    }
  }, [rex_limit, page, filterParams.educations, isSearching ? savedSearchQuery : '', selectedCountry, selectedCity, rex_total]);

  
  useEffect(() => {
    if (selectedCountry && selectedCity && filterParams.educations) {
      const selectedEducations = filterParams.educations && filterParams.educations?.length > 0 ? filterParams.educations : undefined
      dispatch(getCvGeneralReducer(true, 1, 10, selectedEducations, '', selectedCountry, selectedCity));
      console.log('cvGeneral............:', cvGeneral)
    }
  }, [selectedCountry, selectedCity, filterParams.educations])

  const fetchMoreData = () => {
    console.log('rex_total:', rex_total)
    console.log('rex_limit:', rex_limit)
    if (loadingMoreData || !hasMore) {
      return;
    }
    setLoadingMoreData(true);

    const nextPage = page + 1;
    const totalPages = Math.ceil(rex_total / rex_limit);
    console.log('nextPage:', nextPage)
    console.log('rex_total:', rex_total)
    console.log('rex_limit:', rex_limit)
    setTimeout(() => {
      console.log('llegue')
      dispatch(getCvGeneralReducer(false,nextPage, rex_limit))
        .then(() => {
          console.log('llegue2')
          setPage(nextPage);
          setLoadingMoreData(false);
          if (nextPage >= totalPages) {
            setHasMore(false);
          }
        })
        .catch(() => {
          setLoadingMoreData(false);
        });
    }, 1000);
  };

  useEffect(() => {
    if (rex_total == 0) setHasMore(false)
    if (cvGeneral?.length == rex_total) setHasMore(false)
  }, [rex_total, cvGeneral])
  console.log('data22:', data)


  return (
    <>
      <Header />
      <div className="bg-white px-4 lg:px-8 py-8 lg:py-12">
        <div className="flex flex-col lg:flex-row">

          <Button
            type="primary"
            className="lg:hidden mb-3"
            onClick={() => setDrawerVisible(true)}
            icon={<MenuOutlined />}
          />

          <div
            style={{ width: "234px", backgroundColor: "white" }}
            className="hidden lg:block p-0"
          >
            <SidebarSearch
              onFilterChange={handleFilterChange}
              currentFilters={filterParams} />
          </div>


          <Drawer
            title={null}
            placement="left"
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
            drawerStyle={{ padding: 0 }}
            width={268}
            className="block lg:hidden"
          >
            <SidebarSearch
              inDrawer />
          </Drawer>

          <div className="flex-1 pl-0 lg:pl-6">
            <>
              <h2 className="text-heading-md font-bold mb-4">
                Resultado de la búsqueda de CV
              </h2>

              <Row gutter={[12, 12]}>
                <Col xs={24} xl={18}>
                  <Row gutter={[12, 12]}>
                    <Col xs={24} lg={10}>
                      <Input
                        prefix={<img src={IconSearch} width={24} height={24} />}
                        value={searchQuery}
                        placeholder="Buscar por Nombre"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="Input-Filter-Employment w-full custom-input-company px-2 rounded"
                      />
                    </Col>
                    <Col xs={12} md={8} lg={5}>
                      <SelectBasic
                        placeholder="País"
                        style={{ height: '44px' }}
                        onChange={(value) => setSelectedCountry(value)}
                        loading={rex_loading}
                        options={rex_paises.map((country: any) => ({
                          value: country.pais,
                          label: country.pais
                        }))}
                      />
                    </Col>
                    <Col xs={12} md={8} lg={5}>
                      <SelectBasic
                        placeholder="Ciudad"
                        style={{ height: '44px' }}
                        disabled={!selectedCountry}
                        onChange={(value) => setSelectedCity(value)}
                        options={availableCities.map((city: any) => ({
                          value: city.ciudad,
                          label: city.ciudad
                        }))}
                      />
                    </Col>
                    <Col xs={24} md={8} lg={4}>
                      <Button
                        className="bg-blue3 text-white principal-nav-notify-button2 rounded-[4px] w-full"
                        onClick={() => {
                          setSavedSearchQuery(searchQuery); // Guarda el valor solo cuando haces clic en buscar
                          handleSearch(); // Llama a la función de búsqueda
                        }}>
                        Buscar
                      </Button>
                    </Col>
                  </Row>
                </Col>

                <Col xs={0} xl={2} xxl={3}>
                </Col>

                <Col xs={24} xl={4} xxl={3} className="flex justify-end">
                  <div className="principal-nav-action-buttons flex md:justify-end space-x-2 w-full">
                    <Button
                      className="principal-nav-notify-button font-semibold text-body-sm rounded-[4px] w-full"
                      onClick={() => navigate("")}
                    >
                      Guardar búsqueda
                    </Button>
                  </div>
                </Col>
              </Row>


              <Row className="mb-[50px]">
                <Col xl={24} md={24} className="flex justify-start">
                  <div className="principal-nav-action-buttons flex">
                    <Button
                      className="principal-nav-notify-button border-none font-semibold text-body-sm lg:w-[326px] h-[36px] ml-0 rounded-[4px] "
                      onClick={showModal}
                    >
                      <PlusOutlined />
                      Crear perfil de soft skill para la búsqueda
                    </Button>
                    <QuestionModal
                      visible={isModalVisible}
                      onClose={closeModal}
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={16} className="mb-[30px]">
                <Col xs={24} md={16} lg={19} className="mb-[32px]">
                  <div className="pt-3">
                    <div className="flex items-center">
                      <span className="font-bold text-green32 text-caption mr-[4px]">
                        ACCIONES DE PERFIL:
                      </span>
                      <div className="flex space-x-[8px]">
                        <button className="text-[#00476D] font-bold text-caption hover:underline">
                          Añadir a favoritos
                        </button>
                        <span className="font-bold text-xs">|</span>
                        <button className="text-[#00476D] font-bold text-caption hover:underline">
                          Guardar perfil
                        </button>
                        <span className="font-bold text-xs">|</span>
                        <button className="text-[#DC2828] font-bold text-caption hover:underline">
                          Archivar candidato
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs={24} md={16} lg={24} className="">
                  <Row gutter={24}>
                    <Col xs={12}>
                      <SortingOptions onSelect={handleSortSelect} />
                    </Col>
                    <Col xs={12} className="flex justify-end items-center">
                      <IconButton
                        icon={Stream}
                        selected={selectedView === "stream"}
                        onClick={() => handleViewSelect("stream")}
                        altText="Icono de flujo"
                      />
                      <IconButton
                        icon={List}
                        selected={selectedView === "list"}
                        onClick={() => handleViewSelect("list")}
                        altText="Icono de lista"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} md={24} className="mb-[32px]">
                  {/* <InfiniteScroll
                    dataLength={data.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={
                      <div style={{ marginBottom: "16px" }}>
                        {[...Array(2)].map((_, index) => (
                          <Skeleton
                            key={index}
                            active
                            title={false}
                            paragraph={{
                              rows: 2,
                              width: "100%",
                            }}
                          />
                        ))}
                      </div>
                    }
                  > */}
                  {selectedView === "list" ? (
                    <TableSearchCV
                      loading={loading}
                      fetchMoreData={fetchMoreData}
                      educations={filterParams.educations}
                      searchTerm={isSearching ? savedSearchQuery : ''}
                      selectedCountry={countryForTable}
                      selectedCity={cityForTable} />
                  ) : (
                    <Card className="border-[#81BFEC] bg-[#FBFDFE] rounded-md p-4">
                      <ListSearchCV
                        fetchMoreData={fetchMoreData}
                        educations={filterParams.educations}
                        searchTerm={isSearching ? savedSearchQuery : ''}
                        selectedCountry={countryForTable}
                        selectedCity={cityForTable}
                        killerQuestion={false}
                      />

                    </Card>
                  )}
                  {/* </InfiniteScroll> */}

                </Col>
              </Row>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchCV;