// src/redux/reducers/pages/userRatings/getUserRatingReducer.ts

import {
    FETCH_USER_RATINGS_REQUEST,
    FETCH_USER_RATINGS_SUCCESS,
    FETCH_USER_RATINGS_FAILURE,
    UserRatingsActionTypes,
    UserRating,
} from '../../../../constants/company/rating/GetUserLinkRating';
  
  interface UserRatingState {
    data: UserRating | null;
    loading: boolean;
    error: string | null;
  }
  
  const initialState: UserRatingState = {
    data: null,
    loading: false,
    error: null,
  };
  
  const getUserRatingReducer = (
    state = initialState,
    action: UserRatingsActionTypes
  ): UserRatingState => {
    switch (action.type) {
      case FETCH_USER_RATINGS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_USER_RATINGS_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: null,
        };
      case FETCH_USER_RATINGS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default getUserRatingReducer;
  