import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store/store';
import {
  VALIDATE_CODE_REQUEST,
  VALIDATE_CODE_SUCCESS,
  VALIDATE_CODE_FAILURE,
  ValidateCodeActionTypes,
} from '../../../constants/auth/Validation';
import FetchWithIP from '../utils/FetchHeaders';
import { GET_DATA_USER_LOGIN_AUTH } from '../../../constants/auth/Auth';

export const validateCodeRequest = (): ValidateCodeActionTypes => ({
  type: VALIDATE_CODE_REQUEST,
});

export const validateCodeSuccess = (data: any): ValidateCodeActionTypes => ({
  type: VALIDATE_CODE_SUCCESS,
  payload: { data },
});

export const validateCodeFailure = (error: string): ValidateCodeActionTypes => ({
  type: VALIDATE_CODE_FAILURE,
  payload: error,
});


export const AuthLoginWithValidate = (data: any): any => ({
  type: GET_DATA_USER_LOGIN_AUTH,
  payload: data,
});

export const validateCode = (codigo: number): ThunkAction<Promise<any>, RootState, unknown, ValidateCodeActionTypes> => async (dispatch) => {
  dispatch(validateCodeRequest());

  try {
      const response = await FetchWithIP('auth/validateCode', {
          method: 'POST'},
          { codigo_verificacion: codigo }, // Asegúrate de enviar como número
    );

      if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || `Error HTTP: ${response.status}`);
      }

      const responseData = await response.json();
      
      if (responseData.respuesta) {
        dispatch(AuthLoginWithValidate(responseData.data[0].user));
        localStorage.setItem('token', responseData.data[0].token)
      }
      dispatch(validateCodeSuccess(responseData));
      return responseData

    
  } catch (error: any) {
      dispatch(validateCodeFailure(error.message));
      throw error;
  }
};

