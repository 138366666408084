// src/constants/pages/myPortal/cv/GetAnswers.ts

export const GET_ANSWERS_REQUEST = 'GET_ANSWERS_REQUEST';
export const GET_ANSWERS_SUCCESS = 'GET_ANSWERS_SUCCESS';
export const GET_ANSWERS_FAILURE = 'GET_ANSWERS_FAILURE';

// Tipos de las acciones
interface GetAnswersRequestAction {
  type: typeof GET_ANSWERS_REQUEST;
}

interface GetAnswersSuccessAction {
  type: typeof GET_ANSWERS_SUCCESS;
  payload: {
    data: any; // Datos del CV del usuario
  };
}

interface GetAnswersFailureAction {
  type: typeof GET_ANSWERS_FAILURE;
  payload: string;
}

// Exportamos los tipos de las acciones
export type GetAnswersActionTypes =
  | GetAnswersRequestAction
  | GetAnswersSuccessAction
  | GetAnswersFailureAction;