import {
    FETCH_EMPRESAS_BY_SECTOR_REQUEST,
    FETCH_EMPRESAS_BY_SECTOR_SUCCESS,
    FETCH_EMPRESAS_BY_SECTOR_FAILURE,
    EmpresasBySectorActionTypes,
} from '../../../../constants/pages/company/GetCompanySector';
  
  interface EmpresasBySectorState {
    loading: boolean;
    data: any[];
    error: string | null;
  }
  
  const initialState: EmpresasBySectorState = {
    loading: false,
    data: [],
    error: null,
  };
  
const empresasBySectorReducer = (
    state = initialState,
    action: EmpresasBySectorActionTypes
  ): EmpresasBySectorState => {
    switch (action.type) {
      case FETCH_EMPRESAS_BY_SECTOR_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_EMPRESAS_BY_SECTOR_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload.data,
        };
      case FETCH_EMPRESAS_BY_SECTOR_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  export default empresasBySectorReducer;