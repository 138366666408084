import {
    FETCH_OFERTAS_BY_EMPRESA_REQUEST,
    FETCH_OFERTAS_BY_EMPRESA_SUCCESS,
    FETCH_OFERTAS_BY_EMPRESA_FAILURE,
    OfertasByEmpresaActionTypes,
  } from '../../../../constants/pages/company/GetCompanyOffers';
  
  interface OfertasByEmpresaState {
    loading: boolean;
    data: any[];
    error: string | null;
  }
  
  const initialState: OfertasByEmpresaState = {
    loading: false,
    data: [],
    error: null,
  };
  
  const ofertasByEmpresaReducer = (
    state = initialState,
    action: OfertasByEmpresaActionTypes
  ): OfertasByEmpresaState => {
    switch (action.type) {
      case FETCH_OFERTAS_BY_EMPRESA_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_OFERTAS_BY_EMPRESA_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload.data,
        };
      case FETCH_OFERTAS_BY_EMPRESA_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default ofertasByEmpresaReducer;
  