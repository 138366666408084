import React, { useState, useEffect } from 'react';
import { Avatar, Tag, Select, Button, Divider, Skeleton, Modal, notification, Row, Col } from 'antd';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { useNavigate } from 'react-router-dom';
import '../../../styles/pages/offers/OffersProfileInfo.css';
import StyledCheckbox from '../../../checkbox/CheckboxProps';
import CustomLegend from './Legend';
import RatingBlue from '../../../rating/RatingBlue'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import { addOrRemoveUserOffers, GetPostulacionesByOfertaReducer } from '../../../../redux/actions/pages/offers/offersProfile/GetOffersProfile';
import { updateFasePostulacion } from '../../../../redux/actions/common/fase/PatchApplicationPhases';
import dayjs from 'dayjs';
import ModalVerCv from '../../../modals/ModalVerCv';
import { DetailSoftSkillsOfferFormat } from '../../../../constants/pages/searchCV/GetSearchCV';
type NotificationType = 'success' | 'info' | 'warning' | 'error';

const { Option } = Select;
interface OffersProfileInfosProps {
  offerId: number;
}
interface UserSoftSkillsDto {
  nivel: any;
  soft_skills : {
    soft_skill : string
  }
}

interface OfferSoftSkillsDto {
  nivel: any;
  soft_skills_oferta : {
    porcentaje : number
    soft_skills : {
      soft_skill : string
      soft_skill_c : string
    }
  }[]
}

interface UserDto {
  soft_skills_usuarios : UserSoftSkillsDto[]
}

const IMAGE_USER_DEFAULT = process.env.REACT_APP_IMAGE_USER_DEFAULT;
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

const defaultSoftSkills = [
  { subject: 'Adaptable', A: 0, fullMark: 10 },
  { subject: 'Consistente', A: 0, fullMark: 10 },
  { subject: 'Detallista', A: 0, fullMark: 10 },
  { subject: 'Innovador', A: 0, fullMark: 10 },
  { subject: 'Metódico', A: 0, fullMark: 10 },
  { subject: 'Proactivo', A: 0, fullMark: 10 },
  { subject: 'Resiliente', A: 0, fullMark: 10 },
  { subject: 'Visionario', A: 0, fullMark: 10 },
];

const PostulationSkeleton = () => (
  <div className="p-6 bg-gray-100 rounded-lg relative mb-8">
    <div className="flex flex-wrap -mx-3 relative">
      <div className="w-full md:w-3/4 px-3 relative">
        <div className="flex items-center mb-4">
          <Skeleton.Avatar active size={64} shape="square" />
          <div className="ml-4 flex-grow">
            <Skeleton.Input style={{ width: '60%' }} active size="small" />
            <Skeleton.Input style={{ width: '40%' }} active size="small" />
          </div>
        </div>
        <Skeleton active paragraph={{ rows: 2 }} />
        <Skeleton active paragraph={{ rows: 2 }} />
        <Skeleton active paragraph={{ rows: 2 }} />
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <div className="w-full md:w-1/4 px-3 mt-2 md:mt-0 relative">
        <div style={{ height: '200px' }}></div>
      </div>
    </div>
  </div>
);

const OffersProfileInfos: React.FC<OffersProfileInfosProps> = ({ offerId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { soft_skills_offer, postulaciones, loading, error } = useSelector((state: RootState) => state.getOffersProfile);

  const [api, contextHolder] = notification.useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newFase, setNewFase] = useState('');
  const [selectedPostulacionId, setSelectedPostulacionId] = useState<number | null>(null);
  const [showModalCv, setShowModalCv] = useState(false);
  const [cvSeleccionado, setCvSeleccionado] = useState<{
    usuarios: {
      cvs_usuarios: { cv: string }[];
      nombre: string;
      personas: {
        nombre: string;
        apellido_paterno: string;
      }
    };
  } | null>(null);

  useEffect(() => {
    dispatch(GetPostulacionesByOfertaReducer(offerId));
  }, [offerId, dispatch]);

  const handleOpenModal = (fase: string, postulacionId: number) => {
    setNewFase(fase); // Almacena la fase seleccionada
    setSelectedPostulacionId(postulacionId); // Almacena el ID de la postulación
    setIsModalVisible(true); // Muestra el modal
  };

  const handleConfirmChange = () => {
    if (selectedPostulacionId) {
      handleChangeFase(newFase, selectedPostulacionId); // Cambia la fase cuando el usuario confirma
    }
    setIsModalVisible(false); // Cierra el modal después de confirmar
  };

  const handleCancelChange = () => {
    setIsModalVisible(false); // Cierra el modal sin hacer cambios
  };

  const handleChangeFase = (newFase: string, postulacionId: number) => {
    const faseMapping = {
      "No Revisado": 1,
      Descartado: 2,
      Preseleccionado: 3,
      Entrevista: 4,
      Seleccionado: 5,
    } as const;

    const fasePostulacionId = faseMapping[newFase as keyof typeof faseMapping];

    if (postulacionId && fasePostulacionId) {
      dispatch(updateFasePostulacion(postulacionId, fasePostulacionId))
        .then(() => {
          // Llamada a la API para obtener nuevamente las postulaciones después de la actualización
          dispatch(GetPostulacionesByOfertaReducer(offerId));
        })
        .catch((error) => {
          console.error("Error al actualizar la fase:", error);
        });
    }

    // Llamamos a la acción de Redux para actualizar la fase
    if (postulacionId && fasePostulacionId) {
      dispatch(updateFasePostulacion(postulacionId, fasePostulacionId));
    } else {
      console.error('Fase o ID de postulación inválido');
    }
  };

  const handleFaseChange = (value: string, postulacionId: number) => {
    handleOpenModal(value, postulacionId); // Abre el modal cuando el usuario cambia la fase
  };

  // Renderiza el modal de confirmación
  const renderModal = () => (
    <Modal
      title="Confirmación"
      visible={isModalVisible}
      onOk={handleConfirmChange}
      onCancel={handleCancelChange}
      okText="Sí, cambiar fase"
      cancelText="Cancelar"
    >
      <p>¿Estás seguro de que deseas cambiar a la fase "{newFase}"?</p>
    </Modal>
  );

  const handleCandidateClick = (postulacionId: number, candidateId: number) => {
    if (offerId && postulacionId && candidateId) {
      navigate(`/offer/${offerId}/postulacion/${postulacionId}/candidate/${candidateId}`);
    } else {
      console.error("ID de la oferta, postulación o candidato no definidos");
    }
  };

  const handleViewAnswers = (offerId: number, candidateName: string, userId: string) => {
    console.log("Navigating to answers...");
    console.log("Offer ID:", offerId);
    console.log("Candidate Name:", candidateName);

    // Verifica si offerId y candidateName son válidos
    if (offerId && candidateName) {
      navigate(`/offer/answers/${offerId}/${candidateName}/${userId}`);
    } else {
      console.error("Error: Invalid offerId or candidateName");
    }
  };

  const getRadarChartData = (data:{ ofertas:any, usuarios:any}) => {

    const softSkillOffer = formatDataOffferSoftsKill(data.ofertas, data.usuarios)
    
    return softSkillOffer ? softSkillOffer : []
  };

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: 'Alerta',
      description:
        'Solo puedes seleccionar a 10 usuarios como máximo',
    });
  };

  const formatDataOffferSoftsKill = ( oferta: OfferSoftSkillsDto, usuario:UserDto ) => {

    if (oferta.soft_skills_oferta.length > 0) {
      const dataFormated = oferta.soft_skills_oferta.flatMap((item: DetailSoftSkillsOfferFormat, index:number) => {

        const findSubjectUser = usuario.soft_skills_usuarios.find((usr:UserSoftSkillsDto) => usr.soft_skills.soft_skill == item.soft_skills.soft_skill)
        
        return [
          {
            subject: item.soft_skills.soft_skill,
            A: item.porcentaje > 0 ? item.porcentaje/10 : 0,
            B: findSubjectUser ? findSubjectUser.nivel : 0,
            fullMark: 10,
            index : index
          },
          {
            subject: item.soft_skills.soft_skill_c,
            A: 10 - (item.porcentaje > 0 ? item.porcentaje/10 : 0),
            B: findSubjectUser ? 10-(findSubjectUser.nivel) : 0,
            fullMark: 10,
            index : index
          }
        ]
      })
      return dataFormated.sort((a,b) => {
        if(a.subject < b.subject) return -1
        if(a.subject > b.subject) return 1
        return 0
      })
    } else {
      return defaultSoftSkills
    }
  }

  const calculateDuration = (startDate: string, endDate: string | null) => {
    const start = dayjs(startDate);
    const end = endDate ? dayjs(endDate) : dayjs();
    const months = end.diff(start, 'month');
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    return `${years} años, ${remainingMonths} meses`;
  };

  if (loading) {
    return (
      <div>
        <PostulationSkeleton />
        <PostulationSkeleton />
        <PostulationSkeleton />
      </div>
    );
  }

  if (error) return <div>Error al cargar las postulaciones: {error}</div>;

  return (
    <div className="p-6 bg-gray-100 rounded-lg relative">
      {contextHolder}
      {postulaciones.map((postulacion: any, index) => (
        <React.Fragment key={postulacion.usuarios.usuario}>
          <Row className='w-full h-auto'>

            <Col xs={24} md={24} xl={12} xxl={17}>
              <div>
                <div>
                  <div>
                    <div>
                      <div
                        style={{
                          display: 'flex'
                        }}
                      >
                        <div
                          onClick={() => handleCandidateClick(postulacion.id, postulacion.usuarios.id)}
                          style={{ position: 'relative', zIndex: 1 }}
                          className="mx-auto p-1 -ml-5 -mt-8 bg-gray-100 rounded-lg cursor-pointer"
                        >
                          <div className="flex items-center ml-[-8px] cursor-pointer">
                            <StyledCheckbox 
                              checked={postulacion.checked}
                              children={undefined} 
                              onChange={({target: {checked}})=> {
                                if(soft_skills_offer.users.length < 10){
                                  dispatch(addOrRemoveUserOffers(postulacion, checked))
                                }else{
                                  openNotificationWithIcon('warning')
                                }
                              }}
                            />

                            <div className="flex items-center" onClick={() => handleCandidateClick(postulacion.usuarios.id, postulacion.usuarios.id)}>
                              <Avatar
                                size={64}
                                src={
                                  postulacion.usuarios.imagen
                                    ? API_BASE_URL_EXACT + postulacion.usuarios.imagen
                                    : IMAGE_USER_DEFAULT
                                }
                                shape="square"
                                className="!rounded-none bg-white"
                              />
                              <div className="ml-4 relative">
                                <h2 className="text-[18px] font-medium text-[#1A1A1A] opacity-70">
                                  {`${postulacion.usuarios.personas.nombre} ${postulacion.usuarios.personas.apellido_paterno} ${postulacion.usuarios.personas.apellido_materno}`}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className='absolute right-[60px]'
                        >
                          <div key={postulacion.id}>
                            {postulacion.fases_postulaciones?.fase && (
                              <select
                                className={`-top-2 text-center rounded-full px-3 text-white font-bold text-[12px] h-[22px] w-[127px] appearance-none ${postulacion.fases_postulaciones.fase === "Descartado"
                                  ? "bg-[#FF3B30]"
                                  : postulacion.fases_postulaciones.fase === "Preseleccionado"
                                    ? "bg-[#1C82BC]"
                                    : postulacion.fases_postulaciones.fase === "Entrevista"
                                      ? "bg-[#FF9500]"
                                      : postulacion.fases_postulaciones.fase === "Seleccionado"
                                        ? "bg-[#34C759]"
                                        : "text-black"
                                  }`}
                                value={postulacion.fases_postulaciones.fase}
                                onChange={(e) => handleFaseChange(e.target.value, postulacion.id)} // <-- Se llama a handleFaseChange para abrir el modal
                                style={postulacion.fases_postulaciones.fase === "No Revisado" ? { zIndex: 11, color: 'black' } : { zIndex: 11 }}
                              >
                                <option value="No Revisado" className="bg-white text-black">No Revisado</option>
                                <option value="Descartado" className="bg-white text-black">Descartado</option>
                                <option value="Preseleccionado" className="bg-white text-black">Preseleccionado</option>
                                <option value="Entrevista" className="bg-white text-black">Entrevista</option>
                                <option value="Seleccionado" className="bg-white text-black">Seleccionado</option>
                              </select>
                            )}
                          </div>

                          {renderModal()}
                        </div>
                      </div>
                      <p className="text-[16px] font-bold text-[#1A1A1A] opacity-70">{postulacion.usuarios.cargo}</p>
                      <p className="text-[12px] font-medium text-[#1A1A1A] opacity-70">{postulacion.usuarios.ubicacion}</p>
                    </div>
                    {/* <p className="ml-auto text-[12px] font-medium -mt-10 text-[#1A1A1A] opacity-70">Cualitativos</p> */}
                  </div>
                </div>

                <div className="flex items-center mt-4">
                  <h3 className="font-bold text-[12px]">Valoraciones</h3>
                  <div className="flex items-center ml-4 space-x-2">
                    <RatingBlue totalStars={5} filledStars={postulacion.usuarios.promedioValoraciones} />
                    <Tag className="text-[12px] font-medium text-[#1A1A1A] opacity-70 border border-black rounded-full"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Ver valoraciones
                    </Tag>
                  </div>
                </div>

                <div className="mt-4 flex">
                  <h3 className="font-bold mr-4 text-[12px]">Experiencia</h3>
                  <div className="text-[14px] font-medium text-[#1A1A1A] opacity-70">
                    {postulacion.usuarios.experiencias_laborales_usuarios.map((exp: any, index: number) => (
                      index < 2
                        ? <p key={index}>
                          {`${exp.cargo} | ${exp.nombre_empresa} | ${exp.nombre_sector} | ${dayjs(exp.fecha_inicio).format('MM/YYYY')} - ${exp.fecha_fin ? dayjs(exp.fecha_fin).format('MM/YYYY') : 'Actual'} (${calculateDuration(exp.fecha_inicio, exp.fecha_fin)})`}
                        </p>
                        : null
                    ))}
                  </div>
                </div>

                <div className="mt-2 flex">
                  <h3 className="font-bold mr-[21px] text-[12px]">Educación</h3>
                  <div className="text-[14px] font-medium text-[#1A1A1A] opacity-70">
                    {postulacion.usuarios.educacion_usuarios.map((edu: any, index: number) => (
                      index < 2
                        ? <p key={index}>
                          {`${edu.carrera} | ${edu.nombre_centro_educativo} | ${dayjs(edu.fecha_inicio).format('MM/YYYY')} - ${edu.fecha_final ? dayjs(edu.fecha_final).format('MM/YYYY') : 'Actual'} (${calculateDuration(edu.fecha_inicio, edu.fecha_final)})`}
                        </p>
                        : null
                    ))}
                  </div>
                </div>

                <div className="-mt-2 ml-[78px]">
                  <span className="font-bold text-[12px] text-[#1A1A1A] opacity-80">Búsqueda activa | Remoto admitido | Jornada completa</span>
                  <Button
                    type="link"
                    className="font-bold text-[12px]"
                    style={{ color: '#00476D' }}
                    // href{postulacion.usuarios.cvs_usuarios.length > 0 ? postulacion.usuarios.cvs_usuarios[0].nombre : "#"}
                    onClick={() => {
                      setShowModalCv(true);
                      setCvSeleccionado(postulacion)
                    }}
                  >
                    Ver CV en pdf
                  </Button>

                </div>
                <div className="flex mt-1 items-center">
                  <div className="flex-1">
                    <span className="font-bold text-[12px]">Aptitudes</span>
                    <span className="font-bold text-[12px] ml-5 mr-2">Killer questions 7/10</span>
                    <Select
                      defaultValue="Seleccionar Respuesta"
                      className="text-[12px] font-medium !text-[#00476D] border border-[#00476D] rounded mr-2 h-7"
                      onChange={(value) => console.log("Respuesta seleccionada:", value)}
                    >
                      <Option value="1">Respuesta 1</Option>
                      <Option value="2">Respuesta 2</Option>
                      <Option value="3">Respuesta 3</Option>
                    </Select>
                    <span className="font-bold text-[12px]">Aptitudes:</span>
                    {postulacion.usuarios.aptitudes_usuarios.map((apt: any, index: number) => (
                      <Tag key={index} className="ml-1 text-[12px] font-medium text-[#1A1A1A] opacity-70 border border-black rounded-full">
                        {apt.aptitudes.aptitud}
                      </Tag>
                    ))}
                  </div>
                  <Button
                    type="link"
                    className="text-[12px] font-bold text-blue3 underline bg-transparent border-none cursor-pointer relative z-10" // Añadido 'relative' y 'z-10'
                    onClick={() => {
                      const name = postulacion.usuarios.personas.nombre; // Captura el nombre
                      handleViewAnswers(offerId, name, postulacion.usuarios.id + "");
                    }}
                  >
                    Ver respuestas
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={24} md={24} xl={12} xxl={7}>
              <div style={{ position: 'absolute', top: '-30px', right: '0px', width: '100%', height: '100%', zIndex: 2 }}>
                <ResponsiveContainer width="100%" height={"100%"}>
                  <RadarChart cx="50%" cy="50%" outerRadius="90%" data={getRadarChartData(postulacion)}>
                    <PolarGrid />
                    <PolarAngleAxis
                      dataKey="subject"
                      tick={{ fontSize: 11, fill: '#000', fontWeight: 'Bold' }}
                    />
                    <PolarRadiusAxis angle={30} domain={[0, 10]} />
                    <Radar name="Perfil del Candidato" dataKey="B" stroke="#006497" fill="transparent" />
                    <Radar name="Perfil de la oferta" dataKey="A" stroke="#FDBCB4" fill="transparent" />
                    <Legend content={<CustomLegend showOffer={true} />} />
                    <Tooltip/>
                  </RadarChart>
                </ResponsiveContainer>
              </div>
            </Col>

          </Row>
          {index < postulaciones.length - 1 && (
            <Divider className="bg-black my-8 mb-[45px]" />
          )}
        </React.Fragment>
      ))}

      <ModalVerCv
        nombre={cvSeleccionado?.usuarios.personas.nombre + " " + cvSeleccionado?.usuarios.personas.apellido_paterno}
        showModal={showModalCv}
        setShowModal={setShowModalCv}
        cv={cvSeleccionado?.usuarios?.cvs_usuarios[0]?.cv || ""}
      />
    </div>
  );
};

export default OffersProfileInfos;
