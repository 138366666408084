// reducers/SearchSavedOffersReducer.ts
import {
  SEARCH_SAVED_OFFERS_REQUEST,
  SEARCH_SAVED_OFFERS_SUCCESS,
  SEARCH_SAVED_OFFERS_FAILURE,
} from '../../../constants/applications/SearchSavedApplications';

interface SearchSavedOffersState {
  rex_savedOffers: any[];
  rex_loading: boolean;
  rex_error: string | null;
  rex_meta: any;
}

const initialState: SearchSavedOffersState = {
  rex_savedOffers: [],
  rex_loading: false,
  rex_error: null,
  rex_meta: {},
};

const SearchSavedOffersReducer = (state = initialState, action: any): SearchSavedOffersState => {
  switch (action.type) {
    case SEARCH_SAVED_OFFERS_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null,
      };
    case SEARCH_SAVED_OFFERS_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_savedOffers: action.payload.data, // Garantiza que rex_savedOffers sea un array
        rex_meta: action.payload.meta, // Garantiza que rex_meta sea un objeto
      };
    case SEARCH_SAVED_OFFERS_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.error,
      };
    default:
      return state;
  }
};

export default SearchSavedOffersReducer;
