import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store/store';
import {
  SEARCH_SAVED_OFFERS_REQUEST,
  SEARCH_SAVED_OFFERS_SUCCESS,
  SEARCH_SAVED_OFFERS_FAILURE,
} from '../../../constants/applications/SearchSavedApplications';
import FetchWithIP from '../utils/FetchHeaders';

export const searchSavedOffers = (
  cargo: string,
  sector?: string,
  ubi_poblacion?: string,
  ubi_provincia?: string,
  page: number = 1,
  limit: number = 10
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
  async (dispatch: Dispatch) => {
    dispatch({ type: SEARCH_SAVED_OFFERS_REQUEST });

    try {
      // Construye la URL con los filtros adicionales si están presentes
      const queryParams = new URLSearchParams({
        cargo,
        page: page.toString(),
        limit: limit.toString(),
      });

      if (sector) queryParams.append("sector", sector);
      if (ubi_poblacion) queryParams.append("ubi_poblacion", ubi_poblacion);
      if (ubi_provincia) queryParams.append("ubi_provincia", ubi_provincia);

      const response = await FetchWithIP(
        `postulaciones-guardadas/getAllByUserToken?${queryParams.toString()}`,
        { method: 'GET' }
      ).then(res => res.json());

      const savedOfferData = response.data.map((offer: any) => ({
        id: offer.ofertas.id,
        empresa_id: offer.ofertas.empresa_id,
        cargo: offer.ofertas.cargo,
        descripcion: offer.ofertas.descripcion,
        ubi_poblacion: offer.ofertas.ubi_poblacion,
        sal_max: offer.ofertas.sal_max,
        jornada_laboral: offer.ofertas.jornada_laboral,
        createdAt: offer.createdAt,
        updatedAt: offer.updatedAt,
        postulaciones_guardadas: offer.postulaciones_guardadas,
      }));

      dispatch({
        type: SEARCH_SAVED_OFFERS_SUCCESS,
        payload: {
          data: savedOfferData,
          meta: response.meta,
        },
      });
    } catch (error) {
      dispatch({
        type: SEARCH_SAVED_OFFERS_FAILURE,
        error: (error as Error).message,
      });
      console.error('Error al buscar postulaciones guardadas:', error);
    }
  };
