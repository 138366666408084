import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Drawer, Button, Upload, message } from "antd";
import { Routes, Route, Navigate } from "react-router-dom";
import profilePicture from "../../assets/img/MyPortal/ImageP.svg";
import { MenuOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store/store";
import IconEdit from '../../assets/icons/edit2.svg';
import ModalEditBanner from "../../components/pages/myPortal/Modals/ModalEditBanner";
import ModalSaved from "../../components/pages/myPortal/Modals/ModalSavedChanges";
import ProfileCompani from "../../pages/myPortal/profile/ProfileCompani";
import CompanyProfile from "../../components/sidebarProfile/CompanyProfile";
import { UpdateBannerReducer, UpdateLogoReducer } from "../../redux/actions/pages/company/UpdateImagenCompany";
import { GetCompanyReducer } from "../../redux/actions/common/company/Company";

// Environment variables
const BANNER_DEFAULT = process.env.REACT_APP_BANNER_EMPRESA_DEFAULT;
const IMAGE_USER_DEFAULT = process.env.REACT_APP_IMAGE_EMPRESA_DEFAULT;
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

const PortalCompany: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const uploadBanner = useRef<HTMLInputElement | null>(null);

  const { rex_company } = useSelector(({ companyC }: any) => companyC);
  const { rex_user } = useSelector(({ auth }: any) => auth);
  // State
  const [showModalEditBanner, setShowModalEditBanner] = useState(false);
  const [successModalEditBanner, setSuccessModalEditBanner] = useState(false);
  const [profileImage, setProfilePicture] = useState<string>(
    rex_user.empresa ? rex_user.empresa.logo : profilePicture
  );
  const [headerImageSrc, setHeaderImageSrc] = useState<string>(
    rex_user.empresa && rex_user.empresa.banner
      ? API_BASE_URL_EXACT + rex_user.empresa.banner
      : BANNER_DEFAULT
  );
  const [isJobSearchActive, setIsJobSearchActive] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);

  // Handlers
  const handleJobSearchToggle = (checked: boolean) => {
    setIsJobSearchActive(checked);
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const handleProfileImageChange = (newImage: string) => {
    setProfilePicture(newImage);
  };

  const handleHeaderImageChange = async (info: any) => {
    const { file } = info;

    if (!file || file.size === 0) {
      message.error("El archivo está vacío o no es válido.");
      return;
    }

    try {
      const response = await dispatch(UpdateBannerReducer(rex_user.empresa.id, file));
      if (response.success) {
        const newHeaderImageUrl = URL.createObjectURL(file);
        setHeaderImageSrc(newHeaderImageUrl);
        message.success("Imagen de banner actualizada con éxito");
        setShowModalEditBanner(false);
        setSuccessModalEditBanner(true);
      } else {
        message.error(response.message || "Error al subir la imagen del banner.");
      }
    } catch (error) {
      message.error("Hubo un error inesperado al subir la imagen.");
    }
  };

  const sidebarData = {
    profilePicture: rex_user.empresa && rex_user.empresa.logo 
      ? API_BASE_URL_EXACT + rex_user.empresa.logo 
      : IMAGE_USER_DEFAULT,
    headerImage: headerImageSrc,
    name: rex_user?.empresa?.empresa || '',
    jobTitle: "Plan Básico",
    location: rex_user?.empresa?.ubicacion || 'Sin Ubicación',
  };

  useEffect(() => {

  }, [sidebarData]);

  return (
    <>
      
      <div className="relative w-full px-7">
        <img
          src={sidebarData.headerImage}
          alt="Header"
          className="w-full h-64 object-cover rounded-lg"
        />
        <Upload
          showUploadList={false}
          beforeUpload={() => false}
          onChange={handleHeaderImageChange}
        >
          <input ref={uploadBanner} />
        </Upload>

        <Button
          style={{
            position: 'absolute',
            bottom: '30px',
            right: '45px'
          }}
          onClick={() => setShowModalEditBanner(!showModalEditBanner)}
          shape="circle"
          icon={<img src={IconEdit} alt="Edit" style={{ width: '20px' }} />}
        />
      </div>
      {/* <Button
        onClick={() => { console.log('datos', rex_company) }}
      /> */}
      <div className="bg-white pl-7 pr-7">
        <Row>
          <Col xxl={3} xl={3}>
            {/* Desktop sidebar */}
            <div className="hidden md:block" style={{ width: "234px" }}>
              <CompanyProfile
                profilePicture={sidebarData.profilePicture}
                name={sidebarData.name}
                jobTitle={sidebarData.jobTitle}
                location={sidebarData.location}
                isJobSearchActive={isJobSearchActive}
                onJobSearchToggle={handleJobSearchToggle}
                width={234}
                onImageChange={handleProfileImageChange}
                id_company={rex_user.empresa.id}
              />
            </div>

            {/* Mobile drawer */}
            <Drawer
              title={null}
              placement="left"
              onClose={toggleDrawer}
              open={drawerVisible}
              width={300}
              className="md:hidden"
            >
              <CompanyProfile
                profilePicture={sidebarData.profilePicture}
                name={sidebarData.name}
                jobTitle={sidebarData.jobTitle}
                location={sidebarData.location}
                isJobSearchActive={isJobSearchActive}
                onJobSearchToggle={handleJobSearchToggle}
                width={234}
                onImageChange={handleProfileImageChange}
                id_company={rex_user.empresa.id}
              />
            </Drawer>
          </Col>

          <Col xxl={21} xl={21}>
            <div className="flex-1 pl-6 pr-1">
              <Row gutter={[16, 16]}>
                <Col xs={24} md={24}>
                  <Button
                    className="md:hidden mb-4"
                    type="primary"
                    icon={<MenuOutlined />}
                    onClick={toggleDrawer}
                  />
                  <Routes>
                    <Route path="profileCompany" element={<ProfileCompani />} />
                    <Route path="/" element={<Navigate to="profileCompany" replace />} />
                  </Routes>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      <ModalEditBanner
        visible={showModalEditBanner}
        onClose={() => setShowModalEditBanner(!showModalEditBanner)}
        onEdit={() => {
          if (uploadBanner.current) {
            uploadBanner.current.click();
          }
        }}
        previewImage={headerImageSrc}
      />

      <ModalSaved
        visible={successModalEditBanner}
        onClose={() => setSuccessModalEditBanner(!successModalEditBanner)}
      />
    </>
  );
};

export default PortalCompany;