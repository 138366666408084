export const UPDATE_LOGO_REQUEST = 'UPDATE_LOGO_REQUEST';
export const UPDATE_LOGO_SUCCESS = 'UPDATE_LOGO_SUCCESS';
export const UPDATE_LOGO_FAILURE = 'UPDATE_LOGO_FAILURE';
export const UPDATE_BANNER_REQUEST = 'UPDATE_BANNER_REQUEST';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_FAILURE = 'UPDATE_BANNER_FAILURE';

interface UpdateLogoRequestAction {
  type: typeof UPDATE_LOGO_REQUEST;
}

interface UpdateLogoSuccessAction {
  type: typeof UPDATE_LOGO_SUCCESS;
  payload: any;
}

interface UpdateLogoFailureAction {
  type: typeof UPDATE_LOGO_FAILURE;
  payload: string;
}

interface UpdateBannerRequestAction {
  type: typeof UPDATE_BANNER_REQUEST;
}

interface UpdateBannerSuccessAction {
  type: typeof UPDATE_BANNER_SUCCESS;
  payload: any;
}

interface UpdateBannerFailureAction {
  type: typeof UPDATE_BANNER_FAILURE;
  payload: string;
}

// Union Type para todas las posibles acciones de "Company"
export type UpdateImagenCompanyActionTypes = 
  | UpdateLogoRequestAction
  | UpdateLogoSuccessAction
  | UpdateLogoFailureAction
  | UpdateBannerRequestAction
  | UpdateBannerSuccessAction
  | UpdateBannerFailureAction;
