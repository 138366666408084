export const SEARCH_COMPANY_REQUEST = 'SEARCH_COMPANY_REQUEST';
export const SEARCH_COMPANY_SUCCESS = 'SEARCH_COMPANY_SUCCESS';
export const SEARCH_COMPANY_FAILURE = 'SEARCH_COMPANY_FAILURE';

interface FetchCompanyRequestAction {
  type: typeof SEARCH_COMPANY_REQUEST;
}

interface FetchCompanySuccessAction {
  type: typeof SEARCH_COMPANY_SUCCESS;
  payload: {
    data: any[]; // Array de empresas
    meta: { total: number; limit: number; page: number; empresa: string }; // Meta con detalles de la respuesta
  };
}

interface FetchCompanyFailureAction {
  type: typeof SEARCH_COMPANY_FAILURE;
  payload: string; // Mensaje de error
}

export type CompanyActionTypes =
  | FetchCompanyRequestAction
  | FetchCompanySuccessAction
  | FetchCompanyFailureAction;
