import React, { useEffect } from 'react';
import { Layout } from 'antd';
import 'tailwindcss/tailwind.css';
import { useDispatch, useSelector } from 'react-redux';
import { GetOfferReducer } from '../../../../redux/actions/offers/GetOffers';
import { AppDispatch } from '../../../../redux/store/store';
import { useParams } from 'react-router-dom';

const { Sider } = Layout;

interface OfferDescriptionProps {
  jobTitle: string;
  jobLocation: string;
  salaryRange: string;
  jobDetails: string[];
  skills: string[];
  keywords: string[];
  inDrawer?: boolean;
  rex_request?: any;
}

interface Aptitude {
  id: number;
  aptitud: string;
  aprobado: boolean;
  createdAt: string;
  updatedAt: string;
}

interface AptitudeOffer {
  aptitudes: Aptitude;
}

const OfferDescription: React.FC<OfferDescriptionProps> = ({
  jobTitle,
  jobLocation,
  salaryRange,
  jobDetails,
  skills,
  keywords,
  inDrawer = false,
}) => {

  const dispatch = useDispatch<AppDispatch>();

  const  {rex_data_offer } = useSelector(({getOffers}: any) => getOffers);

  const { idOffer } = useParams();

  useEffect(()=> {
    if (idOffer) {
      dispatch(GetOfferReducer(Number(idOffer)));
    }
  }, [idOffer]);

  console.log('rex_data_offer',rex_data_offer)

  const salario = rex_data_offer?.sal_max && rex_data_offer?.sal_min
    ? `${rex_data_offer.sal_min} - ${rex_data_offer.sal_max}€ (anual)`
    : 'No disponible';

    const aptitudes = rex_data_offer?.aptitudes_oferta?.map((aptitudObj: any) => aptitudObj.aptitudes.aptitud) || [];
  
    const palabras_clave = rex_data_offer?.palabras_clave_oferta?.map((aptitudObj: any) => aptitudObj.palabras_claves.palabra) || [];

    if (!rex_data_offer) {
      return <div>Loading...</div>;
    }
  return (
    <div className="flex min-h-screen">
      <Sider
        width={227}
        style={{ backgroundColor: '#fff'}}
        className={` ${
          inDrawer
            ? "p-[5px] border-none ml-[-10px] mt-[-15px]"
            :"p-[24px] rounded-lg shadow-lg border-2 border-sky-blue0"
          }`}
      >
        <h2 className="font-bold text-heading-md text-gray mb-[30px]">Descripción de la oferta</h2>
        <div className="mb-[20px]">
            <p className="font-bold text-caption text-gray">Puesto de trabajo</p>
            <p className="text-caption text-gray">{rex_data_offer.cargo}</p>
            <p className="text-caption text-gray">{rex_data_offer.ubi_provincia  + ',' + rex_data_offer.ubi_poblacion}</p>
            <p className="text-caption text-gray">{`Salario: ${salario} (año)`}</p>
        </div>

        {/* <div className="mb-[20px]">
          <h3 className="font-bold text-caption text-gray">Información sobre el puesto</h3>
          <ul className="text-caption text-gray">
            {jobDetails.map((detail, index) => (
              <h1 key={index}>{detail}</h1>
            ))}
          </ul>
        </div> */}

        <div className="mb-[20px]">
          <h3 className="font-bold text-caption text-gray">Información sobre el puesto2</h3>
          <ul className="text-caption text-gray">
            <p className="text-caption text-gray">{rex_data_offer.anios_experiencia + ' ' + ' años de experiencia'}</p>
            {/* <p className="text-caption text-gray">Eso</p> */}
            <p className="text-caption text-gray">{rex_data_offer.jornada_laboral}</p>
            {/* <p className="text-caption text-gray">Mañana y tarde</p> */}
            <p className="text-caption text-gray">{rex_data_offer.sectores.sector}</p>
          </ul>
        </div>

        <div className="mb-[20px]">
          <h3 className="font-bold text-caption text-gray">Aptitudes / Tecnología</h3>
          <ul className="text-caption text-gray">
            {aptitudes.map((aptitude : any, index:any) => (
              <h1 key={index}>{aptitude}</h1>
            ))}
          </ul>
        </div>

        <div>
          <h3 className="font-bold text-caption text-gray">Palabras clave</h3>
          <ul className="text-caption text-gray">
            {palabras_clave.map((aptitude : any, index:any) => (
              <h1 key={index}>{aptitude}</h1>
            ))}
          </ul>
        </div>
      </Sider>
    </div>
  );
};

export default OfferDescription;
