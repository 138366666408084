export const FETCH_OFFERS_REQUEST = 'FETCH_OFFERS_REQUEST';
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_FAILURE = 'FETCH_OFFERS_FAILURE';
export const FETCH_SOFT_SKILLS_OFFERS_REQUEST = 'FETCH_SOFT_SKILLS_OFFERS_REQUEST';
export const FETCH_SOFT_SKILLS_OFFERS_SUCCESS = 'FETCH_SOFT_SKILLS_OFFERS_SUCCESS';
export const FETCH_SOFT_SKILLS_OFFERS_FAILURE = 'FETCH_SOFT_SKILLS_OFFERS_FAILURE';
export const FETCH_OFFERS_REQUEST_TABLE  = 'FETCH_OFFERS_REQUEST_TABLE ';
export const FETCH_OFFERS_SUCCESS_TABLE = 'FETCH_OFFERS_SUCCESS_TABLE';
export const FETCH_OFFERS_FAILURE_TABLE  = 'FETCH_OFFERS_FAILURE_TABLE ';
export const FETCH_PHASE_POSTULATION_OFFER_REQUEST = 'FETCH_PHASE_POSTULATION_OFFER_REQUEST'
export const FETCH_PHASE_POSTULATION_OFFER_SUCCESS = 'FETCH_PHASE_POSTULATION_OFFER_SUCCESS'
export const FETCH_PHASE_POSTULATION_OFFER_FAILURE = 'FETCH_PHASE_POSTULATION_OFFER_FAILURE'

interface FetchOffersRequestAction {
  type: typeof FETCH_OFFERS_REQUEST;
}

interface FetchOffersSuccessAction {
  type: typeof FETCH_OFFERS_SUCCESS;
  payload: {
    data: any[];
    meta: { total: number; limit: number; page: number };
  };
}

interface FetchOffersFailureAction {
  type: typeof FETCH_OFFERS_FAILURE;
  payload: string;
}

interface FetchSoftSkillsOffersRequestAction {
  type: typeof FETCH_SOFT_SKILLS_OFFERS_REQUEST;
}

interface FetchSoftSkillsOffersSuccessAction {
  type: typeof FETCH_SOFT_SKILLS_OFFERS_SUCCESS;
  payload: {
    data: any[];
  };
}

interface FetchSoftSkillsOffersFailureAction {
  type: typeof FETCH_SOFT_SKILLS_OFFERS_FAILURE;
  payload: string;
}

interface FetchPhasePostulationOfferRequestAction {
  type: typeof FETCH_PHASE_POSTULATION_OFFER_REQUEST;
}

interface FetchPhasePostulationOfferSuccessAction {
  type: typeof FETCH_PHASE_POSTULATION_OFFER_SUCCESS;
  payload: {
    data: {};
  };
}

interface FetchPhasePostulationOfferFailureAction {
  type: typeof FETCH_PHASE_POSTULATION_OFFER_FAILURE;
  payload: string;
}

interface FetchOffersRequestActionTable {
  type: typeof FETCH_OFFERS_REQUEST_TABLE;
}

interface FetchOffersSuccessActionTable {
  type: typeof FETCH_OFFERS_SUCCESS_TABLE;
  payload: {
    data: any[];
    meta: { total: number; limit: number; page: number };
  };
}

interface FetchOffersFailureActionTable {
  type: typeof FETCH_OFFERS_FAILURE_TABLE;
  payload: string;
}


export type OfferActionTypes =
  | FetchOffersRequestAction
  | FetchOffersSuccessAction
  | FetchOffersFailureAction
  | FetchOffersRequestActionTable
  | FetchOffersSuccessActionTable
  | FetchOffersFailureActionTable
  | FetchSoftSkillsOffersRequestAction
  | FetchSoftSkillsOffersSuccessAction
  | FetchSoftSkillsOffersFailureAction
  | FetchPhasePostulationOfferRequestAction
  | FetchPhasePostulationOfferSuccessAction
  | FetchPhasePostulationOfferFailureAction;

