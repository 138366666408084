// src/actions/pages/myPortal/portfolio/updatePortfolioActions.ts

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import {
  CHANGE_DEFAULT_PORTFOLIO_REQUEST,
  CHANGE_DEFAULT_PORTFOLIO_SUCCESS,
  CHANGE_DEFAULT_PORTFOLIO_FAILURE,
  UPDATE_ARCHIVO_REQUEST,
  UPDATE_ARCHIVO_SUCCESS,
  UPDATE_ARCHIVO_FAILURE,
  UpdatePortfolioActionTypes,
} from '../../../../../constants/pages/myPortal/portfolio/PatchPortfolio';
import FetchWithIP from '../../../utils/FetchHeaders';

// Action creators for changing the default portfolio
export const changeDefaultPortfolioRequest = (): UpdatePortfolioActionTypes => ({
  type: CHANGE_DEFAULT_PORTFOLIO_REQUEST,
});

export const changeDefaultPortfolioSuccess = (data: any): UpdatePortfolioActionTypes => ({
  type: CHANGE_DEFAULT_PORTFOLIO_SUCCESS,
  payload: { data },
});

export const changeDefaultPortfolioFailure = (error: string): UpdatePortfolioActionTypes => ({
  type: CHANGE_DEFAULT_PORTFOLIO_FAILURE,
  payload: error,
});

// Action creators for updating an archive
export const updateArchivoRequest = (): UpdatePortfolioActionTypes => ({
  type: UPDATE_ARCHIVO_REQUEST,
});

export const updateArchivoSuccess = (data: any): UpdatePortfolioActionTypes => ({
  type: UPDATE_ARCHIVO_SUCCESS,
  payload: { data },
});

export const updateArchivoFailure = (error: string): UpdatePortfolioActionTypes => ({
  type: UPDATE_ARCHIVO_FAILURE,
  payload: error,
});

// Thunk action for changing the default portfolio
export const changeDefaultPortfolio = (portafolioId: string, updateData: any): ThunkAction<Promise<any>, RootState, unknown, UpdatePortfolioActionTypes> => async (dispatch) => {
  dispatch(changeDefaultPortfolioRequest());

  try {
    const response = await FetchWithIP(`portafolios-usuarios/changeDefaultPortafolio/${portafolioId}`, {
      method: 'PATCH'},
      updateData,
    );

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    dispatch(changeDefaultPortfolioSuccess(responseData));
    return responseData;
  } catch (error: any) {
    dispatch(changeDefaultPortfolioFailure(error.message || 'Error al cambiar el portafolio predeterminado'));
    return { error: error.message };
  }
};

// Thunk action for updating an archive
export const updateArchivo = (archivoId: string, updateData: any): ThunkAction<Promise<any>, RootState, unknown, UpdatePortfolioActionTypes> => async (dispatch) => {
  dispatch(updateArchivoRequest());

  try {
    const response = await FetchWithIP(`portafolios-usuarios/updateArchivo/${archivoId}`, {
      method: 'PATCH'},
      updateData,
    );

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    dispatch(updateArchivoSuccess(responseData));
    return responseData;
  } catch (error: any) {
    dispatch(updateArchivoFailure(error.message || 'Error al actualizar el archivo'));
    return { error: error.message };
  }
};
