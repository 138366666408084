import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../redux/store/store';
import {
  FETCH_OFERTAS_BY_EMPRESA_REQUEST,
  FETCH_OFERTAS_BY_EMPRESA_SUCCESS,
  FETCH_OFERTAS_BY_EMPRESA_FAILURE,
  OfertasByEmpresaActionTypes,
} from '../../../../constants/pages/company/GetCompanyOffers';
import FetchWithIP from '../../utils/FetchHeaders';

// Action Creators
export const fetchOfertasByEmpresaRequest = (): OfertasByEmpresaActionTypes => ({
  type: FETCH_OFERTAS_BY_EMPRESA_REQUEST,
});

export const fetchOfertasByEmpresaSuccess = (data: any[]): OfertasByEmpresaActionTypes => ({
  type: FETCH_OFERTAS_BY_EMPRESA_SUCCESS,
  payload: { data },
});

export const fetchOfertasByEmpresaFailure = (error: string): OfertasByEmpresaActionTypes => ({
  type: FETCH_OFERTAS_BY_EMPRESA_FAILURE,
  payload: error,
});

// Thunk Action para obtener ofertas por empresa
export const GetOfertasByEmpresaIdReducer = (
    empresaId: string,
    limit: number // Agregamos el limit como parámetro
  ): ThunkAction<Promise<any>, RootState, unknown, OfertasByEmpresaActionTypes> => async (dispatch) => {
    dispatch(fetchOfertasByEmpresaRequest());
  
    try {
      // Incluimos sortOrder=desc y limit en la URL
      const response = await FetchWithIP(
        `ofertas/${empresaId}/getAllByEmpresaId?sortOrder=desc&limit=${limit}`, // Añadimos el parámetro limit a la URL
        {
          method: 'GET',
        }
      );
  
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
  
      const responseData = await response.json();
  
      let ofertasData;
      if (Array.isArray(responseData.data)) {
        ofertasData = responseData.data; // Ya está ordenado por el servidor
      } else {
        ofertasData = [responseData.data];
      }
  
      dispatch(fetchOfertasByEmpresaSuccess(ofertasData));
      return { payload: { data: ofertasData } };
  
    } catch (error: any) {
      dispatch(fetchOfertasByEmpresaFailure(error.message || 'Error al cargar las ofertas'));
      return { payload: { data: [] } };
    }
  };