import React from 'react';
import { Col, Row, Select } from 'antd';
import '../../../styles/alertFilter/AlertFilter.css';

const { Option } = Select;

interface FilterButtonsApplicationProps {
  categorias: { valor: string, etiqueta: string }[];
  alClicEnCategoria: (valor: string) => void;
}

const FilterButtonsApplication: React.FC<FilterButtonsApplicationProps> = ({
  categorias,
  alClicEnCategoria,
}) => {
  return (
    <div className="alert-filter-container mb-4 text-center">
      <h2 className="text-left font-bold text-[20px] mb-4 -mt-4">Candidaturas activas</h2>
      {/* <div className="alert-filter-buttons"> */}
      <Row gutter={[12,12]}>
        {categorias.map((categoria, indice) => (
          <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={4}>
            <button
              key={indice}
              className={`
                alert-filter-button w-full mr-2 flex justify-center ${
                indice === 0 ? '!border-[#006497] text-[#006497]' : ''
                }`}
                onClick={() => alClicEnCategoria(categoria.valor)}
                >
              {categoria.etiqueta}
            </button>
          </Col>
        ))}
      </Row>
      {/* </div> */}
    </div>
  );
};

export default FilterButtonsApplication;
