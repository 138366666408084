import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Row, Col, notification, message } from "antd";
import IconClosed from "../../../assets/icons/IconClosed.svg";
import GoogleIcon from "../../../assets/icons/IconG.svg";
import FacebookIcon from "../../../assets/icons/IconF.svg";
import RedIconX from '../../../assets/icons/RedIconX.svg';
import GreenIconCheck from '../../../assets/icons/GreenIconCheck.svg';
import InputC from "../../../components/pages/offers/KillerQuestions/ComponentsKillersQ/Input";
import ModalVal from '../modals/ModalVal';
import RatingV from '../../rating/RatingV';
import DefaultCandidateImage from '../../../assets/img/MyPortal/ImageP.svg';
import DefaultCompanyImage from '../../../assets/img/company/logo_example.png';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store/store";

import { ValueCandidateReducer, ValueCompanyReducer } from "../../../redux/actions/enterprise/rating/ValueCompany";
import { GetCompanyRatingsReducer } from "../../../redux/actions/pages/company/rating/GetRatingCompany";
import { GetUserRating } from '../../../redux/actions/enterprise/rating/GetUserLinkRating';
import { GetCompanyRating } from "../../../redux/actions/enterprise/rating/GetCompanyLinkRating";
import { resendCode } from '../../../redux/actions/auth/ResendCode';
import { RegisterAuthReducer } from "../../../redux/actions/auth/Auth";
import { useParams } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_BASE_URL;
const IMAGE_USER_DEFAULT = process.env.REACT_APP_IMAGE_USER_DEFAULT;
const IMAGE_EMPRESA_DEFAULT = process.env.REACT_APP_IMAGE_EMPRESA_DEFAULT;
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;
interface ValuationModalProps {
  visible: boolean;
  onClose: () => void;
  entityName: string; // Nombre del candidato o empresa
  entityType: 'candidate' | 'company'; // Tipo de entidad
  linkVal: string;
  logo?: string;
  esInterno?: boolean;
}

const ModalValorarPerfil: React.FC<ValuationModalProps> = ({
  visible,
  onClose,
  entityName,
  entityType,
  linkVal,
  logo = null,
  esInterno = false
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    rex_validate_user
  } = useSelector(({ auth }: any) => auth);
  const {
    rex_loading
  } = useSelector(({ valueCompany }: any) => valueCompany);
  const token = localStorage.getItem('token');

  const { empresa_id } = useParams<{ empresa_id: string }>();

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [loginName, setLoginName] = useState("");
  const [email, setEmail] = useState("");
  const [tieneLogo, setTieneLogo] = useState(false);
  const [image, setImage] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);


  const handleRatingChange = (value: number) => setRating(value);
  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value);
  const handleLoginNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setLoginName(e.target.value);
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  useEffect(() => {
    setIsLengthValid(password.length >= 8);
    setHasSymbol(/[^A-Za-z0-9]/.test(password)); // Verificar si hay un símbolo
    setHasUppercase(/[A-Z]/.test(password));
    setHasLowercase(/[a-z]/.test(password));
    setHasNumber(/[0-9]/.test(password));
  }, [password]);


  useEffect(() => {
    const isValid = rating > 0 && comment.length > 0;
    setIsSubmitDisabled(!isValid);
  }, [rating, comment, loginName, email]);

  const handleSendValuation = async () => {
    if (!isSubmitDisabled) {
      onClose();
      setIsSecondModalVisible(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (entityType === 'candidate') {
        const response = await dispatch(GetUserRating(linkVal));
        if (response && response.usuario) {
          setName(`${response.usuario.personas.nombre || ''} ${response.usuario.personas.apellido_paterno || ''}`);
          setImage(response.usuario.imagen || IMAGE_USER_DEFAULT);
          setTieneLogo(response.usuario.imagen ? true : false);
        }
      } else if (entityType === 'company') {
        const response = await dispatch(GetCompanyRating(linkVal));
        if (response && response.empresa) {
          setName(response.empresa.empresa || entityName);
          setImage(response.empresa.logo || IMAGE_EMPRESA_DEFAULT);
          setTieneLogo(response.empresa.logo ? true : false);
        }
      }
    };

    if (linkVal) {
      fetchData();
    }
  }, [linkVal, entityType, entityName]);

  const handleRegister = async () => {
    if (!email || !loginName || !password) {
      notification.error({ message: "Por favor completa todos los campos." });
      return;
    }

    if (!isLengthValid || !hasUppercase || !hasLowercase || !hasNumber || !hasSymbol) {
      notification.error({ message: "La contraseña debe cumplir con todos los requisitos." });
      return;
    }

    try {
      await dispatch(RegisterAuthReducer({ nombre: loginName, email, contrasena: password }));
      notification.success({ message: "Te hemos enviado un código de verificación a tu correo." });
      setIsSecondModalVisible(true);
    } catch (error) {
      notification.error({ message: "Error al registrar el usuario." });
    }
  };


  const loginGoogle = (event: React.MouseEvent<HTMLButtonElement>) => {
    window.location.href = `${API_URL}auth/google`;
  };

  const loginFacebook = () => {
    window.location.href = `${API_URL}auth/facebook`;
  };

  // Determinar la imagen según el tipo de entidad
  const entityImage = entityType === 'candidate' ? DefaultCandidateImage : DefaultCompanyImage;

  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{ borderRadius: "12px", border: "1px solid #E1E1E2", marginTop: '15px', marginBottom: '15px' }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mx-[86px] mb-[36px] mt-[30px]">
          <h2 className="text-heading-md font-bold text-blue3">
            ¡Te damos la bienvenida a Merēre!
          </h2>
          <h3 className="mt-[16px] mx-[44px] text-heading-md font-bold">
            Valora a {name}
          </h3>

          {/* Imagen circular centrada */}
          <div
            className="flex justify-center my-[16px]"
            onClick={() => {
              console.log(image);
            }}
          >
            <img
              src={
                esInterno
                  ? logo
                    ? API_BASE_URL_EXACT + logo
                    : IMAGE_USER_DEFAULT
                  : tieneLogo && image
                    ? API_BASE_URL_EXACT + image
                    : image ? image : ""
              }
              alt={name}
              className="rounded-full w-[100px] h-[100px] object-cover"
            />
          </div>

          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, a continuación califica a {name} del 1 al 5 donde 1 es no
            recomendable y 5 completamente recomendable.
          </p>
        </div>

        <div className="text-center mb-[18px] mx-[232px]">
          <RatingV
            filledStars={rating}
            onChange={handleRatingChange}
            filledStarClass="filled-star-class"
            emptyStarClass="empty-star-class"
            filledStarStyle={{ color: '#FFD700' }}
            emptyStarStyle={{ color: '#E1E1E1' }}
            showRatingValue={false}
          />
        </div>

        <div className="mx-[49px] mb-[30px] mt-[26px]">
          <span className="text-body-md font-normal">
            Escribe tu valoración *
          </span>
          <Input.TextArea
            rows={5}
            value={comment}
            onChange={handleCommentChange}
            className="w-full border my-[8px] border-[#D9D9D9] placeholder:text-green32 focus:placeholder:text-grays hover:placeholder:text-black hover:bg-gray3 hover:border-2 hover:border-[#D9D9D9] hover:text-black focus:border-4 focus:border-[#91c3fd] focus:text-[#757575] rounded-[8px] transition-all duration-200 text-[#757575] font-normal text-body-md"
            style={{ height: 80 }}
          />
          <span className="text-body-md font-normal text-[#757575]">
            Máximo 400 caracteres
          </span>

          {
            !rex_validate_user && !token ? (
              <>
                <div className="flex flex-col items-center mt-[31px] mb-6 mx-[117px]">
                  <span className="text-body-sm font-normal mb-[23px] text-[#757575]">
                    Regístrate en menos de 1 minuto
                  </span>
                  <Button
                    icon={
                      <img
                        src={GoogleIcon}
                        alt="Google"
                        style={{ width: "20px", marginRight: "8px" }}
                      />
                    }
                    className="principal-nav-notify-buttonG ml-[0px] w-full max-w-xs mb-[28px]"
                    onClick={loginGoogle}
                  >
                    Sign in with Google
                  </Button>

                  <Button
                    icon={
                      <img
                        src={FacebookIcon}
                        alt="Facebook"
                        style={{ width: "20px", marginRight: "8px" }}
                      />
                    }
                    className="principal-nav-notify-buttonG ml-[0px] w-full max-w-xs"
                    onClick={loginFacebook}
                  >
                    Sign in with Facebook
                  </Button>
                </div>

                <span className="text-body-sm font-medium text-[#5F5F5F]">
                  Nombre completo (Opcional)
                </span>
                <InputC
                  placeholder="Escribe tu nombre"
                  value={loginName}
                  onChange={handleLoginNameChange}
                  style={{
                    marginBottom: "23px",
                    borderRadius: "12px",
                    height: "36px",
                    marginTop: "10px",
                  }}
                />

                <span className="text-body-sm font-medium text-[#5F5F5F]">
                  Email
                </span>
                <InputC
                  placeholder="Escribe tu email"
                  value={email}
                  onChange={handleEmailChange}
                  style={{
                    marginBottom: "20px",
                    borderRadius: "12px",
                    height: "36px",
                    marginTop: "10px",
                  }}
                />
                <span className="text-body-sm font-medium text-[#5F5F5F]">
                  Contraseña
                </span>
                <InputC
                  type="password"
                  placeholder="Escribe tu contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    marginBottom: "20px",
                    borderRadius: "12px",
                    height: "36px",
                    marginTop: "10px",
                  }}
                />
                <Row className="mb-4 justify-start items-center mt-[34px]">
                  <Col span={24}>
                    <div className="flex items-center mb-2">
                      <img
                        src={isLengthValid ? GreenIconCheck : RedIconX}
                        alt={isLengthValid ? "Green Check" : "Red X"}
                        className="w-[20px] mr-[10px]"
                      />
                      <span style={{ color: isLengthValid ? "#16A249" : "#B3261E", fontSize: "16px", fontWeight: "bold" }}>
                        8 caracteres
                      </span>
                    </div>
                    <div className="flex items-center mb-2">
                      <img
                        src={hasUppercase ? GreenIconCheck : RedIconX}
                        alt={hasUppercase ? "Green Check" : "Red X"}
                        className="w-[20px] mr-[10px]"
                      />
                      <span style={{ color: hasUppercase ? "#16A249" : "#B3261E", fontSize: "16px", fontWeight: "bold" }}>
                        1 mayúscula
                      </span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="flex items-center mb-2">
                      <img
                        src={hasLowercase ? GreenIconCheck : RedIconX}
                        alt={hasLowercase ? "Green Check" : "Red X"}
                        className="w-[20px] mr-[10px]"
                      />
                      <span style={{ color: hasLowercase ? "#16A249" : "#B3261E", fontSize: "16px", fontWeight: "bold" }}>
                        1 minúscula
                      </span>
                    </div>
                    <div className="flex items-center mb-2">
                      <img
                        src={hasNumber ? GreenIconCheck : RedIconX}
                        alt={hasNumber ? "Green Check" : "Red X"}
                        className="w-[20px] mr-[10px]"
                      />
                      <span style={{ color: hasNumber ? "#16A249" : "#B3261E", fontSize: "16px", fontWeight: "bold" }}>
                        1 número
                      </span>
                    </div>
                    <div className="flex items-center">
                      <img
                        src={hasSymbol ? GreenIconCheck : RedIconX}
                        alt={hasSymbol ? "Green Check" : "Red X"}
                        className="w-[20px] mr-[10px]"
                      />
                      <span style={{ color: hasSymbol ? "#16A249" : "#B3261E", fontSize: "16px", fontWeight: "bold" }}>
                        1 símbolo
                      </span>
                    </div>
                  </Col>
                </Row>

                {/* Barra de progreso */}
                <div className="progress-bar-container my-[20px] mt-8 flex">
                  <div
                    className={`progress-bar h-[8px] rounded-full ${isLengthValid || hasUppercase || hasLowercase || hasNumber || hasSymbol
                      ? "bg-[#006497] w-[100px]"
                      : "bg-[#E1E1E1] w-[100px]"
                      }`}
                  />
                  <div
                    className={`progress-bar h-[8px] rounded-full ml-[10px] ${(isLengthValid && (hasUppercase || hasLowercase || hasNumber || hasSymbol)) ||
                      (hasUppercase && (hasLowercase || hasNumber || hasSymbol)) ||
                      (hasLowercase && (hasNumber || hasSymbol)) ||
                      (hasNumber && hasSymbol)
                      ? "bg-[#006497] w-[100px]"
                      : "bg-[#E1E1E1] w-[100px]"
                      }`}
                  />
                  <div
                    className={`progress-bar h-[8px] rounded-full ml-[10px] ${((isLengthValid && hasUppercase && (hasLowercase || hasNumber || hasSymbol)) ||
                      (hasUppercase && hasLowercase && (hasNumber || hasSymbol)) ||
                      (hasLowercase && hasNumber && hasSymbol))
                      ? "bg-[#006497] w-[100px]"
                      : "bg-[#E1E1E1] w-[100px]"
                      }`}
                  />
                  <div
                    className={`progress-bar h-[8px] rounded-full ml-[10px] ${((isLengthValid && hasUppercase && hasLowercase && (hasNumber || hasSymbol)) ||
                      (hasUppercase && hasLowercase && hasNumber && hasSymbol))
                      ? "bg-[#006497] w-[100px]"
                      : "bg-[#E1E1E1] w-[100px]"
                      }`}
                  />
                  <div
                    className={`progress-bar h-[8px] rounded-full ml-[10px] ${(isLengthValid && hasUppercase && hasLowercase && hasNumber && hasSymbol)
                      ? "bg-[#006497] w-[100px]"
                      : "bg-[#E1E1E1] w-[100px]"
                      }`}
                  />
                </div>

              </>
            ) : null
          }

          <div className="flex justify-center">
            <Button
              onClick={onClose}
              className="principal-nav-notify-buttonG w-[118px] h-[44px]"
              style={{ marginRight: "8px", borderRadius: "4px" }}
            >
              Cancelar
            </Button>
            <Button
              disabled={rex_loading}
              onClick={handleSendValuation}
              className={
                `w-[181px] h-[44px] ml-[18px] rounded-[4px] ${rex_loading ? 'bg-[#F4F4F5] text-[#757575] font-semibold text-body-md cursor-not-allowed' : 'bg-blue3 principal-nav-notify-button2 text-white cursor-pointer'}`}
            >
              Enviar valoración
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal secundario */}
      <ModalVal
        visible={isSecondModalVisible}
        onClose={() => setIsSecondModalVisible(false)}
        entityName={name}
        entityType={entityType}
        showModalRegister={!rex_validate_user && !token}
        onSend={async () => {
          if (!rex_validate_user && !token) {
            // Si el usuario no está validado, ejecuta la función de registro
            await handleRegister();
          } else {
            // Si el usuario está validado, envía la valoración
            if (entityType === "company") {
              await dispatch(ValueCompanyReducer({
                link_valoracion: linkVal,
                observacion: comment,
                valoracion: rating
              }));
              if (empresa_id) {
                dispatch(GetCompanyRatingsReducer(parseInt(empresa_id, 10)));
              }
            } else {
              await dispatch(ValueCandidateReducer({
                link_valoracion: linkVal,
                observacion: comment,
                valoracion: rating
              }));
            }
          }
        }}
      />

    </>
  );
};

export default ModalValorarPerfil;
