import React, { useState, useEffect } from "react";
import { Table, Skeleton, Button, notification, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store/store';
import { addOrRemoveUserGraphSoftSkills, getCvGeneralReducer } from '../../../redux/actions/pages/searchCV/GetSearchCV';
import CheckboxC from "../../../components/checkbox/CheckboxProps";
import RatingStar from "../searchCV/RatingStar"; // Importar RatingStar
import ModalSoftSkills from "./ModalSoftSkills";
import InfiniteScroll from "react-infinite-scroll-component";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface Column {
  title: string;
  dataIndex: string;
  key: string;
  render?: (text: any, record: any) => JSX.Element | string;
}

interface TableSearchCVProps {
  educations?: string[];
  searchTerm?: string;
  selectedCountry?: string;
  selectedCity?: string;
  loading: boolean;
  fetchMoreData : () => void
}

interface DetailSoftSkillsFormat {
  soft_skills: {
    soft_skill: any
    soft_skill_c: any
  }
  nivel: number
}

type DataSoftSkill = {
  [key: string]: string | number | undefined;
  idxSubject?: number,
  subject?: string;
  A?: number;
  B?: number;
  C?: number;
  D?: number;
  E?: number;
  F?: number;
  G?: number;
  H?: number;
  I?: number;
  J?: number;
  fullMark: number;
};

const SkeletonRow = () => (
  <Row gutter={12} className="skeleton-row" style={{ display: 'flex', padding: '12px 0', borderBottom: '1px solid #f0f0f0' }}>
    <Col span={1}>
      <Skeleton.Avatar active shape="square" size="small" />
    </Col>
    <Col span={3}>
      <Skeleton.Input active size="small" />
    </Col>
    <Col span={3}>
      <Skeleton.Input active size="small" />
    </Col>
    <Col span={3}>
      <Skeleton.Input active size="small" />
    </Col>
    <Col span={3}>
      <Skeleton.Input active size="small" />
    </Col>
    <Col span={3}>
      <Skeleton.Input active size="small" />
    </Col>
    <Col span={3}>
      <Skeleton.Input active size="small" />
    </Col>
    <Col span={3}>
      <Skeleton.Input active size="small" />
    </Col>
    <Col span={2}>
      <Skeleton.Input active size="small" />
    </Col>
  </Row>
);

const OfferTableSearch: React.FC<TableSearchCVProps> = ({ 
  loading = false,
  fetchMoreData
}) => {

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { 
    rex_users_graph_sk, 
    cvGeneral, 
    error, 
    rex_limit, 
    rex_page, 
    rex_total 
  } = useSelector((state: RootState) => state.getSearchCV);

  const [api, contextHolder] = notification.useNotification();
  const [selectedKeys, setSelectedKeys] = useState<React.ReactText[]>([]);
  const [showSoftSkills, setShowSoftSkills] = useState<boolean>(false);
  const [dataUserSk, setDataUserSk] = useState<DataSoftSkill[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const handleCandidateClick = (candidateId: number) => {
    navigate(`/searchCV/candidate/${candidateId}`);
  };

  const handleCheckboxChange = (id: React.ReactText, checked: boolean) => {
    setSelectedKeys((prevSelectedKeys) =>
      checked
        ? [...prevSelectedKeys, id]
        : prevSelectedKeys.filter((key) => key !== id)
    );
  };

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: 'Alerta',
      description:
        'Solo puedes seleccionar a 10 usuarios como máximo',
    });
  };

  const columnsWithCheckbox: Column[] = [
    {
      title: "",
      dataIndex: "checkbox",
      key: "checkbox",
      render: (_, record) => (
        <CheckboxC 
          checked={record.checked}
          onChange={({target: {checked}})=> {
            console.log("@cambia a " + checked)
            if(rex_users_graph_sk.users.length < 10 || checked == false){
              dispatch(addOrRemoveUserGraphSoftSkills(record, checked,false))
            }else{
              openNotificationWithIcon('warning')
            }
          }}
          // value={1}
          // children={undefined} 
        />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (_, record) => {

        const nombre = `${record.personas.nombre} ${record.personas.apellido_paterno} ${record.personas.apellido_materno}` 
        const rol = record.experiencias_laborales_usuarios[0]?.cargo || "Rol no especificado"

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <div className="font-normal text-caption text-green32">
                {nombre}
              </div>
              <div className="font-bold text-body-sm text-green32">
                {rol}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: "Valoraciones",
      dataIndex: "valoraciones",
      key: "valoraciones",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }} onClick={() => {
            console.log("record_ --------------------")
            console.log(record)
          }}>
            <RatingStar value={record.valoracionesPromedio} />
            <span className="font-bold text-body-sm ml-[4px]">
              {record.valoracionesPromedio}
            </span>
            <span className="font-normal text-body-sm text-green32 ml-[4px]">
              ({record.valoracionesCount})
            </span>
          </div>
        );
      },
    },
    // {
    //   title: "Ofertas",
    //   dataIndex: "soft_skills_usuarios",
    //   key: "ofertas",
    //   render: (_,record) => {

    //     const soft_skills_usuarios = record.soft_skills_usuarios.map((sftu:any) => ({
    //       ofertas: sftu.soft_skills?.soft_skills_oferta?.map((oferta: any) => ({
    //         oferta_id: oferta.oferta_id
    //       })) || []
    //     }))

    //     return (
    //       <div className="font-medium text-caption text-green32">
    //         {soft_skills_usuarios.map((oferta: any, index: number) => (
    //           <div key={index}>
    //             {oferta.ofertas && oferta.ofertas.map((oferta: any, idx: number) => (
    //               <div key={idx}>
    //                 {oferta.softSkill}: Oferta ID {oferta.oferta_id}, Porcentaje {oferta.porcentaje}%
    //               </div>
    //             ))}
    //           </div>
    //         ))}
    //       </div>
    //     )
    //   }
    // },
    {
      title: "Aptitudes",
      dataIndex: "aptitudes",
      key: "aptitudes",
      render: (_, record) => {
        
        const aptitudes = record.aptitudes_usuarios.map((apt:any) => apt.aptitudes.aptitud)

        return (
          <div className="font-medium text-caption text-green32">
          {aptitudes.map((aptitud: string, index: number) => (
            index < 1 && (
              <div key={index}>{aptitud}</div>
            )
          ))}
        </div>
        )
      }
    },
    {
      title: "Soft Skills",
      dataIndex: "softSkills",
      key: "softSkills",
      render: (_, record) => (
        <div 
          onClick={(e) => {
            e.stopPropagation()
            setShowSoftSkills(true)
            if(rex_users_graph_sk.users.length < 10){
              dispatch(addOrRemoveUserGraphSoftSkills(record, true, true))
            }else{
              openNotificationWithIcon('warning')
            }
          }}
          className="cursor-pointer text-caption text-blue3"
        >Ver Gráficas</div>
      ),
    },
    {
      title: "Ubicación",
      dataIndex: "ubicacion",
      key: "ubicacion",
      render: (text, record) => (
        <div 
          className="font-normal text-body-sm text-green32"
        >{record?.pais?.pais}</div>
      ),
    },
    {
      title: "Estudios",
      dataIndex: "estudios",
      key: "estudios",
      render: (_, record) => {

        const educacion = record.educacion_usuarios.map((edu: any) => edu.carrera)
        return (
          <div className="font-medium text-body-sm text-green32">
            {educacion.map((carrera: string, index: number) => (
              index < 1 && (
                <div key={index}>{carrera}</div>
              )
            ))}
          </div>
        )
      },
    },
    {
      title: "Experiencia",
      dataIndex: "experiencia",
      key: "experiencia",
      // experiencia: 
      render: (_, record) => {
        const experiencia = `${record.meses_experiencia ?? 0} meses`
        return (
          <div className="font-medium text-body-sm text-green32">{experiencia}</div>
        )
      }
    },
  ];

  if (loading) return <div>{[...Array(5)].map((_, index) => (
    <>
      <SkeletonRow key={index} />
      <SkeletonRow key={index} />
    </>
  ))}</div>;

  if (error) return <div>Error: {error}</div>;

  const handleClick = () => {
    // console.log("cvGeneral: ----");
    // console.log(data);
    // console.log(cvGeneral);
    // console.log("cvGeneral: ----");
  };

  return (
    <div
    >
      {contextHolder}
      <Button
        onClick={handleClick}>

      </Button>
      <Button
        onClick={() => {
          console.log('cvGeneral:', cvGeneral)
        }}>
        Aqui
      </Button>
      {/* {searchInitiated ? (
              <Skeleton active paragraph={{ rows: 3 }} />
            ) : data.length === 0 && searchInitiated ? (
              <p style={{ textAlign: "center" }}>
                <b>No se encontraron empresas para el término de búsqueda.</b>
              </p>
            ) : ( */}
      <InfiniteScroll
        dataLength={cvGeneral.length}
        next={fetchMoreData}
        hasMore={hasMore && cvGeneral.length >= rex_limit} // Solo permite más carga si hay más de `rex_limit` resultados
        loader={
          hasMore && cvGeneral.length >= rex_limit ? ( // Solo muestra el Skeleton si `hasMore` es `true` y hay más resultados para cargar
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <Skeleton avatar paragraph={{ rows: 3 }} active />
            </div>
          ) : null
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Ya no hay más datos</b>
          </p>
        }
        scrollThreshold={0.95}
      >
        <React.Fragment >
          <Table
            columns={columnsWithCheckbox}
            dataSource={cvGeneral}
            // dataSource={data}
            pagination={false}
            rowKey={(record) => record.id}
            onRow={(record) => ({
              onClick: () => handleCandidateClick((record.id)),
            })}

            className="offer-table cursor-pointer"
          />
        </React.Fragment>

      </InfiniteScroll>


      <style>{`
        .offer-table .ant-table-thead > tr > th {
          background-color: white;
          color: #5f5f5f;
          font-weight: normal;
          font-size: 12px;
          border-bottom: 1px solid #5F5F5F;
        }

        .offer-table .ant-table-thead > tr > th,
        .offer-table .ant-table-tbody > tr > td {
          padding: 12px;
          border-right: none;
        }
      `}</style>
      <ModalSoftSkills
        visible={showSoftSkills}
        onClose={() => setShowSoftSkills(false)}
        data={dataUserSk}
      />
    </div >
  );
};

export default OfferTableSearch;