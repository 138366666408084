// src/actions/pages/myPortal/user/updateEmailPasswordActions.ts

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import {
  UPDATE_EMAIL_PASSWORD_REQUEST,
  UPDATE_EMAIL_PASSWORD_SUCCESS,
  UPDATE_EMAIL_PASSWORD_FAILURE,
  UpdateEmailPasswordActionTypes,
} from '../../../../../constants/pages/myPortal/generalSetting/UpdateEmailPassword';
import FetchWithIP from '../../../utils/FetchHeaders';

export const updateEmailPassword = (
  email: string,
  contrasenaActual: string,
  contrasenaNueva: string
): ThunkAction<Promise<any>, RootState, unknown, UpdateEmailPasswordActionTypes> => async (dispatch) => {
  dispatch({ type: UPDATE_EMAIL_PASSWORD_REQUEST });

  try {
    const response = await FetchWithIP(`auth/update-email-password`, {
      method: 'PATCH'},
      { email, contrasenaActual, contrasenaNueva },
  );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `Error HTTP: ${response.status}`);
    }

    const responseData = await response.json(); // Asegúrate de que esto sea correcto
    dispatch({ type: UPDATE_EMAIL_PASSWORD_SUCCESS, payload: responseData });
    return responseData; // Retornar la respuesta correcta
  } catch (error: any) {
    console.error('Error en updateEmailPassword:', error);
    dispatch({ type: UPDATE_EMAIL_PASSWORD_FAILURE, payload: error.message });
    throw error;
  }
};

