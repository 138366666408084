import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../redux/store/store';
import {
  FETCH_EMPRESAS_BY_SECTOR_REQUEST,
  FETCH_EMPRESAS_BY_SECTOR_SUCCESS,
  FETCH_EMPRESAS_BY_SECTOR_FAILURE,
  EmpresasBySectorActionTypes,
} from '../../../../constants/pages/company/GetCompanySector';
import FetchWithIP from '../../utils/FetchHeaders';

// Action Creators
export const fetchEmpresasBySectorRequest = (): EmpresasBySectorActionTypes => ({
  type: FETCH_EMPRESAS_BY_SECTOR_REQUEST,
});

export const fetchEmpresasBySectorSuccess = (data: any[]): EmpresasBySectorActionTypes => ({
  type: FETCH_EMPRESAS_BY_SECTOR_SUCCESS,
  payload: { data },
});

export const fetchEmpresasBySectorFailure = (error: string): EmpresasBySectorActionTypes => ({
  type: FETCH_EMPRESAS_BY_SECTOR_FAILURE,
  payload: error,
});

// Thunk Action para obtener las empresas por sector con un límite
export const GetEmpresasBySectorReducer = (
  empresaId: string, // ID del sector
  limit: number      // Número máximo de empresas a recuperar
): ThunkAction<Promise<any>, RootState, unknown, EmpresasBySectorActionTypes> => async (dispatch) => {
  dispatch(fetchEmpresasBySectorRequest());

  try {
    // Modificar la URL para incluir el límite en la consulta
    const response = await FetchWithIP(`empresas/findAllBySector/${empresaId}?limit=${limit}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    let empresasData;
    
    if (Array.isArray(responseData)) {
      empresasData = responseData;
    } else if (responseData.data && Array.isArray(responseData.data)) {
      empresasData = responseData.data;
    } else {
      empresasData = [responseData];
    }

    dispatch(fetchEmpresasBySectorSuccess(empresasData));
    return { payload: { data: empresasData } };

  } catch (error: any) {
    dispatch(fetchEmpresasBySectorFailure(error.message || 'Error al cargar las empresas por sector'));
    return { payload: { data: [] } };
  }
};
