import React, { useEffect, useState } from "react";
import { Button } from "antd";
import "tailwindcss/tailwind.css";
import "./OpenEstilos.css";
import { AppDispatch } from "../../../redux/store/store";
import { useDispatch, useSelector } from "react-redux";
import { GetLatestInscritosReducer } from "../../../redux/actions/pages/controlPanel/LatestRegistrations";
import TableComponentDessign from "../../table/TableDessign";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

const LatestRegistrants: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const {
    rex_latest_inscritos, 
    rex_meta
  } = useSelector(({latestInscritos}: any) => latestInscritos);
  const [sortColumn, setSortColumn] = useState<string>('id');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    dispatch(GetLatestInscritosReducer(currentPage, pageSize, sortColumn, sortOrder));
  }, [currentPage, pageSize, sortColumn, sortOrder]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSortChange = (column: string, order: string) => {
    setSortColumn(column);
    setSortOrder(order as 'asc' | 'desc');
  };

  const flattenData = (data: any[]) => {
    return data.map(item => ({
      id: item.id,
      nombre:`${item.usuarios.personas.nombre} ${item.usuarios.personas.apellido_paterno} ${item.usuarios.personas.apellido_materno}`,
      cargo: item.ofertas.cargo
    }));
  };

  const columns = [
    {
      title: "Nombre",
      key: "nombre",
      dataIndex: "nombre",
      sorter: true,
      ellipsis: true,
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: "Cargo Usuario",
      key: "Ofertas",
      dataIndex: "cargo",
      sorter: true,
      ellipsis: true,
      render: (text: string) => <span>{text}</span>,
    },
  ];

  return (
    <div className="px-[15px]">
      <h3 className="font-bold text-lg pb-[0px] mt-[-6px]">Ultimos Inscritos</h3>
      <div>
        <TableComponentDessign
          customDesign={true}
          columns={columns}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          pageSize={pageSize}
          getData={(current, order, column) => {
            handleSortChange(column, order);
            setCurrentPage(current);
          }} 
          data={flattenData(rex_latest_inscritos)} 
          meta={rex_meta}        
          />

        <Button
          onClick={() => navigate("/offer/createOffer")}
          style={{
            position: 'absolute',
            bottom: '40px'
          }}
          icon={<PlusOutlined />}
          className="principal-nav-notify-button2 w-[136px] h-[36px] mt-[0px] ml-0 bg-blue3 text-white"
        >
          Nueva oferta
        </Button>
      </div>
    </div>
  );
};

export default LatestRegistrants;
