import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../redux/store/store';
import {
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  SET_FILTER_PARAMS,
  CompanyActionTypes
} from '../../../../constants/pages/company/Company';
import FetchWithIP from '../../utils/FetchHeaders';

// Action Creators
export const fetchCompanyRequestReducer = (): CompanyActionTypes => ({
  type: FETCH_COMPANIES_REQUEST,
});

export const fetchCompanySuccessReducer = (data: any[], meta: { total: number; limit: number; page: number }): CompanyActionTypes => ({
  type: FETCH_COMPANIES_SUCCESS,
  payload: { data, meta }, // Almacena los datos y la información meta (total, limit, page)
});

export const fetchCompanyFailureReducer = (error: string): CompanyActionTypes => ({
  type: FETCH_COMPANIES_FAILURE,
  payload: error,
});

export const setFilterParams = (filters: any): CompanyActionTypes => ({
  type: SET_FILTER_PARAMS,
  payload: filters,
});

export const GetCompanyReducer = (
  page: number, 
  limit: number,
  valoraciones_empresasArray?: number[],
  palabras_claveArray?: string[],
  sectoresArray?: string[],
  tamanioArray?: { desde: number; hasta: number }[]
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(fetchCompanyRequestReducer());

  if (valoraciones_empresasArray) {
    console.log('hola-------------------------------------------------')
    dispatch(setFilterParams(valoraciones_empresasArray));
  }
  if (palabras_claveArray) {
    console.log('hola2222222222222222-------------------------------------------------')
    dispatch(setFilterParams(palabras_claveArray));
  }
  if (sectoresArray) {
    console.log('hola3333333333333333-------------------------------------------------')
    dispatch(setFilterParams(sectoresArray));
  }
  if (tamanioArray) {
    console.log('hola44444444444444444444-------------------------------------------------')
    console.log('tamanioArray:',tamanioArray)
    dispatch(setFilterParams(tamanioArray));
  }

  try {
    const response = await FetchWithIP(`empresas/mostrar?page=${page}&limit=${limit}&sortOrder=desc&sortColumn=createdAt`, {
      method: 'POST'
    }, {
      valoraciones_empresasArray,
      palabras_claveArray, 
      sectoresArray,
      tamanioArray
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    
    console.log('responseData:', responseData);

    if (Array.isArray(responseData.data)) {
      console.log('responseData:-------------------------------', responseData);
      const { total, limit, page } = responseData.meta; // Extraemos los datos de la API
      dispatch(fetchCompanySuccessReducer(
        responseData.data, { total, limit, page }));
        
    } else {
      throw new Error('Los datos obtenidos no son un array');
    }
    
    return

  } catch (error) {
    dispatch(fetchCompanyFailureReducer('Error al mostrar datos'));
  }
};
