import React from 'react';
import { Checkbox } from 'antd';
import '../styles/checkboxProps/CheckboxProps.css';

interface ValueObject {
  desde: number;
  hasta: number;
}

interface StyledCheckboxProps {
  value?: string | number | ValueObject;
  children?: React.ReactNode;
  className?: string;
  onChange?: (e: any) => void;
  checked?: boolean;
  onClick?:(e:any) => void
}

const StyledCheckbox: React.FC<StyledCheckboxProps> = ({ 
  value, 
  children, 
  className, 
  onChange, 
  checked,
  onClick = ()=>{}
}) => (
  <div className={className}>
    <Checkbox
      value={value}
      className="custom-checkbox"
      onChange={onChange}
      checked={checked}
      onClick={(e)=>e.stopPropagation()}
    >
      {children}
    </Checkbox>
  </div>
);

export default StyledCheckbox;