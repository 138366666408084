import React, { useState, useEffect } from "react";
import { Row, Col, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store/store";
import "tailwindcss/tailwind.css";
import IconEdit from "../../../assets/icons/EditP.svg";
import ModalEditPerfil from "../myPortal/Modals/ModalEditProfileInformation";

import dayjs from "dayjs";
import ValuationModal from "../modals/ModalQR";
import ModalEditAboutMe from "./Modals/ModalEditAboutMe";
import ModalEditProfileCompany from "./Modals/ModalEditProdileCompany";
import ModalEditProfileDescription from "./Modals/ModalEditProfileDescription";
import ReviewSection from "./ReviewSection";
import CarouselReviews from "../../carousels/CarouselReviews";
import { GetCompanyRatingsReducer } from "../../../redux/actions/pages/company/rating/GetRatingCompany";

interface ProfileCompanyProps {
  languages: { name: string; level: string }[];
}

const ProfileCompany: React.FC<ProfileCompanyProps> = ({ languages }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { rex_user } = useSelector(({ auth }: any) => auth);
  const {
    rex_companyRatings,
    rex_loading_companie
  } = useSelector(({ getRatingCompany }: any) => getRatingCompany);

  const [isModalPerfilVisible, setIsModalPerfilVisible] = useState(false);
  const [isModalAboutMeVisible, setIsModalAboutMeVisible] = useState(false);
  const [isModalValuation, setIsModalValuation] = useState(false);

  // useEffect(() => {
  // }, []);

  const handleEditPerfilClick = () => {
    setIsModalPerfilVisible(true);
  };

  const handleEditAboutMeClick = () => {
    setIsModalAboutMeVisible(true);
  };

  const handleModalClose = () => {
    setIsModalPerfilVisible(false);
    setIsModalAboutMeVisible(false);
  };

  useEffect(() => {
    if (rex_user.empresa.id) {
      dispatch(GetCompanyRatingsReducer(parseInt(rex_user.empresa.id, 10)))
    }
  }, [rex_user.empresa.id]);

  return (
    <div>
      <div className="pb-10">
        <h1 className="text-heading-md font-bold">Perfil</h1>
      </div>
      <Row>
        <Col xxl={18} xl={18}>
          <div className="pb-10">
            <h1 className="text-heading-x1 font-bold pb-3">
              Información de Perfil de Empresa
              <img
                src={IconEdit}
                alt="Editar"
                onClick={handleEditPerfilClick}
                className="inline-block text-sky-blue0 pl-3 cursor-pointer"
              />
            </h1>
            <h1 className="text-body-md font-medium text-green22">
              <span className="font-bold">Empresa:</span>{" "}
              {rex_user.empresa.empresa}
            </h1>
            <h1 className="text-body-md font-medium text-green22">
              <span className="font-bold">Pagina Web:</span>{" "}
              {rex_user.empresa.pagina_web}
            </h1>
            <h1 className="text-body-md font-medium text-green22">
              <span className="font-bold">Sede Fiscal:</span>{" "}
              {rex_user.empresa.sede_fiscal}
            </h1>
            <h1 className="text-body-md font-medium text-green22">
              <span className="font-bold">Número de Trabajadores:</span>{" "}
              {rex_user.empresa.tamanio || 0} Trabajadores
            </h1>
            {/* <h1 className="text-body-md font-medium text-green22">
              <span className="font-bold">Descripcion:</span>{" "}
              {rex_user.empresa.descripcion}
            </h1> */}
            <h1 className="text-body-md font-medium text-green22">
              <span className="font-bold">País:</span>{" "}
              {rex_user.empresa.ubicacion}
            </h1>
            {/* <h1 className="text-body-md font-medium text-green22">
              <span className="font-bold">Sectores:</span>{" "}
              {rex_user.empresa.sectores}
            </h1> */}
          </div>
        </Col>
        <Col
          xxl={6}
          xl={6}
          style={{ textAlignLast: "right" }}
          onClick={() => console.log(rex_user)}
        >
          <div className="pb-10">
            <h1 className="text-heading-x1 font-bold pb-3">
              Editar URL de valoración
              <img
                src={IconEdit}
                alt="Editar"
                onClick={() => {
                  setIsModalValuation(true);
                }}
                className="inline-block text-sky-blue0 pl-3 cursor-pointer"
              />
            </h1>
          </div>
        </Col>
      </Row>

      <div className="pb-10">
        <h1 className="text-heading-x1 font-bold pb-3">
          Descripción de la Empresa
          <img
            src={IconEdit}
            alt="Editar"
            onClick={handleEditAboutMeClick}
            className="inline-block text-sky-blue0 pl-3 cursor-pointer"
          />
        </h1>
        <h1 className="text-body-md font-medium text-green22 text-justify">
          {rex_user?.empresa.descripcion}
        </h1>
      </div>

      <h1 className="text-heading-md font-bold flex items-center">
        Valoraciones
        {/* <img
          src={IconShare}
          alt="Compartir"
          onClick={handleAddClick}
          className="inline-block text-sky-blue0 pl-3 cursor-pointer"
        /> */}
      </h1>

      {/* <div id="review-section"> */}
      <CarouselReviews
        border={true}
        data_reviews={rex_companyRatings?.valoraciones_empresas}
        loading={rex_loading_companie}
      />
      {/* </div> */}

      <ModalEditProfileCompany
        visible={isModalPerfilVisible}
        onClose={() => setIsModalPerfilVisible(false)}
        ID_empresa={rex_user.empresa.id}
      />

      <ModalEditProfileDescription
        visible={isModalAboutMeVisible}
        onClose={handleModalClose}
        ID_empresa={rex_user.empresa.id}
      />

      <ValuationModal
        visible={isModalValuation}
        onClose={() => setIsModalValuation(!isModalValuation)}
        editLink={true}
        link={rex_user?.link_valoracion}
        tipoUsuarioId={rex_user?.tipo_usuario_id}
      />

    </div>
  );
};
export default ProfileCompany;
