// src/reducers/pages/myPortal/validation/validateCodeReducer.ts

import {
    VALIDATE_CODE_REQUEST,
    VALIDATE_CODE_SUCCESS,
    VALIDATE_CODE_FAILURE,
    ValidateCodeActionTypes,
} from '../../../constants/auth/Validation';
  
  interface ValidateCodeState {
    isValidating: boolean;
    validationSuccess: boolean;
    validationError: string | null;
  }
  
  const initialState: ValidateCodeState = {
    isValidating: false,
    validationSuccess: false,
    validationError: null,
  };
  
  export const validateCodeReducer = (
    state = initialState,
    action: ValidateCodeActionTypes
  ): ValidateCodeState => {
    switch (action.type) {
      case VALIDATE_CODE_REQUEST:
        return {
          ...state,
          isValidating: true,
          validationSuccess: false,
          validationError: null,
        };
      case VALIDATE_CODE_SUCCESS:
        return {
          ...state,
          isValidating: false,
          validationSuccess: true,
          validationError: null,
        };
      case VALIDATE_CODE_FAILURE:
        return {
          ...state,
          isValidating: false,
          validationSuccess: false,
          validationError: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default validateCodeReducer;
  