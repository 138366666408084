import {
	FETCH_NOTIFICACTIONS_REQUEST,
	FETCH_NOTIFICATIONS_SUCCESS,
	FETCH_NOTIFICATIONS_FAILURE,
  DELETE_NOTIFICATIONS_REQUEST,
  DELETE_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATIONS_FAILURE,
	NotificationsActionTypes,
  FETCH_INFO_RESTRICT_NOTIFICATIONS_REQUEST,
  FETCH_INFO_RESTRICT_NOTIFICATIONS_SUCCESS,
  FETCH_INFO_RESTRICT_NOTIFICATIONS_FAILURE
} from '../../../../constants/common/notificacions/Notificacions';

// Estado inicial para las notificaciones
interface NotificationsState {
	rex_loading: boolean;
	rex_notifications: any[];
	rex_error: string | null;
  rex_restricted_notifications : any[]
}

const initialState: NotificationsState = {
	rex_loading: false,
	rex_notifications: [],
	rex_error: null,
  rex_restricted_notifications: []
};

const notificationsReducer = (state = initialState, action: NotificationsActionTypes): NotificationsState => {
  switch (action.type) {
    case FETCH_NOTIFICACTIONS_REQUEST:
      return {
        ...state,
        rex_loading: true,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_notifications: action.payload.data,
        rex_error: null,
      };
    case FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload,
      };

    case FETCH_INFO_RESTRICT_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        rex_loading: true,
      };
    case FETCH_INFO_RESTRICT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_restricted_notifications: action.payload.data,
        rex_error: null,
      };
    case FETCH_INFO_RESTRICT_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload,
      };

      case DELETE_NOTIFICATIONS_REQUEST:
        return {
          ...state,
          rex_loading: true,
        };
      case DELETE_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          rex_loading: false,
          rex_notifications: state.rex_notifications.filter(noti => noti.id !== action.payload),
          rex_error: null,
        };
      case DELETE_NOTIFICATIONS_FAILURE:
        return {
          ...state,
          rex_loading: false,
          rex_error: action.payload,
        };
    default:
      return state;
  }
};

export default notificationsReducer;