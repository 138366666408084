export const FETCH_USER_RATINGS_REQUEST = 'FETCH_USER_RATINGS_REQUEST';
export const FETCH_USER_RATINGS_SUCCESS = 'FETCH_USER_RATINGS_SUCCESS';
export const FETCH_USER_RATINGS_FAILURE = 'FETCH_USER_RATINGS_FAILURE';

export interface UserRating {
  usuario: {
    imagen: string | null;
    link_valoracion: string;
    personas: {
      nombre: string;
      apellido_paterno: string;
      apellido_materno: string;
    };
  };
}

export interface FetchUserRatingsRequestAction {
  type: typeof FETCH_USER_RATINGS_REQUEST;
}

export interface FetchUserRatingsSuccessAction {
  type: typeof FETCH_USER_RATINGS_SUCCESS;
  payload: UserRating;
}

export interface FetchUserRatingsFailureAction {
  type: typeof FETCH_USER_RATINGS_FAILURE;
  payload: string;
}

export type UserRatingsActionTypes =
  | FetchUserRatingsRequestAction
  | FetchUserRatingsSuccessAction
  | FetchUserRatingsFailureAction;
