// src/constants/pages/myPortal/portfolio/UpdatePortfolio.ts

export const CHANGE_DEFAULT_PORTFOLIO_REQUEST = 'CHANGE_DEFAULT_PORTFOLIO_REQUEST';
export const CHANGE_DEFAULT_PORTFOLIO_SUCCESS = 'CHANGE_DEFAULT_PORTFOLIO_SUCCESS';
export const CHANGE_DEFAULT_PORTFOLIO_FAILURE = 'CHANGE_DEFAULT_PORTFOLIO_FAILURE';

export const UPDATE_ARCHIVO_REQUEST = 'UPDATE_ARCHIVO_REQUEST';
export const UPDATE_ARCHIVO_SUCCESS = 'UPDATE_ARCHIVO_SUCCESS';
export const UPDATE_ARCHIVO_FAILURE = 'UPDATE_ARCHIVO_FAILURE';

export type UpdatePortfolioActionTypes =
  | { type: typeof CHANGE_DEFAULT_PORTFOLIO_REQUEST }
  | { type: typeof CHANGE_DEFAULT_PORTFOLIO_SUCCESS; payload: any }
  | { type: typeof CHANGE_DEFAULT_PORTFOLIO_FAILURE; payload: string }
  | { type: typeof UPDATE_ARCHIVO_REQUEST }
  | { type: typeof UPDATE_ARCHIVO_SUCCESS; payload: any }
  | { type: typeof UPDATE_ARCHIVO_FAILURE; payload: string };
