import React, { useState } from 'react';
import { Modal, Row, Col, Button, Divider } from 'antd';
import Sliders from "../../../components/pages/offers/CreateOffers/Slider";
import "../../../components/styles/pages/principalNav/PrincipalNav.css";

// Define el componente del modal
// const QuestionModal: React.FC<QuestionModalProps> = ({ visible, onClose }) => {
const QuestionModal: React.FC<any> = ({ visible, onClose }) => {
  return (
    <div>
      <Modal
        title={null}
        open={visible}
        onCancel={onClose}
        width={584}
        footer={null}
        centered={true} 
        closable={false} 
        style={{
          border: '2px solid #0077FF',
          borderRadius: '10px',
          overflow: 'hidden',
          padding: '0px',
        }}
      >
        <div className='pt-[35px] px-[24px]'>
          <h2 className="text-heading-md font-bold">
            Creación de perfil de soft skill
          </h2>
          <p className="text-body-md">
            Crea tu perfil ideal para esta oferta
          </p>
          <Divider className="bg-black" />

          <div className="w-full h-[398px]">
            <Sliders
              minLabel="Colaborativo"
              maxLabel="Autónomo"
              defaultValue={4} 
              skillId={0} 
              onChange={function (skillId: number, value: number): void {
                throw new Error('Function not implemented.');
              } }            
            />
            <Sliders
              minLabel="Innovador"
              maxLabel="Metódico"
              defaultValue={8} 
              skillId={0} 
              onChange={function (skillId: number, value: number): void {
                throw new Error('Function not implemented.');
              } }            
            />
            <Sliders
              minLabel="Detallista"
              maxLabel="Visionario"
              defaultValue={2} 
              skillId={0} 
              onChange={function (skillId: number, value: number): void {
                throw new Error('Function not implemented.');
              } }           
            />
            <Sliders
              minLabel="Resiliente"
              maxLabel="Proactivo"
              defaultValue={10} 
              skillId={0} 
              onChange={function (skillId: number, value: number): void {
                throw new Error('Function not implemented.');
              } }            
            />
            <Sliders
              minLabel="Adaptable"
              maxLabel="Consistente"
              defaultValue={6} 
              skillId={0} 
              onChange={function (skillId: number, value: number): void {
                throw new Error('Function not implemented.');
              } }            
            />
          </div>

          <Row gutter={[12,12]} className='flex justify-between mt-8'>
            <Col>
                <Button className="text-blue3 border border-white h-[36px] principal-nav-notify-buttonS ml-0" onClick={onClose}>
                  Resetear todos los parámetros
              </Button>
            </Col>
            <Col className='w-full md:w-auto'>
              <Row className='flex justify-center'>
                <Col>
                  <Button onClick={onClose} className="mr-2 principal-nav-notify-button h-[36px] border rounded-[4px]">
                    Cerrar
                  </Button>
                </Col>
                <Col>
                  <Button className='bg-blue3 text-white h-[36px] border rounded-[4px] principal-nav-notify-button2' onClick={onClose}>
                    Confirmar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default QuestionModal;
