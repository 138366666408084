export const FETCH_UPDATE_PROFILE_COMPANY_REQUEST = 'FETCH_UPDATE_PROFILE_COMPANY_REQUEST';
export const FETCH_UPDATE_PROFILE_COMPANY_SUCCESS = 'FETCH_UPDATE_PROFILE_COMPANY_SUCCESS';
export const FETCH_UPDATE_PROFILE_COMPANY_FAILURE = 'FETCH_UPDATE_PROFILE_COMPANY_FAILURE';

interface FetchUpdateProfileCompanyRequestAction {
  type: typeof FETCH_UPDATE_PROFILE_COMPANY_REQUEST;
}

interface FetchUpdateProfileCompanySuccessAction {
  type: typeof FETCH_UPDATE_PROFILE_COMPANY_SUCCESS;
  payload: any; // Tipo de la oferta detallada
}

interface FetchUpdateProfileCompanyFailureAction {
  type: typeof FETCH_UPDATE_PROFILE_COMPANY_FAILURE;
  payload: string;
}

export type UpdateProfileCompanyActionTypes =
  | FetchUpdateProfileCompanyRequestAction
  | FetchUpdateProfileCompanySuccessAction
  | FetchUpdateProfileCompanyFailureAction;