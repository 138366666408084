import { 
  FETCH_UPDATE_PROFILE_COMPANY_FAILURE, 
  FETCH_UPDATE_PROFILE_COMPANY_REQUEST, 
  FETCH_UPDATE_PROFILE_COMPANY_SUCCESS, 
  UpdateProfileCompanyActionTypes 
} from "../../../../../constants/pages/myPortal/profile/ProfileCompany";


interface ProfileCompanyState {
  rex_loading: boolean;
  rex_user_updated: any | null;
  rex_error: string | null;
}

const initialState: ProfileCompanyState = {
  rex_loading: false,
  rex_user_updated: null,
  rex_error: null,
};

const ProfileCompanyReducer = (
  state = initialState, action: UpdateProfileCompanyActionTypes
): ProfileCompanyState => {
  switch (action.type) {
    case FETCH_UPDATE_PROFILE_COMPANY_REQUEST:
      return {
        ...state,
        rex_loading: true,
      };
    case FETCH_UPDATE_PROFILE_COMPANY_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_user_updated: action.payload,
        rex_error: null,
      };
    case FETCH_UPDATE_PROFILE_COMPANY_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_user_updated: null,
        rex_error: action.payload,
      };
    default:
      return state;
  }
};

export default ProfileCompanyReducer;