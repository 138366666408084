// actions/pages/company/GetCompanySameSector.ts
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../redux/store/store';
import {
  FETCH_EMPRESAS_SAME_SECTOR_REQUEST,
  FETCH_EMPRESAS_SAME_SECTOR_SUCCESS,
  FETCH_EMPRESAS_SAME_SECTOR_FAILURE,
  EmpresasSameSectorActionTypes,
} from '../../../../constants/pages/company/GetCompanySame';
import FetchWithIP from '../../utils/FetchHeaders';

// Action Creators
export const fetchEmpresasSameSectorRequest = (): EmpresasSameSectorActionTypes => ({
  type: FETCH_EMPRESAS_SAME_SECTOR_REQUEST,
});

export const fetchEmpresasSameSectorSuccess = (data: any[]): EmpresasSameSectorActionTypes => ({
  type: FETCH_EMPRESAS_SAME_SECTOR_SUCCESS,
  payload: { data },
});

export const fetchEmpresasSameSectorFailure = (error: string): EmpresasSameSectorActionTypes => ({
  type: FETCH_EMPRESAS_SAME_SECTOR_FAILURE,
  payload: error,
});

// Thunk Action para obtener empresas del mismo sector
export const GetEmpresasSameSectorReducer = (
  empresaId: string,
  limit: number
): ThunkAction<Promise<any>, RootState, unknown, EmpresasSameSectorActionTypes> => async (dispatch) => {
  dispatch(fetchEmpresasSameSectorRequest());

  try {
    const response = await FetchWithIP(
      `empresas/findAllSameSector/${empresaId}?limit=${limit}`,
      {
        method: 'GET',
      }
    );

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    let empresasData;

    if (Array.isArray(responseData)) {
      empresasData = responseData// Se espera que sea un array
    } else if (responseData.data && Array.isArray(responseData.data)) {
      empresasData = responseData.data;
    } else {
        empresasData = [responseData];
    }

    dispatch(fetchEmpresasSameSectorSuccess(empresasData));
    return { payload: { data: empresasData } };

  } catch (error: any) {
    dispatch(fetchEmpresasSameSectorFailure(error.message || 'Error al cargar las empresas'));
    return { payload: { data: [] } };
  }
};
