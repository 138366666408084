// constants/company/ClosedOffers.ts

export const SEARCH_CLOSED_OFFERS_REQUEST = 'SEARCH_CLOSED_OFFERS_REQUEST';
export const SEARCH_CLOSED_OFFERS_SUCCESS = 'SEARCH_CLOSED_OFFERS_SUCCESS';
export const SEARCH_CLOSED_OFFERS_FAILURE = 'SEARCH_CLOSED_OFFERS_FAILURE';

interface FetchClosedOffersRequestAction {
  type: typeof SEARCH_CLOSED_OFFERS_REQUEST;
}

interface FetchClosedOffersSuccessAction {
  type: typeof SEARCH_CLOSED_OFFERS_SUCCESS;
  payload: {
    data: any[];
    meta: { total: number; limit: number; page: number };
  };
}

interface FetchClosedOffersFailureAction {
  type: typeof SEARCH_CLOSED_OFFERS_FAILURE;
  payload: string;
}

// Define los tipos de acciones para las ofertas finalizadas
export type ClosedOfferActionTypes =
  | FetchClosedOffersRequestAction
  | FetchClosedOffersSuccessAction
  | FetchClosedOffersFailureAction;
