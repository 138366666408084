// src/redux/actions/pages/companyRatings/GetCompanyRating.ts

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
  FETCH_COMPANY_RATINGS_REQUEST,
  FETCH_COMPANY_RATINGS_SUCCESS,
  FETCH_COMPANY_RATINGS_FAILURE,
  CompanyRatingsActionTypes,
  CompanyRating,
} from '../../../../constants/company/rating/GetCompanyLinkRating';
import FetchWithIP from '../../utils/FetchHeaders';

// Action Creators
export const fetchCompanyRatingsRequest = (): CompanyRatingsActionTypes => ({
  type: FETCH_COMPANY_RATINGS_REQUEST,
});

export const fetchCompanyRatingsSuccess = (data: CompanyRating): CompanyRatingsActionTypes => ({
  type: FETCH_COMPANY_RATINGS_SUCCESS,
  payload: data,
});

export const fetchCompanyRatingsFailure = (error: string): CompanyRatingsActionTypes => ({
  type: FETCH_COMPANY_RATINGS_FAILURE,
  payload: error,
});

// Thunk Action para obtener la valoración de la empresa
export const GetCompanyRating = (link_valoracion: string): ThunkAction<Promise<any>, RootState, unknown, CompanyRatingsActionTypes> => async (dispatch) => {
  dispatch(fetchCompanyRatingsRequest());

  try {
    const response = await FetchWithIP(`valoraciones-empresas/userAndCompanyDetails/${link_valoracion}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    dispatch(fetchCompanyRatingsSuccess(responseData));
    return responseData; // Retornamos la respuesta para manejarla en el frontend
  } catch (error: unknown) {
    const errorMessage = error instanceof Error ? error.message : 'Error desconocido al obtener la valoración de la empresa';
    dispatch(fetchCompanyRatingsFailure(errorMessage));
    return { error: errorMessage };
  }
};
