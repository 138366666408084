// src/actions/pages/myPortal/cv/getUserCVByIdActions.ts

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import {
  GET_ANSWERS_REQUEST,
  GET_ANSWERS_SUCCESS,
  GET_ANSWERS_FAILURE,
  GetAnswersActionTypes,
} from '../../../../../constants/pages/searchCV/answers/GetAnswers';
import FetchWithIP from '../../../utils/FetchHeaders';

export const getAnswersRequestReducer = (): GetAnswersActionTypes => ({
  type: GET_ANSWERS_REQUEST,
});

export const getAnswersSuccessReducer = (data: any[]): GetAnswersActionTypes => ({
  type: GET_ANSWERS_SUCCESS,
  payload: { data },
});

export const getAnswersFailureReducer = (error: string): GetAnswersActionTypes => ({
  type: GET_ANSWERS_FAILURE,
  payload: error,
});

export const GetAnswersReducer = (
  ofertaId: number,
  userId: number
): ThunkAction<Promise<any>, RootState, unknown, GetAnswersActionTypes> => async (dispatch) => {
  dispatch(getAnswersRequestReducer());

  try {
    const response = await FetchWithIP(`killers-questions/responses/${ofertaId}/${userId}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData && responseData.data) {
      dispatch(getAnswersSuccessReducer(responseData.data));
      return responseData;
    } else {
      throw new Error('Datos vacíos en la respuesta');
    }

  } catch (error: any) {
    dispatch(getAnswersFailureReducer(error.message || 'Error al obtener el CV del usuario'));
    return { payload: { data: [] } };
  }
};