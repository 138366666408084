// src/reducers/pages/myPortal/portfolio/updatePortfolioReducer.ts

import {
    CHANGE_DEFAULT_PORTFOLIO_REQUEST,
    CHANGE_DEFAULT_PORTFOLIO_SUCCESS,
    CHANGE_DEFAULT_PORTFOLIO_FAILURE,
    UPDATE_ARCHIVO_REQUEST,
    UPDATE_ARCHIVO_SUCCESS,
    UPDATE_ARCHIVO_FAILURE,
    UpdatePortfolioActionTypes,
} from '../../../../../constants/pages/myPortal/portfolio/PatchPortfolio';
  
  interface UpdatePortfolioState {
    loading: boolean;
    data: any | null;
    error: string | null;
  }
  
  const initialState: UpdatePortfolioState = {
    loading: false,
    data: null,
    error: null,
  };
  
  const updatePortfolioReducer = (state = initialState, action: UpdatePortfolioActionTypes): UpdatePortfolioState => {
    switch (action.type) {
      case CHANGE_DEFAULT_PORTFOLIO_REQUEST:
      case UPDATE_ARCHIVO_REQUEST:
        return { ...state, loading: true, error: null };
        
      case CHANGE_DEFAULT_PORTFOLIO_SUCCESS:
      case UPDATE_ARCHIVO_SUCCESS:
        return { ...state, loading: false, data: action.payload.data };
  
      case CHANGE_DEFAULT_PORTFOLIO_FAILURE:
      case UPDATE_ARCHIVO_FAILURE:
        return { ...state, loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  
  export default updatePortfolioReducer;
  