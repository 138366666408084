import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import FetchWithIP from '../../../utils/FetchHeaders';
import { FETCH_UPDATE_PROFILE_COMPANY_FAILURE, FETCH_UPDATE_PROFILE_COMPANY_REQUEST, FETCH_UPDATE_PROFILE_COMPANY_SUCCESS, UpdateProfileCompanyActionTypes } from '../../../../../constants/pages/myPortal/profile/ProfileCompany';

export const fetchUpdateProfileCompanyRequestReducer = (): UpdateProfileCompanyActionTypes => ({
  type: FETCH_UPDATE_PROFILE_COMPANY_REQUEST,
});

export const fetchUpdateProfileCompanySuccessReducer = (data: any): UpdateProfileCompanyActionTypes => ({
  type: FETCH_UPDATE_PROFILE_COMPANY_SUCCESS,
  payload: data,
});

export const fetchUpdateProfileCompanyFailureReducer = (error: string): UpdateProfileCompanyActionTypes => ({
  type: FETCH_UPDATE_PROFILE_COMPANY_FAILURE,
  payload: error,
});

export const UpdateProfileCompanyReducer = (
  data: {},
  ID_empresa: {}
): ThunkAction<Promise<void>, RootState, unknown, UpdateProfileCompanyActionTypes> =>
  async (dispatch) => {
    dispatch(fetchUpdateProfileCompanyRequestReducer());

    try {
      const response = await FetchWithIP(`empresas/${ID_empresa}`, {
        method: 'PATCH'
      }, data);

      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }

      const responseData = await response.json();

      if (responseData.data) {
        dispatch(fetchUpdateProfileCompanySuccessReducer(responseData.data));
        return response
      } else {
        throw new Error('No se encontraron datos de la oferta');
      }

    } catch (error) {
      dispatch(fetchUpdateProfileCompanyFailureReducer('Error al obtener los detalles de la oferta'));
    }
  };