import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store/store';
import {
  FETCH_SEARCH_APPLICATIONS_REQUEST,
  FETCH_SEARCH_APPLICATIONS_SUCCESS,
  FETCH_SEARCH_APPLICATIONS_FAILURE,
} from '../../../constants/applications/SearchApllications';
import FetchWithIP from '../utils/FetchHeaders';

export const searchApplicationsByCargo = (
  cargo: string,
  sector?: string,
  ubi_poblacion?: string,
  ubi_provincia?: string,
  page: number = 1,
  limit: number = 10
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_SEARCH_APPLICATIONS_REQUEST });

  try {
    // Construye la URL con los filtros adicionales si están presentes
    const queryParams = new URLSearchParams({
      cargo,
      page: page.toString(),
      limit: limit.toString(),
    });

    if (sector) queryParams.append("sector", sector);
    if (ubi_poblacion) queryParams.append("ubi_poblacion", ubi_poblacion);
    if (ubi_provincia) queryParams.append("ubi_provincia", ubi_provincia);

    const response = await FetchWithIP(
      `postulaciones/getAllByUserToken?${queryParams.toString()}`,
      { method: 'GET' }
    ).then(res => res.json());

    const applicationData = response.data
    // const applicationData = response.data.map((application: any) => ({
    //   id: application.id,
    //   usuario_id: application.usuario_id,
    //   oferta_id: application.oferta_id,
    //   createdAt: application.createdAt,
    //   updatedAt: application.updatedAt,
    //   empresa_id: application.ofertas?.empresa_id || application.empresa_id,
    //   ofertas: {
    //     id: application.ofertas.id,
    //     cargo: application.ofertas.cargo,
    //     descripcion: application.ofertas.descripcion,
    //     tipo: application.ofertas.tipo,
    //     ubi_provincia: application.ofertas.ubi_provincia,
    //     ubi_poblacion: application.ofertas.ubi_poblacion,
    //     sal_min: application.ofertas.sal_min,
    //     sal_max: application.ofertas.sal_max,
    //     anios_experiencia: application.ofertas.anios_experiencia,
    //     estudios_minimos: application.ofertas.estudios_minimos,
    //     tipo_contrato: application.ofertas.tipo_contrato,
    //     jornada_laboral: application.ofertas.jornada_laboral,
    //     createdAt: application.ofertas.createdAt,
    //   },
    // }));

    dispatch({
      type: FETCH_SEARCH_APPLICATIONS_SUCCESS,
      payload: {
        data: applicationData,
        meta: response.meta,
      },
    });
  } catch (error) {
    dispatch({
      type: FETCH_SEARCH_APPLICATIONS_FAILURE,
      error: (error as Error).message,
    });
    console.error('Error al buscar las aplicaciones por cargo', error);
  }
};
