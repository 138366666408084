import React, { useEffect } from 'react';
import { Avatar, Tag, Select, Button, Divider, Skeleton } from 'antd';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router-dom';
import '../../../styles/pages/offers/OffersProfileInfo.css';
import RatingBlue from '../../../rating/RatingBlue';
import CustomLegend from './Legend';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import { GetPostulacionesByOfertaReducer } from '../../../../redux/actions/pages/offers/offersProfile/GetOffersProfile';
import dayjs from 'dayjs';

const { Option } = Select;
interface SideAnswersProps {
  offerId: number;

}

const IMAGE_USER_DEFAULT = process.env.REACT_APP_IMAGE_USER_DEFAULT;
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

const data = [
  { subject: 'Colaborativo3', A: 7, B: 6, fullMark: 10 },
  { subject: 'Innovador', A: 6, B: 5, fullMark: 10 },
  { subject: 'Detallista', A: 5, B: 6, fullMark: 10 },
  { subject: 'Proactivo', A: 4, B: 4, fullMark: 10 },
  { subject: 'Adaptable', A: 6, B: 5, fullMark: 10 },
  { subject: 'Energético', A: 3, B: 4, fullMark: 10 },
  { subject: 'Empático', A: 2, B: 3, fullMark: 10 },
  { subject: 'Creativo', A: 7, B: 8, fullMark: 10 },
  { subject: 'Analítico', A: 5, B: 6, fullMark: 10 },
];

const PostulationSkeleton = () => (
  <div className="p-6 bg-gray-100 rounded-lg relative mb-8">
    <div className="flex flex-wrap -mx-3 relative">
      <div className="w-full md:w-3/4 px-3 relative">
        <div className="flex items-center mb-4">
          <Skeleton.Avatar active size={64} shape="square" />
          <div className="ml-4 flex-grow">
            <Skeleton.Input style={{ width: '60%' }} active size="small" />
            <Skeleton.Input style={{ width: '40%' }} active size="small" />
          </div>
        </div>
        <Skeleton active paragraph={{ rows: 2 }} />
        <Skeleton active paragraph={{ rows: 2 }} />
        <Skeleton active paragraph={{ rows: 2 }} />
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <div className="w-full md:w-1/4 px-3 mt-2 md:mt-0 relative">
        <div style={{ height: '200px' }}></div>
      </div>
    </div>
  </div>
);


const SideAnswers: React.FC<{ offerId: number }> = ({ offerId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { postulaciones, loading, error } = useSelector((state: RootState) => state.getOffersProfile);

  useEffect(() => {
    dispatch(GetPostulacionesByOfertaReducer(offerId));
  }, [dispatch, offerId]);

  const handleCandidateClick = (postulacionId: number, candidateId: number) => {
    if (offerId && postulacionId && candidateId) {
      navigate(`/offer/${offerId}/postulacion/${postulacionId}/candidate/${candidateId}`);
    } else {
      console.error("ID de la oferta, postulación o candidato no definidos");
    }
  };

  const getRadarChartData = (data: any) => {
    if (!data || !data.usuarios || !Array.isArray(data.usuarios.soft_skills_usuarios)) {
      console.warn("Datos inválidos o estructura incorrecta");
      return [];
    }
  
    return data.usuarios.soft_skills_usuarios.map((skill: any) => ({
      subject: skill.soft_skills.soft_skill,
      A: skill.porcentaje,
      B: 50,
      fullMark: 100
    }));
  };

  const calculateDuration = (startDate: string, endDate: string | null) => {
    const start = dayjs(startDate);
    const end = endDate ? dayjs(endDate) : dayjs();
    const months = end.diff(start, 'month');
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    return `${years} años, ${remainingMonths} meses`;
  };

  if (loading) {
    return (
      <div>
        <PostulationSkeleton />
        <PostulationSkeleton />
        <PostulationSkeleton />
      </div>
    );
  }

  if (error) return <div>Error al cargar las postulaciones: {error}</div>;

  return (
    <div className="-p-2 bg-gray-100 rounded-lg relative" style={{ width: '234px' }}>
      {postulaciones.map((postulacion) => (
        <div key={postulacion.usuarios.usuario} className="mb-4">
          <div className="-p-2 bg-gray-100 rounded-lg">
            {/* Avatar and Basic Info */}
            <div className="flex flex-col items-start">
                <Avatar
                    size={64}
                    src={postulacion.usuarios.imagen ? `${API_BASE_URL_EXACT}${postulacion.usuarios.imagen}` : IMAGE_USER_DEFAULT}
                    shape="square"
                    className="!rounded-none bg-white"
                />
                <div className="mt-2 text-start">
                    <h2 className="text-[18px] font-medium opacity-70">
                    {`${postulacion.usuarios.personas.nombre} ${postulacion.usuarios.personas.apellido_paterno}`}
                    </h2>
                    <p className="text-[16px] font-bold opacity-70">{postulacion.usuarios.cargo}</p>
                    <p className="text-[12px] opacity-70">{postulacion.usuarios.ubicacion}</p>
                </div>
                </div>

            {/* Rating */}
            <div className="mt-4">
              <h3 className="font-bold text-[12px]">Valoraciones</h3>
              <div className="flex items-center space-x-2">
                <RatingBlue totalStars={5} filledStars={postulacion.usuarios.promedioValoraciones} />
                {/* <p className="font-bold text-[14px]">{postulacion.usuarios.promedioValoraciones}</p> */}
              </div>
              <Tag className="text-[12px] mt-3 font-medium text-[#1A1A1A] opacity-70 border border-black rounded-full"
                         onClick={(e) => e.stopPropagation()}
                    >
                      Ver valoraciones
                    </Tag>
            </div>

            {/* Experience */}
            <div className="mt-4">
              <h3 className="font-bold text-[12px]">Experiencia</h3>
              {postulacion.usuarios.experiencias_laborales_usuarios.map((exp, index) => (
                <p key={index} className="text-[14px] opacity-70">
                  {`${exp.cargo} | ${exp.nombre_empresa} | ${dayjs(exp.fecha_inicio).format('MMM. YYYY')} - ${
                    exp.fecha_fin ? dayjs(exp.fecha_fin).format('MMM. YYYY') : 'Actual'
                  } (${calculateDuration(exp.fecha_inicio, exp.fecha_fin)})`}
                </p>
              ))}
            </div>


            {/* Education */}
            <div className="mt-4">
              <h3 className="font-bold text-[12px]">Educación</h3>
              {postulacion.usuarios.educacion_usuarios.map((edu, index) => (
                <p key={index} className="text-[14px] opacity-70">
                  {`${edu.carrera} | ${edu.nombre_centro_educativo} | ${dayjs(edu.fecha_inicio).format('MMM. YYYY')} - ${
                    edu.fecha_final ? dayjs(edu.fecha_final).format('MMM. YYYY') : 'Actual'
                  } (${calculateDuration(edu.fecha_inicio, edu.fecha_final)})`}
                </p>
              ))}
            </div>

            {/* CV Link */}
            <div className="flex flex-col items-start">
                  <div className="font-bold text-[12px] text-[#1A1A1A] opacity-80 mt-3">Búsqueda activa </div>
                  <div className="font-bold text-[12px] text-[#1A1A1A] opacity-80 mt-2 mb-2">Remoto admitido </div>
                  <div className="font-bold text-[12px] text-[#1A1A1A] opacity-80 mb-3">Jornada completa </div>
                  <Button type="link" className="font-bold text-[12px] -ml-[12px]" style={{ color: '#00476D' }} href={postulacion.usuarios.cvs_usuarios.length > 0 ? postulacion.usuarios.cvs_usuarios[0].nombre : "#"}>
                    Ver CV en pdf
                  </Button>
                  
                </div>

            <div className="flex flex-col items-start">
                  <div className="flex-1">
                  <div className="font-bold text-[12px] mb-2">Aptitudes</div>
                    {postulacion.usuarios.aptitudes_usuarios.map((apt: any, index: number) => (
                      <Tag key={index} className="block ml-2 mb-2 w-[58px] text-[12px] font-medium text-[#1A1A1A] opacity-70 border border-black rounded-full" >
                        {apt.aptitudes.aptitud}
                      </Tag>
                    ))}
                    <div className="font-bold text-[12px] mr-2 mb-2">Killer questions 7/10</div>
                    <Select
                      defaultValue="Seleccionar Respuesta"
                      className="text-[12px] font-medium !text-[#00476D] border border-[#00476D] rounded mr-2 h-7"
                      onChange={(value) => console.log("Respuesta seleccionada:", value)}
                    >
                      <Option value="1">Respuesta 1</Option>
                      <Option value="2">Respuesta 2</Option>
                      <Option value="3">Respuesta 3</Option>
                    </Select>
                  </div>
                </div>
          </div>
           {/* Radar Chart goes here */}
          <div className="mt-4 mb-10 w-full">
            <ResponsiveContainer width="100%" height={400}>
              <RadarChart cx="50%" cy="50%" outerRadius="70%" data={getRadarChartData(postulacion)}>
                <PolarGrid />
                <PolarAngleAxis
                  dataKey="subject"
                  tick={{ fontSize: 11, fill: '#000', fontWeight: 'Bold' }}
                />
                <PolarRadiusAxis angle={30} domain={[0, 10]} />
                <Radar name="Perfil del Candidato" dataKey="A" stroke="#006497" fill="transparent" />
                <Radar name="Perfil de la oferta" dataKey="B" stroke="#FDBCB4" fill="transparent" />
                <Legend content={<CustomLegend showOffer={true}/>} />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
          // {index < postulaciones.length - 1 && (
          //   <Divider className="bg-black my-8 mb-[45px]" />
          // )}
      ))}
    </div>
  );
};

export default SideAnswers;