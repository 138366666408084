import { Modal } from 'antd'
import React from 'react'

const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

const ModalVerCv: React.FC<{
  nombre: string;
  showModal: boolean;
  setShowModal: any;
  cv: string;
}> = ({
  nombre,
  showModal,
  setShowModal,
  cv
}) => {
  return (
    <div>
      <Modal
        title={"CV de " + nombre}
        visible={showModal}
        onOk={() => setShowModal(!showModal)}
        onCancel={() => setShowModal(!showModal)}
        // okText="Sí, cambiar fase"
        // cancelText="Cancelar"
        width={"80%"}
      // height={"100%"}
      >
        <iframe
          src={API_BASE_URL_EXACT && API_BASE_URL_EXACT + cv}
          width={"100%"}
          height={"650px"}
        />

        {/* <button
          onClick={() => {
            console.log(cvSeleccionado);

          }}
        >cli</button> */}

      </Modal>
    </div>
  )
}

export default ModalVerCv