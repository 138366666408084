// src/reducers/authReducer.ts
import {
  GET_DATA_USER_LOGIN_AUTH,
  VALIDATE_USER_AUTH
} from "../../../constants/auth/Auth";

interface UserProfile {
  id: number;
  empresa: { id: number } | null;
  email: string;
  contrasena: string;
  usuario: string;
  estado: string;
  link_valoracion: string;
  jornada: string | null;
  modalidad: string | null;
  pais: string | null;
  cargo: string | null;
  personas: {
    nombre: string;
    apellido_paterno: string;
    apellido_materno: string;
  } | null;
  // Agrega otras propiedades necesarias
}

interface AuthState {
  rex_user: UserProfile | null;
  rex_validate_user: boolean;
}

const INIT_STATE: AuthState = {
  rex_user: null,
  rex_validate_user: false
};

export default (state = INIT_STATE, action: any): AuthState => {
  switch (action.type) {
    case GET_DATA_USER_LOGIN_AUTH: {
      console.log(action.payload, "user data");
      return {
        ...state,
        rex_user: action.payload,
        rex_validate_user: true
      };
    }
    case VALIDATE_USER_AUTH: {
      return {
        ...state,
        rex_validate_user: action.payload,
      };
    }
    default:
      return state;
  }
};
