// src/constants/pages/myPortal/validation/ValidateCode.ts

export const VALIDATE_CODE_REQUEST = 'VALIDATE_CODE_REQUEST';
export const VALIDATE_CODE_SUCCESS = 'VALIDATE_CODE_SUCCESS';
export const VALIDATE_CODE_FAILURE = 'VALIDATE_CODE_FAILURE';

interface ValidateCodeRequestAction {
  type: typeof VALIDATE_CODE_REQUEST;
}

interface ValidateCodeSuccessAction {
  type: typeof VALIDATE_CODE_SUCCESS;
  payload: {
    data: any; // Respuesta de la API después de validar el código
  };
}

interface ValidateCodeFailureAction {
  type: typeof VALIDATE_CODE_FAILURE;
  payload: string;
}

export type ValidateCodeActionTypes =
  | ValidateCodeRequestAction
  | ValidateCodeSuccessAction
  | ValidateCodeFailureAction;
