// src/constants/pages/myPortal/user/UpdateEmailPassword.ts

export const UPDATE_EMAIL_PASSWORD_REQUEST = 'UPDATE_EMAIL_PASSWORD_REQUEST';
export const UPDATE_EMAIL_PASSWORD_SUCCESS = 'UPDATE_EMAIL_PASSWORD_SUCCESS';
export const UPDATE_EMAIL_PASSWORD_FAILURE = 'UPDATE_EMAIL_PASSWORD_FAILURE';

interface UpdateEmailPasswordRequestAction {
  type: typeof UPDATE_EMAIL_PASSWORD_REQUEST;
}

interface UpdateEmailPasswordSuccessAction {
  type: typeof UPDATE_EMAIL_PASSWORD_SUCCESS;
  payload: { email: string }; // El nuevo correo electrónico actualizado
}

interface UpdateEmailPasswordFailureAction {
  type: typeof UPDATE_EMAIL_PASSWORD_FAILURE;
  payload: string; // Mensaje de error
}

export type UpdateEmailPasswordActionTypes =
  | UpdateEmailPasswordRequestAction
  | UpdateEmailPasswordSuccessAction
  | UpdateEmailPasswordFailureAction;
