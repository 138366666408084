// src/constants/company/rating/GetCompanyLinkRating.ts

export const FETCH_COMPANY_RATINGS_REQUEST = 'FETCH_COMPANY_RATINGS_REQUEST';
export const FETCH_COMPANY_RATINGS_SUCCESS = 'FETCH_COMPANY_RATINGS_SUCCESS';
export const FETCH_COMPANY_RATINGS_FAILURE = 'FETCH_COMPANY_RATINGS_FAILURE';

export interface CompanyRating {
  usuario: {
    imagen: string | null;
    cargo: string;
    personas: {
      nombre: string;
      apellido_paterno: string;
      apellido_materno: string;
    };
  };
  empresa: {
    empresa: string;
    logo: string | null;
  };
}

export interface FetchCompanyRatingsRequestAction {
  type: typeof FETCH_COMPANY_RATINGS_REQUEST;
}

export interface FetchCompanyRatingsSuccessAction {
  type: typeof FETCH_COMPANY_RATINGS_SUCCESS;
  payload: CompanyRating;
}

export interface FetchCompanyRatingsFailureAction {
  type: typeof FETCH_COMPANY_RATINGS_FAILURE;
  payload: string;
}

export type CompanyRatingsActionTypes =
  | FetchCompanyRatingsRequestAction
  | FetchCompanyRatingsSuccessAction
  | FetchCompanyRatingsFailureAction;
