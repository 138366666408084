import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import { UPDATE_BANNER_FAILURE, UPDATE_BANNER_REQUEST, UPDATE_BANNER_SUCCESS, UPDATE_LOGO_FAILURE, UPDATE_LOGO_REQUEST, UPDATE_LOGO_SUCCESS, UpdateImagenCompanyActionTypes } from '../../../../constants/pages/company/UpdateImagenCompany';
import FetchWithIP from '../../utils/FetchHeaders';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const updateLogoRequest = (): UpdateImagenCompanyActionTypes => ({
  type: UPDATE_LOGO_REQUEST,
});

export const updateLogoSuccess = (data: any): UpdateImagenCompanyActionTypes => ({
  type: UPDATE_LOGO_SUCCESS,
  payload: data,
});

export const updateLogoFailure = (error: string): UpdateImagenCompanyActionTypes => ({
  type: UPDATE_LOGO_FAILURE,
  payload: error,
});

export const updateBannerRequest = (): UpdateImagenCompanyActionTypes => ({
  type: UPDATE_BANNER_REQUEST,
});

export const updateBannerSuccess = (data: any): UpdateImagenCompanyActionTypes => ({
  type: UPDATE_BANNER_SUCCESS,
  payload: data,
});

export const updateBannerFailure = (error: string): UpdateImagenCompanyActionTypes => ({
  type: UPDATE_BANNER_FAILURE,
  payload: error,
});

export const UpdateLogoReducer = (
  id_company: number,
  logo: File
): ThunkAction<Promise<{
  respuesta: boolean;
  data?: any;
  message?: string
}>,
  RootState,
  unknown,
  UpdateImagenCompanyActionTypes> => async (dispatch) => {
    dispatch(updateLogoRequest());

    try {
      const formData = new FormData();
      formData.append('imagen', logo); // Agrega la imagen al FormData

      if (!logo || logo.size === 0) {
        return { respuesta: false, message: 'El archivo está vacío o no es válido.' };
      }

      const response = await FetchWithIP(`empresas/updateLogo/${id_company}`,
        { method: 'PUT' },
        formData
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Error HTTP: ${response.status}`);
      }

      const responseData = await response.json();
      dispatch(updateLogoSuccess(responseData.data));

      return {
        respuesta: true,
        data: responseData.data
      };

    } catch (error: any) {
      dispatch(updateLogoFailure(error.message || 'Error al actualizar la imagen'));
      return { respuesta: false, message: error.message || 'Error al actualizar la imagen' };
    }
  };

export const UpdateBannerReducer = (
  id_company: number,
  banner: File
): ThunkAction<Promise<any>, RootState, unknown, UpdateImagenCompanyActionTypes> => async (dispatch) => {
  dispatch(updateBannerRequest());

  try {
    const formData = new FormData();
    formData.append('imagen_banner', banner);
    if (!banner || banner.size === 0) {
      console.log('El archivo es nulo o vacío');
      return;
    }
    const imagenFile = formData.get('banner');
    if (imagenFile instanceof File) {
      console.log('Nombre del archivo:', imagenFile.name);
    } else {
      console.log('No se encontró el archivo en FormData');
    }

    const response = await FetchWithIP(`empresas/updateBanner/${id_company}`,
      { method: 'PUT' },
      formData
    );

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();


    dispatch(updateBannerSuccess(responseData.data));

    return { success: true, data: responseData.data };

  } catch (error: any) {
    dispatch(updateBannerFailure(error.message || 'Error al actualizar el banner'));
    return { success: false, message: error.message || 'Error al actualizar el banner' };
  }
};