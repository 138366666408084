import React, { useEffect, useState } from "react";
import StyledCheckbox from "../../../../../components/checkbox/CheckboxProps";
import IconDrop from "../../../../../assets/icons/ArrowDrop.svg";
import IconDrop2 from "../../../../../assets/icons/ArrowDrop2.svg";
import ButtonText from "../../../../../components/button/ButtonText";
import { Spin } from "antd"; 
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../redux/store/store";
import { GetSectorsReducer } from "../../../../../redux/actions/common/sectors/Sectors";

interface SectorsProps {
  sectionTitle: string;
  onSectorsChange: (selectedSectors: string[]) => void; 
}

const Sectors: React.FC<SectorsProps> = ({ 
  sectionTitle, 
  onSectorsChange }) => {
    
  const dispatch = useDispatch<AppDispatch>();
  const [openSection, setOpenSection] = useState(false);
  const [sectors, setSectors] = useState<string[]>([]);
  const [visibleLimit, setVisibleLimit] = useState(5);
  const [loading, setLoading] = useState(false);
  const [totalSectors, setTotalSectors] = useState(0);
  const [sectoresArray, setSectoresArray] = useState<string[]>([]); 

  const fetchSectors = async () => {
    setLoading(true);

    try {
      const response = await dispatch(GetSectorsReducer('', 1, totalSectors || 100)); 
      const sectorList = response.payload.data.map((sectorObj: any) => sectorObj.sector);
   
      setSectors(sectorList);
      setTotalSectors(response.payload.meta.total);
    } catch (error) {
      console.error("Error fetching sectors:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchSectors();
  }, []);

  const handleCheckboxChange = (sector: string, checked: boolean) => {
    let updatedSectoresArray = [...sectoresArray];
    console.log('updatedSectoresArray:',updatedSectoresArray)
    if (checked) {
      updatedSectoresArray.push(sector); 
    } else {
      updatedSectoresArray = updatedSectoresArray.filter((s) => s !== sector); 
    }
    setSectoresArray(updatedSectoresArray);
    onSectorsChange(updatedSectoresArray); 
  };

  const toggleSection = () => {
    setOpenSection(!openSection);
  };

  const handleLoadMore = () => {
    if (visibleLimit >= totalSectors) {
      setVisibleLimit(5); 
    } else {
      setVisibleLimit(visibleLimit + 10); 
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center cursor-pointer" onClick={toggleSection}>
        <h3 className="font-bold text-heading-sm mb-2 text-green42">
          {sectionTitle}
        </h3>
        <img src={openSection ? IconDrop2 : IconDrop} alt="Toggle icon" />
      </div>

      {openSection && (
        <div>
          {sectors.slice(0, visibleLimit).map((sector, index) => (
            <StyledCheckbox
              className="mb-[8px]"
              key={index}
              value={sector}
              checked={sectoresArray.includes(sector)} // Controlar el estado de checked
              onChange={(e) => handleCheckboxChange(sector, e.target.checked)} // Escuchar cambios
            >
              <span className="font-bold text-body-md text-gray">{sector}</span>
            </StyledCheckbox>
          ))}

          {loading && (
            <div className="flex justify-center my-4">
              <Spin size="small" />
            </div>
          )}

          {sectors.length > 0 && (
            <div className="flex justify-center items-center">
              <ButtonText
                buttons={[
                  {
                    type: "link",
                    label: visibleLimit >= totalSectors ? "Ver menos" : "Ver más",
                    size: "small",
                    textColor: "#006497",
                    fontWeight: 700,
                    fontSize: "14px",
                    underline: true,
                    onClick: handleLoadMore,
                  },
                ]}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Sectors;
