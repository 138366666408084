import React, { useState, useEffect } from "react";
import { Modal, Button, Select, Input, Row, Col } from "antd";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import InputC from "../../../../components/pages/offers/KillerQuestions/ComponentsKillersQ/Input";
import ModalSaved from '../../myPortal/Modals/ModalSavedChanges';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store/store";
import { UpdateProfileReducer } from "../../../../redux/actions/pages/myPortal/profile/Profile";
import { ValidateTokenAuthReducer } from "../../../../redux/actions/auth/Auth";
import SelectBasic from "../../offers/CreateOffers/SelectBasic";
import { GetPaisesReducer } from "../../../../redux/actions/pages/emplotment/searchCountry";

interface ModalEditProfileProps {
  visible: boolean;
  onClose: () => void;
  context: "myPortal" | "OfferRegistration"; // Definir contextos diferentes
}

const ModalEditProfile: React.FC<ModalEditProfileProps> = ({
  visible, onClose, context
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { rex_user } = useSelector(({ auth }: any) => auth);
  const { rex_loading, rex_error } = useSelector(({ profile }: any) => profile);
  const paises = useSelector((state: RootState) => state.paises.rex_paises);
  const loading = useSelector((state: RootState) => state.paises.rex_loading);

  // Definimos los valores iniciales del formulario, incluyendo cargo y pais.
  const [formData, setFormData] = useState<Record<string, any>>({
    "nombre": rex_user?.personas?.nombre,
    "apellido_paterno": rex_user?.personas?.apellido_paterno,
    "apellido_materno": rex_user?.personas?.apellido_materno,
    "jornada": rex_user?.jornada,
    "modalidad": rex_user?.modalidad,
    "cargo": rex_user?.cargo || "", // Nuevo campo cargo
    "pais_id": rex_user?.pais_id || 0 // Nuevo campo pais
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);

  const [isAddingNewCountry, setIsAddingNewCountry] = useState(false); // Estado para manejar la opción de agregar nuevo país
  const [newCountry, setNewCountry] = useState(""); // Estado para el nuevo país

  // New states for country/city
  const [selectedCountry, setSelectedCountry] = useState<string | null>("");
  const [availableCities, setAvailableCities] = useState<any[]>([]);
  const [searchCiudad, setSearchCiudad] = useState<string | null>("");

  // Lista de países (Ejemplo, puedes reemplazar con datos de tu API)
  const countries = [1, 2, 3, 4];

  // Configuración de campos basada en el contexto
  const fieldConfigs = context === "myPortal"
    ? [
      { name: 'nombre', label: 'Nombre', value: '' },
      { name: 'apellido_paterno', label: 'Primer Apellido', value: '' },
      { name: 'apellido_materno', label: 'Segundo Apellido', value: '' },
      {
        name: 'jornada', label: 'Jornada Laboral', value: '',
        type: 'select',
        values: [
          { value: "completa", label: "Completa" },
          { value: "media", label: "Media Jornada" },
          { value: "flexible", label: "Flexible" },
        ]
      },
      {
        name: 'modalidad', label: 'Modalidad', value: '', type: 'select',
        values: [
          { value: "Presencial", label: "Presencial" },
          { value: "Híbrido", label: "Híbrido" },
          { value: "Remoto", label: "Remoto" },
        ]
      },
      { name: 'cargo', label: 'Cargo', value: '' }, // Nuevo campo cargo
    ]
    : [
      { name: 'fullName', label: 'Nombre Completo', value: '' },
      { name: 'email', label: 'Correo electrónico', value: '' },
      { name: 'cityState', label: 'Ciudad/Estado', value: '' },
      { name: 'phoneNumber', label: 'Número de teléfono', value: '' },
    ];

  // Manejar el cambio de inputs
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };

  // Manejar el cambio de select de país
  const handleCountryChange = (value: string) => {
    if (value === "new") {
      setIsAddingNewCountry(true);
    } else {
      setFormData({ ...formData, pais_id: value });
      setIsAddingNewCountry(false);
    }
  };

  // Validar si todos los campos están completos
  useEffect(() => {
    const allFieldsFilled = fieldConfigs.every(field => formData[field.name]?.length > 0);
    setIsSubmitDisabled(!allFieldsFilled || !formData.pais_id);
  }, [formData, fieldConfigs]);

  useEffect(() => {
    // Fetch countries when component mounts
    dispatch(GetPaisesReducer());
  }, []);

  useEffect(() => {
    if (selectedCountry && paises.length > 0) {
      const country = paises.find((c: any) => c.pais === selectedCountry);
      if (country) {
        setAvailableCities(country.ciudades);
      } else {
        setAvailableCities([]);
      }
    } else {
      setAvailableCities([]);
    }
  }, [selectedCountry, paises]);

  const handleSaveChanges = async () => {
    if (!isSubmitDisabled) {
      const personas = { personas: formData.nombre }
      // setFormData({
      //   "nombre": formData.nombre,
      //   "apellido_paterno": rex_user?.personas?.apellido_paterno,
      //   "apellido_materno": rex_user?.personas?.apellido_materno,
      //   "jornada": rex_user?.jornada,
      //   "modalidad": rex_user?.modalidad,
      //   "cargo": rex_user?.cargo || "",
      //   "pais_id": rex_user?.pais_id
      // })
      await dispatch(UpdateProfileReducer(formData));
      if (!rex_error) {
        await dispatch(ValidateTokenAuthReducer());
      }

      onClose(); // Cerrar el modal actual
      setIsSecondModalVisible(true); // Mostrar el segundo modal
    }
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{ borderRadius: "12px", border: "1px solid #E1E1E2", marginTop: '15px', marginBottom: '15px' }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />
        <div className="text-center mt-3 mb-[36px]">
          <h3 className="mt-[16px] text-heading-md font-bold">
            Editar Información de perfil
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, a continuación edita tu información
          </p>
        </div>
        <Row gutter={[16, 16]}>
          {fieldConfigs.map((field) => (
            <Col xxl={12} xl={12} md={12} key={field.name}>
              <div>
                <span className="text-body-md font-medium text-[#5F5F5F]">
                  {field.label}
                </span>
                {
                  field.type && field.type == 'select' ? (
                    <SelectBasic
                      showSearch
                      placeholder="Seleccionar"
                      size="large"
                      // value={{ value: values.jornada_laboral, label: values.jornada_laboral }}
                      // value={formData[field?.name] || ""}
                      onChange={(e) => handleInputChange(e, field.name)}
                      options={field.values}
                    />
                  ) : (
                    <InputC
                      placeholder=""
                      value={formData[field.name] || ""}
                      onChange={(e) => handleInputChange(e, field.name)}
                      style={{
                        // marginBottom: "23px",
                        borderRadius: "12px",
                        height: "36px",
                        // marginTop: "10px",
                      }}
                    />
                  )
                }

              </div>
            </Col>

          ))}
        </Row>
        <div className="mb-[32px] mt-[20px]">
          {/* Campo select para País */}
          {/* <div>
            <span className="text-body-md font-medium text-[#5F5F5F]">País</span>
            <Select
              value={isAddingNewCountry ? newCountry : formData.pais_id}
              onChange={handleCountryChange}
              style={{
                width: "100%",
                marginBottom: "23px",
                borderRadius: "12px",
                marginTop: "10px",
              }}
              className="border border-gray2 "
            >
              {countries.map((country) => (
                <Select.Option key={country} value={country}>
                  {country}
                </Select.Option>
              ))}
              <Select.Option value="new">Agregar nuevo país</Select.Option>
            </Select>

            {isAddingNewCountry && (
              <Input
                placeholder="Escribe el nuevo país"
                value={newCountry}
                onChange={(e) => setNewCountry(e.target.value)}
                onBlur={() => setFormData({ ...formData, pais_id: Number(newCountry) })}
                style={{
                  marginBottom: "23px",
                  borderRadius: "12px",
                  height: "36px",
                  marginTop: "10px",
                }}
              />
            )}
          </div> */}


          <Row gutter={[16, 16]}>
            <Col xxl={12} xl={12} xs={12} sm={12} md={12} lg={4}>
              <SelectBasic
                placeholder="País"
                style={{ width: "100%", height: '44px' }}
                onChange={(value, val: any) => {

                  setFormData({ ...formData, pais_id: val.id });
                  setIsAddingNewCountry(false);
                  setSelectedCountry(value)
                }}
                loading={loading}
                options={paises.map((country: any) => ({
                  value: country.pais,
                  label: country.pais,
                  id: country.id
                }))}
                showSearch={false}
              />
            </Col>
            <Col xxl={12} xl={12} xs={12} sm={12} md={12} lg={4}>
              <SelectBasic
                placeholder="Ciudad"
                style={{ width: "100%", height: '44px' }}
                disabled={!selectedCountry}
                options={availableCities.map((city: any) => ({
                  value: city.ciudad,
                  label: city.ciudad
                }))}
                onChange={(citie) => {
                  setSearchCiudad(citie)
                }}
                showSearch={false}
              />
            </Col>
          </Row>

          <div className="flex justify-center mt-[40px]">
            <Button
              onClick={onClose}
              className="principal-nav-notify-buttonG w-[118px] h-[44px]"
              style={{ marginRight: "8px", borderRadius: "4px" }}
            >
              Cancelar
            </Button>
            <Button
              disabled={rex_loading || isSubmitDisabled}
              onClick={handleSaveChanges}
              className={
                `w-[181px] h-[44px] ml-[18px] rounded-[4px] 
                ${rex_loading
                  ? 'bg-[#F4F4F5] text-[#757575] font-semibold text-body-md cursor-not-allowed'
                  : 'bg-blue3 principal-nav-notify-button2 text-white cursor-pointer'
                }
                `
              }
            >
              Guardar cambios
            </Button>
          </div>
        </div>
      </Modal>

      {/* Segundo Modal */}
      <ModalSaved
        visible={isSecondModalVisible}
        onClose={() => setIsSecondModalVisible(false)}
      />
    </>
  );
};

export default ModalEditProfile;
