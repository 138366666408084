import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import {
  FETCH_POSTULACIONES_REQUEST,
  FETCH_POSTULACIONES_SUCCESS,
  FETCH_POSTULACIONES_FAILURE,
  PostulacionesActionTypes,
  Postulacion,
  UPDATE_DATA_POSTULACIONES,
  UPDATE_DATA_SOFT_SKILLS_OFFER
} from '../../../../../constants/pages/offers/offersProfile/GetOffersProfile';
import FetchWithIP from '../../../utils/FetchHeaders';
import { DetailSoftSkillsOfferFormat } from '../../../../../constants/pages/searchCV/GetSearchCV';

export const fetchPostulacionesRequest = (): PostulacionesActionTypes => ({
  type: FETCH_POSTULACIONES_REQUEST,
});

export const fetchPostulacionesSuccess = (data: Postulacion[]): PostulacionesActionTypes => ({
  type: FETCH_POSTULACIONES_SUCCESS,
  payload: { data },
});

export const updateDataPostulaciones = (data: Postulacion[]): PostulacionesActionTypes => ({
  type: UPDATE_DATA_POSTULACIONES,
  payload: { data },
});

export const updateDataSoftSkillsOffer = (data: { users:any[], data:any[] }): PostulacionesActionTypes => ({
  type: UPDATE_DATA_SOFT_SKILLS_OFFER,
  payload: { 
    users:data.users,
    data:data.data 
  },
});

export const fetchPostulacionesFailure = (error: string): PostulacionesActionTypes => ({
  type: FETCH_POSTULACIONES_FAILURE,
  payload: error,
});

export const addOrRemoveUserOffers = (
  postulacion:any,
  addUser:boolean,
): ThunkAction<Promise<any>, RootState, unknown, any> => async (dispatch, getState) => {

  try {

    let { ofertas, usuarios } = postulacion
    let { soft_skills_offer, postulaciones } = getState().getOffersProfile;

    if(soft_skills_offer.data.length == 0){
      soft_skills_offer.data = ofertas.soft_skills_oferta.flatMap((item: DetailSoftSkillsOfferFormat, index:number) => [
          {
            subject: item.soft_skills.soft_skill,
            A: item.porcentaje > 0 ? item.porcentaje/10 : 0,
            fullMark: 10,
            index : index
          },
          {
            subject: item.soft_skills.soft_skill_c,
            A: 10 - (item.porcentaje > 0 ? item.porcentaje/10 : 0),
            fullMark: 10,
            index : index
          }
        ]
      )

      soft_skills_offer.data.sort((a,b) => {
        if(a.subject < b.subject) return -1
        if(a.subject > b.subject) return 1
        return 0
      })
    }

    if(addUser){
      soft_skills_offer.data.forEach((ofs:any) => {

        const findSubjectUser = usuarios.soft_skills_usuarios.find((usr:any) => usr.soft_skills.soft_skill == ofs.subject)

        if(findSubjectUser){
          ofs[usuarios.id] = findSubjectUser.nivel
          const findSubjectOpposite = soft_skills_offer.data.find((off:any) => off.index == ofs.index && off.subject != ofs.subject)
          if(findSubjectOpposite){
            findSubjectOpposite[usuarios.id] = 10 - (findSubjectUser.nivel)
          }
        }
      })

      soft_skills_offer.users.push({
        nombre : `${usuarios.personas.nombre} ${usuarios.personas.apellido_paterno} ${usuarios.personas.apellido_materno}`,
        id: usuarios.id,
        nameKey: usuarios.id.toString()
      })

    }else{

      soft_skills_offer.data.map((ofs:any) => {
        delete ofs[usuarios.id]
        return ofs
      })
      soft_skills_offer.users = soft_skills_offer.users.filter((usr:any) => usr.id != usuarios.id)
  }

  const idxUser: number = postulaciones.findIndex(pos => pos.usuarios.id == usuarios.id)
  postulaciones[idxUser]['checked'] = addUser
  dispatch(updateDataPostulaciones(postulaciones))
  dispatch(updateDataSoftSkillsOffer(soft_skills_offer))


  } catch (error) {
    return { payload: [] };
  }
};

export const GetPostulacionesByOfertaReducer = (
  idOferta: number
): ThunkAction<Promise<any>, RootState, unknown, PostulacionesActionTypes> => async (dispatch) => {
  dispatch(fetchPostulacionesRequest());

  try {
    const response = await FetchWithIP(`postulaciones/findAllPostulacionesByOferta/${idOferta}?sortOrder=desc&sortColumn=createdAt`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData && responseData.data) {
      dispatch(fetchPostulacionesSuccess(responseData.data));
      return responseData;
    } else {
      throw new Error('Datos vacíos en la respuesta');
    }
  } catch (error: any) {
    dispatch(fetchPostulacionesFailure(error.message || 'Error al cargar las postulaciones'));
    return { error: error.message };
  }
};
