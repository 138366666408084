import { 
  UPDATE_LOGO_FAILURE, 
  UPDATE_LOGO_REQUEST, 
  UPDATE_LOGO_SUCCESS, 
  UpdateImagenCompanyActionTypes 
} from "../../../../constants/pages/company/UpdateImagenCompany";

// Estado inicial para las UpdateImagen
interface UpdateImagenState {
  rex_loading: boolean;
  rex_logo: any[];
  rex_error: string | null;
  rex_meta: { total: number; limit: number; page: number } | undefined;
}

const initialState: UpdateImagenState = {
  rex_loading: false,
  rex_logo: [],
  rex_meta: { total: 0, limit: 10, page: 1 },
  rex_error: null,
};

const UpdateImagenCompanyReducer = (state = initialState, action: UpdateImagenCompanyActionTypes): UpdateImagenState => {
  switch (action.type) {
    case UPDATE_LOGO_REQUEST:
      return {
        ...state,
        rex_loading: true,
      };
    case UPDATE_LOGO_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_logo: [],
        rex_error: null,
      };
    case UPDATE_LOGO_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload,
      };
    default:
      return state;
  }
};

export default UpdateImagenCompanyReducer;
