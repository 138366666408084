export const FETCH_OFERTAS_BY_EMPRESA_REQUEST = 'FETCH_OFERTAS_BY_EMPRESA_REQUEST';
export const FETCH_OFERTAS_BY_EMPRESA_SUCCESS = 'FETCH_OFERTAS_BY_EMPRESA_SUCCESS';
export const FETCH_OFERTAS_BY_EMPRESA_FAILURE = 'FETCH_OFERTAS_BY_EMPRESA_FAILURE';

export interface FetchOfertasByEmpresaRequestAction {
  type: typeof FETCH_OFERTAS_BY_EMPRESA_REQUEST;
}

export interface FetchOfertasByEmpresaSuccessAction {
  type: typeof FETCH_OFERTAS_BY_EMPRESA_SUCCESS;
  payload: { data: any[] };
}

export interface FetchOfertasByEmpresaFailureAction {
  type: typeof FETCH_OFERTAS_BY_EMPRESA_FAILURE;
  payload: string;
}

export type OfertasByEmpresaActionTypes =
  | FetchOfertasByEmpresaRequestAction
  | FetchOfertasByEmpresaSuccessAction
  | FetchOfertasByEmpresaFailureAction;
