// constants/offer/SearchOffers.ts

export const SEARCH_OFFERS_REQUEST = 'SEARCH_OFFERS_REQUEST';
export const SEARCH_OFFERS_SUCCESS = 'SEARCH_OFFERS_SUCCESS';
export const SEARCH_OFFERS_FAILURE = 'SEARCH_OFFERS_FAILURE';

interface FetchOffersRequestAction {
    type: typeof SEARCH_OFFERS_REQUEST;
  }
  
  interface FetchOffersSuccessAction {
    type: typeof SEARCH_OFFERS_SUCCESS;
    payload: {
      data: any[];
      meta: { total: number; limit: number; page: number, cargo:string };
    };
  }
  
  interface FetchOffersFailureAction {
    type: typeof SEARCH_OFFERS_FAILURE;
    payload: string;
  }

export type OfferActionTypes =
    | FetchOffersRequestAction
    | FetchOffersSuccessAction
    | FetchOffersFailureAction
    ;