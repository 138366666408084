// src/reducers/pages/myPortal/user/updateEmailPasswordReducer.ts

import {
    UPDATE_EMAIL_PASSWORD_REQUEST,
    UPDATE_EMAIL_PASSWORD_SUCCESS,
    UPDATE_EMAIL_PASSWORD_FAILURE,
    UpdateEmailPasswordActionTypes,
} from '../../../../../constants/pages/myPortal/generalSetting/UpdateEmailPassword';
  
  interface UpdateEmailPasswordState {
    loading: boolean;
    success: boolean;
    error: string | null;
    email: string | null;
  }
  
  const initialState: UpdateEmailPasswordState = {
    loading: false,
    success: false,
    error: null,
    email: null,
  };
  
  export const updateEmailPasswordReducer = (
    state = initialState,
    action: UpdateEmailPasswordActionTypes
  ): UpdateEmailPasswordState => {
    switch (action.type) {
      case UPDATE_EMAIL_PASSWORD_REQUEST:
        return {
          ...state,
          loading: true,
          success: false,
          error: null,
        };
      case UPDATE_EMAIL_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          email: action.payload.email, // Guarda el nuevo correo electrónico
        };
      case UPDATE_EMAIL_PASSWORD_FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default updateEmailPasswordReducer;
  