import React, { useEffect } from 'react';
import { Card, Checkbox, Divider, Input, Radio, Slider } from 'antd';
import Header from '../../../components/pages/principalNav/HeaderOffers';
import SideAnswers from '../../../components/pages/offers/offersProfile/SideAnswers';
import { useParams } from 'react-router-dom';
import '../../../styles/pages/offers/answers.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store/store';
import { GetAnswersReducer } from '../../../redux/actions/pages/searchCV/answers/GetAnswers';
import CustomRadio from '../../../components/pages/offers/KillerQuestions/ComponentsKillersQ/CustomRadio';
import CustomInputNumber from '../../../components/pages/offers/KillerQuestions/ComponentsKillersQ/CustomInputNumber';
// import CustomRadio from "../../../pages/offers/KillerQuestions/";

const Answers: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { offerId, candidateName, userId } = useParams<{ offerId?: string; candidateName?: string; userId?: string }>();

  const {
    rex_answers_killers_questions
  } = useSelector(({ getAnswers }: any) => getAnswers);

  // Validamos que offerId tenga un valor numérico válido
  const parsedOfferId = offerId ? parseInt(offerId) : 0;

  useEffect(() => {
    if (offerId && userId) {
      dispatch(GetAnswersReducer(+offerId, +userId))
    }
  }, [])

  return (
    <div className="answers-container">

      <div className="answers-content">
        {/* Lado izquierdo: SideAnswers */}
        <Card className="border-[#81BFEC] bg-[#FBFDFE] rounded-md p-4 mb-4">
          <SideAnswers offerId={parsedOfferId} />
        </Card>

        {/* Lado derecho: Detalles de las respuestas */}
        <div className="answers-details">
          <h2 className="answers-title">
            Respuestas {candidateName || 'Desconocido'}
          </h2>
          <Divider className="answers-divider" />
          {/* Aquí puedes agregar más detalles o comentarios adicionales */}



          {
            rex_answers_killers_questions.map((question: any, index: number) => {
              return (
                <div className="mb-8">
                  <p className="font-bold text-heading-x1 ">
                    {index + 1}. {question.pregunta}
                  </p>
                  {
                    question.tipos_preguntas.tipo == 'lineal'
                      ? <div className="max-w-full mt-[10px]">
                        <div className="flex justify-between mb-2 font-medium text-caption">
                          {
                            question.detalle_killers.map((detalle: any) => {
                              return (
                                <span>{detalle.detalle}</span>
                              )
                            })
                          }
                        </div>
                        <Slider
                          min={0}
                          max={question.detalle_killers.length - 1}
                          step={1}
                          value={+question?.respuestas_killer_questions[0]?.respuesta}
                          onChange={(e) => { }}
                          tooltip={{ formatter: null }}
                          trackStyle={{ backgroundColor: "#0778b1" }}
                          railStyle={{ backgroundColor: "#d9d9d9" }}
                        />
                      </div>
                      : question.tipos_preguntas.tipo == 'radio'
                        ? <Radio.Group
                          className="mt-4 font-bold text-body-md"
                          onChange={(e) => {

                          }}
                          value={+question.respuestas_killer_questions[0].respuesta}
                        >
                          {
                            question.detalle_killers.map((detalle: any) => {
                              return (
                                <CustomRadio
                                  value={detalle.id}
                                >
                                  {detalle.detalle}
                                </CustomRadio>
                              )
                            })
                          }
                        </Radio.Group>
                        : question.tipos_preguntas.tipo == 'numerico'
                          ? <div className="flex flex-wrap items-center mt-[10px]">
                            <CustomInputNumber
                              value={question.respuestas_killer_questions[0].respuesta}
                              onChange={() => {}}
                              onchangeDefault={(value: any) => {}}
                            />
                          </div>
                          : question.tipos_preguntas.tipo == 'checkbox'
                            ? <div className="flex flex-wrap">
                              {question.detalle_killers.map((detalle: any, index_detalle: number) => {
                                let check = false

                                if (question.respuestas_killer_questions[0].detalles_respuestas_killer_questions.find((dt: any) => dt.detalle_killer_question_id == detalle.id)) {
                                  check = true
                                }

                                return (
                                  <div key={index_detalle} className="flex items-center mb-3 mr-5">
                                    <Checkbox
                                      className="custom-checkbox"
                                      onChange={(e) => {



                                      }}
                                      checked={check}
                                    >
                                    </Checkbox>
                                    <span className="ml-1 font-bold text-body-md mr-[15px]">
                                      {detalle.detalle}
                                    </span>
                                  </div>
                                )
                              })}
                            </div>
                            : question.tipos_preguntas.tipo == 'respuesta'
                              ? <Input.TextArea
                                rows={5}
                                className="w-full 
                                border
                                my-[8px]
                                border-[#D9D9D9] 
                                placeholder:text-green32 
                                focus:placeholder:text-grays 
                                hover:placeholder:text-black 
                                hover:bg-gray3 
                                hover:border-2 
                                hover:border-[#D9D9D9]
                                hover:text-black 
                                focus:border-4 
                                focus:border-[#91c3fd] 
                                focus:text-[#757575]
                                rounded-[8px] 
                                transition-all 
                                duration-200 
                                text-[#757575]
                                font-normal
                                text-body-md"
                                style={{ height: 80 }}
                                onChange={(e) => {


                                }}
                                value={question.respuestas_killer_questions[0].respuesta}
                                disabled
                              />
                              : null
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default Answers;
