// src/constants/pages/myPortal/portfolio/GetUserPortfolioById.ts

export const GET_USER_PORTFOLIO_BY_ID_REQUEST = 'GET_USER_PORTFOLIO_BY_ID_REQUEST';
export const GET_USER_PORTFOLIO_BY_ID_SUCCESS = 'GET_USER_PORTFOLIO_BY_ID_SUCCESS';
export const GET_USER_PORTFOLIO_BY_ID_FAILURE = 'GET_USER_PORTFOLIO_BY_ID_FAILURE';


interface ArchivoPortafolio {
  id: number;
  portafolio_usuario_id: number;
  titulo: string;
  nombre_archivo: string;
  archivo: string;
  createdAt: string;
  updatedAt: string;
}

interface SoftSkillPortafolio {
  id: number;
  soft_skill_id: number;
  portafolio_usuario_id: number;
  soft_skills: {
    id: number;
    soft_skill: string;
    aprobado: boolean;
    createdAt: string;
    updatedAt: string;
  };
}

export interface Portfolio {
  id: number;
  usuario_id: number;
  nombre: string;
  nombre_archivo: string;
  portafolio: string;
  default: boolean;
  titulo: string;
  descripcion: string;
  url: string;
  createdAt: string;
  updatedAt: string;
  soft_skills_portafolio: SoftSkillPortafolio[];
  archivos_portafolio: ArchivoPortafolio[];
}

interface GetUserPortfolioByIdRequestAction {
  type: typeof GET_USER_PORTFOLIO_BY_ID_REQUEST;
}

interface GetUserPortfolioByIdSuccessAction {
  type: typeof GET_USER_PORTFOLIO_BY_ID_SUCCESS;
  payload: {
    data: any; // Datos del portafolio del usuario
  };
}

interface GetUserPortfolioByIdFailureAction {
  type: typeof GET_USER_PORTFOLIO_BY_ID_FAILURE;
  payload: string;
}

export type GetUserPortfolioByIdActionTypes =
  | GetUserPortfolioByIdRequestAction
  | GetUserPortfolioByIdSuccessAction
  | GetUserPortfolioByIdFailureAction;