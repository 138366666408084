import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
  FETCH_NOTIFICACTIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  NotificationsActionTypes,
  DELETE_NOTIFICATIONS_REQUEST,
  DELETE_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATIONS_FAILURE,
  FETCH_RESTRICT_NOTIFICATIONS_REQUEST,
  FETCH_RESTRICT_NOTIFICATIONS_SUCCESS,
  FETCH_RESTRICT_NOTIFICATIONS_FAILURE,
  FETCH_INFO_RESTRICT_NOTIFICATIONS_REQUEST,
  FETCH_INFO_RESTRICT_NOTIFICATIONS_SUCCESS,
  FETCH_INFO_RESTRICT_NOTIFICATIONS_FAILURE

} from '../../../../constants/common/notificacions/Notificacions';
import FetchWithIP from '../../utils/FetchHeaders';

const API_URL = process.env.REACT_APP_API_BASE_URL; // Usar la URL de la variable de entorno

export const fetchNotificationsRequest = (): NotificationsActionTypes => ({
  type: FETCH_NOTIFICACTIONS_REQUEST,
})

export const fetchNotificationsSuccess = (data: any): NotificationsActionTypes => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: data,
})

export const fetchNotificationsFailure = (error: string): NotificationsActionTypes => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  payload: error,
});

export const deleteNotificationsRequest = (): NotificationsActionTypes => ({
  type: DELETE_NOTIFICATIONS_REQUEST,
});

export const deleteNotificationsSuccess = (id: number): NotificationsActionTypes => ({
  type: DELETE_NOTIFICATIONS_SUCCESS,
  payload: id,
});

export const deleteNotificationsFailure = (error: string): NotificationsActionTypes => ({
  type: DELETE_NOTIFICATIONS_FAILURE,
  payload: error,
});

export const fetchRestrictNotificationsRequest = (): NotificationsActionTypes => ({
  type: FETCH_RESTRICT_NOTIFICATIONS_REQUEST,
});

export const fetchRestrictNotificationsSuccess = (id: number): NotificationsActionTypes => ({
  type: FETCH_RESTRICT_NOTIFICATIONS_SUCCESS,
  payload: id,
});

export const fetchRestrictNotificationsFailure = (error: string): NotificationsActionTypes => ({
  type: FETCH_RESTRICT_NOTIFICATIONS_FAILURE,
  payload: error,
});

export const fetchInfoRestrictNotificationsRequest = (): NotificationsActionTypes => ({
  type: FETCH_INFO_RESTRICT_NOTIFICATIONS_REQUEST,
});

export const fetchInfoRestrictNotificationsSuccess = (data: any): NotificationsActionTypes => ({
  type: FETCH_INFO_RESTRICT_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const fetchInfoRestrictNotificationsFailure = (error: string): NotificationsActionTypes => ({
  type: FETCH_INFO_RESTRICT_NOTIFICATIONS_FAILURE,
  payload: error,
})

export const fetchInfoNotificationsReducer = (filter?: string): ThunkAction<Promise<void>, RootState, unknown, NotificationsActionTypes> => async (dispatch) => {
  dispatch(fetchInfoRestrictNotificationsRequest());
  
  try {
    const response = await FetchWithIP(`notificaciones-restringidas-usuarios/findNotificationsRestricted`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData && responseData.data) {
      dispatch(fetchInfoRestrictNotificationsSuccess({ data: responseData.data }));
    } else {
      throw new Error('No data in response');
    }
  } catch (error: any) {
    dispatch(fetchInfoRestrictNotificationsFailure(error.message || 'An error occurred while fetching notifications'));
  }
};

export const fetchNotificationsReducer = (filter?: string): ThunkAction<Promise<void>, RootState, unknown, NotificationsActionTypes> => async (dispatch) => {
  dispatch(fetchNotificationsRequest());

  try {
    const response = await FetchWithIP(`notificaciones${filter ? `?filter=${filter}` : '/'}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData && responseData.data) {
      dispatch(fetchNotificationsSuccess({ data: responseData.data }));
    } else {
      throw new Error('No data in response');
    }
  } catch (error: any) {
    dispatch(fetchNotificationsFailure(error.message || 'An error occurred while fetching notifications'));
  }
};

export const DeleteNotificationReducer = (id: number): ThunkAction<Promise<any>, RootState, unknown, NotificationsActionTypes> => async (dispatch) => {
  dispatch(deleteNotificationsRequest());

  try {
    const response = await fetch(`${API_URL}notificaciones/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    dispatch(deleteNotificationsSuccess(id));
    return id;
  } catch (error) {
    dispatch(deleteNotificationsFailure('Error al eliminar la notificacion'));
    throw error;
  }
}

export const createRestrictNotificationReducer = (
  id_tipo_notificacion: number
): ThunkAction<Promise<any>, RootState, unknown, NotificationsActionTypes> => async (dispatch, getState) => {

  dispatch(fetchRestrictNotificationsRequest())

  try {
    const response = await FetchWithIP('notificaciones-restringidas-usuarios', {
      method: 'POST',
    }, {id : id_tipo_notificacion});

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    dispatch(fetchInfoNotificationsReducer())
    return responseData;
  } catch (error: any) {
    dispatch(fetchRestrictNotificationsFailure(error.message || 'Error al actualizar las notificaciones restringidas'));
    return { error: error.message };
  }
};