export const RESEND_CODE_REQUEST = 'RESEND_CODE_REQUEST';
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS';
export const RESEND_CODE_FAILURE = 'RESEND_CODE_FAILURE';

interface ResendCodeRequestAction {
  type: typeof RESEND_CODE_REQUEST;
}

interface ResendCodeSuccessAction {
  type: typeof RESEND_CODE_SUCCESS;
  payload: {
    data: any; // Respuesta de la API después de reenviar el código
  };
}

interface ResendCodeFailureAction {
  type: typeof RESEND_CODE_FAILURE;
  payload: string;
}

export type ResendCodeActionTypes =
  | ResendCodeRequestAction
  | ResendCodeSuccessAction
  | ResendCodeFailureAction;
