import {
    RESEND_CODE_REQUEST,
    RESEND_CODE_SUCCESS,
    RESEND_CODE_FAILURE,
    ResendCodeActionTypes,
} from '../../../constants/auth/ResendCode';

interface ResendCodeState {
    rex_isResending: boolean;
    rex_resendSuccess: boolean;
    rex_resendError: string | null;
}

const initialState: ResendCodeState = {
    rex_isResending: false,
    rex_resendSuccess: false,
    rex_resendError: null,
};

export const resendCodeReducer = (
    state = initialState,
    action: ResendCodeActionTypes
): ResendCodeState => {
    switch (action.type) {
        case RESEND_CODE_REQUEST:
            return {
                ...state,
                rex_isResending: true,
                rex_resendSuccess: false,
                rex_resendError: null,
            };
        case RESEND_CODE_SUCCESS:
            return {
                ...state,
                rex_isResending: false,
                rex_resendSuccess: true,
                rex_resendError: null,
            };
        case RESEND_CODE_FAILURE:
            return {
                ...state,
                rex_isResending: false,
                rex_resendSuccess: false,
                rex_resendError: action.payload,
            };
        default:
            return state;
    }
};

export default resendCodeReducer;
