import {
  SEARCH_SAVED_OFFERS_REQUEST,
  SEARCH_SAVED_OFFERS_SUCCESS,
  SEARCH_SAVED_OFFERS_FAILURE,
  SavedOfferActionTypes,
} from '../.././../../constants/company/SearchSaveOffers';

interface SavedOffersState {
  rex_saved_offers: any[];
  rex_loading: boolean;
  error: string | null;
  meta: { total: number; limit: number; page: number } | null;
}

const initialState: SavedOffersState = {
  rex_saved_offers: [],
  rex_loading: false,
  error: null,
  meta: null,
};

const savedOffersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SEARCH_SAVED_OFFERS_REQUEST:
      return { ...state, rex_loading: true, error: null };

    case SEARCH_SAVED_OFFERS_SUCCESS:
      return {
        ...state,
        rex_saved_offers: action.payload.data,
        rex_loading: false,
        meta: action.payload.meta,
        page: action.payload.page,
        rex_error: null,
      };

    case SEARCH_SAVED_OFFERS_FAILURE:
      return { ...state, rex_loading: false, error: action.payload };

    default:
      return state;
  }
};
export default savedOffersReducer;