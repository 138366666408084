import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store/store';
import {
  RESEND_CODE_REQUEST,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_FAILURE,
  ResendCodeActionTypes,
} from '../../../constants/auth/ResendCode';
import FetchWithIP from '../utils/FetchHeaders';

export const resendCodeRequest = (): ResendCodeActionTypes => ({
  type: RESEND_CODE_REQUEST,
});

export const resendCodeSuccess = (data: any): ResendCodeActionTypes => ({
  type: RESEND_CODE_SUCCESS,
  payload: { data },
});

export const resendCodeFailure = (error: string): ResendCodeActionTypes => ({
  type: RESEND_CODE_FAILURE,
  payload: error,
});

export const resendCode = (email: string): ThunkAction<Promise<any>, RootState, unknown, ResendCodeActionTypes> => async (dispatch) => {
  dispatch(resendCodeRequest());

  try {
    const response = await FetchWithIP('auth/resendCode', {
      method: 'POST' },
      { email },
   );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    dispatch(resendCodeSuccess(responseData));
    return responseData;

  } catch (error: any) {
    dispatch(resendCodeFailure(error.message));
    throw error;
  }
};
