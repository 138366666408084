import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
  FETCH_LATEST_INSCRITOS_FAILURE,
  FETCH_LATEST_INSCRITOS_REQUEST,
  FETCH_LATEST_INSCRITOS_SUCCESS,
  LatestInscritosActionTypes
} from '../../../../constants/pages/controlPanel/LatestRegistrations'
import FetchWithIP, { FetchWithIPGet } from '../../utils/FetchHeaders';

export const fetchLatestInscritosRequestReducer = (): LatestInscritosActionTypes => ({
  type: FETCH_LATEST_INSCRITOS_REQUEST,
});

export const fetchLatestInscritosSuccessReducer = (data: any[], meta : any): LatestInscritosActionTypes => ({
  type: FETCH_LATEST_INSCRITOS_SUCCESS,
  payload: { 
    data:data,
    meta:meta
  },
});

export const fetchLatestInscritosFailureReducer = (error: string): LatestInscritosActionTypes => ({
  type: FETCH_LATEST_INSCRITOS_FAILURE,
  payload: error,
});

export const GetLatestInscritosReducer = (
  page: number = 1,
  limit: number = 10,
  sortColumn: string = 'id',
  sortOrder: 'asc' | 'desc' | null = 'asc'
): ThunkAction<Promise<any>, RootState, unknown, LatestInscritosActionTypes> => async (dispatch) => {
  dispatch(fetchLatestInscritosRequestReducer());

  try {
    const respuesta = await FetchWithIPGet(`empresas/findAlllastInscritosEmpresaByToken?page=${page}&limit=${limit}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`, {
      method: 'GET'
    });

    if (!respuesta.ok) {
      throw new Error(`Error HTTP: ${respuesta.status}`);
    }

    const responseData = await respuesta.json();

    if (Array.isArray(responseData.data)) {
      dispatch(fetchLatestInscritosSuccessReducer(responseData.data, responseData.meta));
      return { 
        payload: { 
          data: responseData.data, 
          meta: responseData.meta, // Devuelve también la meta para paginación
        } 
      };
    } else {
      throw new Error('Error en la respuesta del servidor');
    }
  } catch (error) {
    dispatch(fetchLatestInscritosFailureReducer('Error al obtener los datos'));
    return { payload: { data: [] } };
  }
};