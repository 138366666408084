import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Skeleton, Affix } from 'antd';
import CardEmpleo from '../../../components/cards/CardEmployment';
import Information from '../../../components/pages/employment/recommendations/Information';
import AlertFilter from '../../../components/pages/employment/recommendations/AlertFilter';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FetchEmploymentsReducer, GetOfferReducer } from '../../../redux/actions/offers/GetOffers';
import { AppDispatch } from '../../../redux/store/store';
import { SaveOfferReducer } from '../../../redux/actions/offers/SaveOffer';

// Environment variables
const BANNER_DEFAULT = process.env.REACT_APP_BANNER_EMPRESA_DEFAULT;
const IMAGE_USER_DEFAULT = process.env.REACT_APP_IMAGE_EMPRESA_DEFAULT;
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

const CardEmpleoExample: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    rex_data: rawCardsData,
    rex_loading,
    rex_error,
    rex_meta,

    rex_loading_offer,
    rex_data_offer
  } = useSelector(({ getOffers }: any) => getOffers);

  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);
  const [appliedCards, setAppliedCards] = useState<{ [key: number]: boolean }>({});
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false);
  const [hoveredCardIndex, setHoveredCardIndex] = useState<number | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [formattedData, setFormattedData] = useState<{ allProperties: any, informationProps: any }[]>([]);

  useEffect(() => {
    dispatch(FetchEmploymentsReducer());
  }, []);

  // Formatear los datos obtenidos desde el estado de Redux
  // const formattedData = rawCardsData


  useEffect(() => {
    const newData = rawCardsData?.map((job: any) => ({

      exampleProps: {
        companyName: job.empresa_id ? job.empresas.empresa : 'Empresa desconocida',
        jobTitle: job.cargo || 'Título desconocido',
        location: job.ubi_poblacion || 'Ubicación no disponible',
        salary: job.sal_max ? `${job.sal_min} - ${job.sal_max}€` : 'Salario no disponible',
        schedule: job.jornada_laboral || 'Horario no especificado',
        ratings: job.empresas._count.valoraciones_empresas + ' valoraciones',
        activeOffers: job.empresas._count.ofertas + ' ofertas activas',
        followers: job.empresas._count.empresa_seguida + ' Seguidores',
        description: job.descripcion || 'Descripción no disponible',
        postedTime: job.updatedAt && !isNaN(new Date(job.updatedAt).getTime())
          ? `Subida hace ${Math.floor((Date.now() - new Date(job.updatedAt).getTime()) / (1000 * 60 * 60))}h`
          : 'Fecha no disponible',
        applied: false,
        createdAt: job.createdAt
      },
      informationProps: {
        id: job.id,
        title: job.cargo || 'Título desconocido',
        company: job.empresa_id ? job.empresas.empresa : 'Empresa desconocida',
        location: job.ubi_poblacion || 'Ubicación no disponible',
        employmentType: job.jornada_laboral || 'Tipo de jornada no disponible',
        salary: job.sal_max ? `${job.sal_min} - ${job.sal_max}€` : 'Salario no disponible',
        comments: 0, // Este es estático
        applicantsCount: job._count.postulaciones + ' Inscritos', // Este es estático
        introText: "Auxilium comparo odit. Vado vomica laboriosam similique cattus teres dolorum video nostrum derideo. Cresco solum coniuratio pectus ventito cohibeo spiculum animi debitis.",
        requirements: [
          {
            title: "Estudios mínimos",
            requirement: job.estudios_minimos,
          },
          // {
          //   title: "Jornada laboral",
          //   requirement: job.jornada_laboral,
          // },
          // {
          //   title: "Tipo de contrato",
          //   requirement: job.tipo_contrato,
          // },
          {
            title: "Años de experiencia",
            requirement: job.anios_experiencia == 0 ? "Sin experiencia" : job.anios_experiencia,
          }
        ],
        responsibilities: [
          job.descripcion || 'Descripción no disponible'
        ],
        extraText: [
          '' // Estático
        ],
        extraText2: [
          'Muchos más te están esperando, ¡descúbrelos!'
        ],
        postedTime: job.updatedAt && !isNaN(new Date(job.updatedAt).getTime())
          ? `Subida hace ${Math.floor((Date.now() - new Date(job.updatedAt).getTime()) / (1000 * 60 * 60))}h`
          : 'Fecha no disponible',
        createdAt: job.createdAt
      },
      postulaciones_guardadas: job.postulaciones_guardadas,
      allProperties: job
    }));
    setFormattedData(newData)
  }, [rawCardsData])

  const fetchMoreData = () => {
    if (formattedData) {
      if (formattedData.length >= 6) {
        setHasMore(false);
        return;
      }
    }

    // Simular la carga de más datos
    setTimeout(() => {
      // Aquí puedes manejar el fetch de más datos si lo necesitas
    }, 1500);
  };

  const handleCardClick = (index: number) => {
    // setLoadingInfo(true);
    setSelectedCardIndex(index);
    dispatch(GetOfferReducer(rawCardsData[index].id))
    // setLoadingInfo(false);
  };

  const handleApply = (index: number | null) => {
    if (index !== null) {
      setAppliedCards((prev) => ({
        ...prev,
        [index]: !prev[index]
      }));
    }
  };

  if (rex_loading) {
    return <Skeleton active />;
  }

  if (rex_error) {
    return <p>Error al cargar las ofertas: {rex_error}</p>;
  }

  return (
    <div
      className='p-[20px]'
    >
      <AlertFilter
        newOffersCount={rex_meta?.total ?? 0}
        onFilterClick={() => alert('Filtro aplicado')}
        onQuickApplyClick={() => alert('Solicitud rápida aplicada')}
        onRatingsClick={(value: string) => alert(`Valoraciones: ${value} aplicadas`)}
        onProvinceClick={(value: string) => alert(`Provincia: ${value} seleccionada`)}
        onCreateAlertClick={() => alert('Nueva alerta creada')}
        newOffersText="Nuevas ofertas que se adaptan a ti"
        createAlertText="Crear nueva alerta"
        filterButtonText="Todos los filtros"
        quickApplyButtonText="Solicitudes rápidas"
        ratingsDefaultText="Valoraciones"
        provinceDefaultText="Provincia"
        ratingsOptions={[
          { value: '5-stars', label: '5 Estrellas' },
          { value: '4-stars', label: '4 Estrellas' },
          { value: '3-stars', label: '3 Estrellas' },
        ]}
        provinceOptions={[
          { value: 'madrid', label: 'Madrid' },
          { value: 'barcelona', label: 'Barcelona' },
          { value: 'valencia', label: 'Valencia' },
        ]}
      />

      <Row gutter={[16, 16]}>
        <Col xs={24} md={10} style={{ padding: 0, height: '100%' }}>
          <InfiniteScroll
            className='w-full pt-[2px] md:pl-[2px] pl-[8px] pr-[8px] box-border'
            dataLength={formattedData ? formattedData.length : 0}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div style={{ marginBottom: '16px' }}>
                <Skeleton
                  active
                  avatar={{ shape: 'square', size: 'large' }}
                  paragraph={{ rows: 3 }}
                />
              </div>
            }
            endMessage={<p>No hay más ofertas para mostrar</p>}
          >
            {formattedData?.map((card: any, index: number) => (
              <div
                key={index}
                className={
                  `card-container mb-[16px] rounded-lg
                  ${hoveredCardIndex === index
                    ? 'hovered'
                    : ''
                  } 
                  ${selectedCardIndex === index
                    ? 'selected md:shadow-shadow1'
                    : hoveredCardIndex === index
                      ? 'shadow-shadow1'
                      : 'shadow-shadow2'
                  } transition-shadow duration-300 ease-in-out`
                }
                onClick={() => {
                  handleCardClick(index)
                  console.log("Click lateral")
                  console.log(card.allProperties)
                  console.log(card?.allProperties.empresas.logo)
                  console.log("Click lateral")
                }}
                onMouseEnter={() => setHoveredCardIndex(index)}
                onMouseLeave={() => setHoveredCardIndex(null)}
              >
                <div className='hidden md:flex'>
                  <CardEmpleo
                    currentStep={0} {...card.exampleProps}
                    // applied={!!appliedCards[index]}
                    applied={card.allProperties.inscritoOfertaByUser}
                    styleType={card?.allProperties.ofertaGuardadaByUser ? "type3" : "default"}
                    saveOffer={false}
                    avatar={card?.allProperties?.empresas?.logo}
                  />
                </div>
                <div className='flex md:hidden'>
                  {selectedCardIndex === index
                    ? <Information
                      saved={rex_data_offer?.guardada}
                      applied={formattedData[selectedCardIndex].allProperties.inscritoOfertaByUser}
                      onApply={function (): void {
                      }}
                      onSave={async () => {
                        await dispatch(SaveOfferReducer(rawCardsData[selectedCardIndex].id))
                        await dispatch(FetchEmploymentsReducer(false));
                      }}
                      loading={rex_loading_offer}
                      {...formattedData[selectedCardIndex].informationProps}
                      sector={formattedData[selectedCardIndex].allProperties.sectores.sector}
                      aptitudes={formattedData[selectedCardIndex].allProperties.aptitudes_oferta}
                      avatar={formattedData[selectedCardIndex].allProperties.empresas?.logo}
                    />
                    : <CardEmpleo
                      currentStep={0} {...card.exampleProps}
                      // applied={!!appliedCards[index]}
                      applied={card.allProperties.inscritoOfertaByUser}
                      styleType={card?.allProperties.ofertaGuardadaByUser ? "type3" : "default"}
                      saveOffer={false}
                    />

                  }
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </Col>
        <Col xs={0} md={14} style={{ paddingLeft: '8px' }}>
          {selectedCardIndex !== null && formattedData ? (
            <Affix offsetTop={140}>
              <Information
                saved={rex_data_offer?.guardada}
                applied={formattedData[selectedCardIndex].allProperties.inscritoOfertaByUser}
                onApply={function (): void {
                  // throw new Error('FALTA FUNCION.');
                  // alert("Inscribiendo...")
                }}
                onSave={async () => {
                  await dispatch(SaveOfferReducer(rawCardsData[selectedCardIndex].id))
                  await dispatch(FetchEmploymentsReducer(false));
                }}
                loading={rex_loading_offer}
                {...formattedData[selectedCardIndex].informationProps}
                sector={formattedData[selectedCardIndex].allProperties.sectores.sector}
                aptitudes={formattedData[selectedCardIndex].allProperties.aptitudes_oferta}
                avatar={formattedData[selectedCardIndex].allProperties.empresas?.logo}
              />
            </Affix>
          ) : (
            <div
              className='py-[12px] px-[16px] rounded-md border border-solid border-gray5'
            >
              <p>Selecciona una oferta para ver más detalles</p>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CardEmpleoExample;