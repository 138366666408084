// src/redux/actions/pages/userRatings/GetUserRating.ts

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
  FETCH_USER_RATINGS_REQUEST,
  FETCH_USER_RATINGS_SUCCESS,
  FETCH_USER_RATINGS_FAILURE,
  UserRatingsActionTypes,
  UserRating,
} from '../../../../constants/company/rating/GetUserLinkRating';
import FetchWithIP from '../../utils/FetchHeaders';

// Action Creators
export const fetchUserRatingsRequest = (): UserRatingsActionTypes => ({
  type: FETCH_USER_RATINGS_REQUEST,
});

export const fetchUserRatingsSuccess = (data: UserRating): UserRatingsActionTypes => ({
  type: FETCH_USER_RATINGS_SUCCESS,
  payload: data,
});

export const fetchUserRatingsFailure = (error: string): UserRatingsActionTypes => ({
  type: FETCH_USER_RATINGS_FAILURE,
  payload: error,
});

// Thunk Action para obtener la valoración del usuario
export const GetUserRating = (link_valoracion: string): ThunkAction<Promise<any>, RootState, unknown, UserRatingsActionTypes> => async (dispatch) => {
  dispatch(fetchUserRatingsRequest());

  try {
    const response = await FetchWithIP(`valoraciones-usuarios/findByLinkValoracion/${link_valoracion}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    dispatch(fetchUserRatingsSuccess(responseData));
    return responseData; // Retornamos la respuesta para manejarla en el frontend
  } catch (error: unknown) {
    const errorMessage = error instanceof Error ? error.message : 'Error desconocido al obtener la valoración del usuario';
    dispatch(fetchUserRatingsFailure(errorMessage));
    return { error: errorMessage };
  }
};
