import {
  GET_ANSWERS_REQUEST,
  GET_ANSWERS_SUCCESS,
  GET_ANSWERS_FAILURE,
  GetAnswersActionTypes
} from '../../../../../constants/pages/searchCV/answers/GetAnswers';

interface GetAnswersState {
  rex_loading: boolean;
  rex_answers_killers_questions: any;
  rex_error: string | null;
}

const initialState: GetAnswersState = {
  rex_loading: false,
  rex_answers_killers_questions: [],
  rex_error: null,
};

export const GetAnswersReducer = (
  state = initialState,
  action: GetAnswersActionTypes
): GetAnswersState => {
  switch (action.type) {
    case GET_ANSWERS_REQUEST:
      return {
        ...state,
        rex_loading: true,
      };
    case GET_ANSWERS_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_answers_killers_questions: action.payload.data,
        rex_error: null,
      };
    case GET_ANSWERS_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload,
        rex_answers_killers_questions: [],
      };
    default:
      return state;
  }
};

export default GetAnswersReducer;