// src/actions/pages/myPortal/portfolio/getUserPortfolioActions.ts

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import {
  GET_USER_PORTFOLIO_REQUEST,
  GET_USER_PORTFOLIO_SUCCESS,
  GET_USER_PORTFOLIO_FAILURE,
  GetUserPortfolioActionTypes,
  Portfolio
} from '../../../../../constants/pages/myPortal/portfolio/GetPortfolio';
import FetchWithIP from '../../../utils/FetchHeaders';

export const getUserPortfolioRequestReducer = (): GetUserPortfolioActionTypes => ({
  type: GET_USER_PORTFOLIO_REQUEST,
});

export const getUserPortfolioSuccessReducer = (data: Portfolio[]): GetUserPortfolioActionTypes => ({
  type: GET_USER_PORTFOLIO_SUCCESS,
  payload: { data }, // Enviamos la data correctamente tipada
});

export const getUserPortfolioFailureReducer = (error: string): GetUserPortfolioActionTypes => ({
  type: GET_USER_PORTFOLIO_FAILURE,
  payload: error,
});

export const GetUserPortfolioReducer = (): ThunkAction<Promise<any>, RootState, unknown, GetUserPortfolioActionTypes> => async (dispatch) => {
  dispatch(getUserPortfolioRequestReducer());

  try {
    const response = await FetchWithIP('portafolios-usuarios/findPortafolioUsuarioByToken', {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    // Aquí ya recibimos un array de portafolios
    const portfolios: Portfolio[] = responseData.data.map((portfolio: any) => ({
      id: portfolio.id,
      usuario_id: portfolio.usuario_id,
      nombre: portfolio.nombre,
      nombre_archivo: portfolio.nombre_archivo,
      portafolio: portfolio.portafolio,
      default: portfolio.default,
      titulo: portfolio.titulo,
      descripcion: portfolio.descripcion,
      url: portfolio.url,
      createdAt: portfolio.createdAt,
      updatedAt: portfolio.updatedAt,
      soft_skills_portafolio: portfolio.soft_skills_portafolio.map((skill: any) => ({
        id: skill.id,
        soft_skill_id: skill.soft_skill_id,
        portafolio_usuario_id: skill.portafolio_usuario_id,
        soft_skills: {
          id: skill.soft_skills.id,
          soft_skill: skill.soft_skills.soft_skill,
          aprobado: skill.soft_skills.aprobado,
          createdAt: skill.soft_skills.createdAt,
          updatedAt: skill.soft_skills.updatedAt,
        },
      })),
      archivos_portafolio: portfolio.archivos_portafolio.map((archivo: any) => ({
        id: archivo.id,
        portafolio_usuario_id: archivo.portafolio_usuario_id,
        titulo: archivo.titulo,
        nombre_archivo: archivo.nombre_archivo,
        archivo: archivo.archivo,
        createdAt: archivo.createdAt,
        updatedAt: archivo.updatedAt,
      })),
    }));

    dispatch(getUserPortfolioSuccessReducer(portfolios)); // Enviamos los portfolios mapeados
    return portfolios;
  } catch (error: any) {
    dispatch(getUserPortfolioFailureReducer(error.message || 'Error al obtener el portafolio del usuario'));
    return { error: error.message };
  }
};
