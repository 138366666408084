export const FETCH_EMPRESAS_BY_SECTOR_REQUEST = 'FETCH_EMPRESAS_BY_SECTOR_REQUEST';
export const FETCH_EMPRESAS_BY_SECTOR_SUCCESS = 'FETCH_EMPRESAS_BY_SECTOR_SUCCESS';
export const FETCH_EMPRESAS_BY_SECTOR_FAILURE = 'FETCH_EMPRESAS_BY_SECTOR_FAILURE';

export interface FetchEmpresasBySectorRequestAction {
  type: typeof FETCH_EMPRESAS_BY_SECTOR_REQUEST;
}

export interface FetchEmpresasBySectorSuccessAction {
  type: typeof FETCH_EMPRESAS_BY_SECTOR_SUCCESS;
  payload: { data: any[] };
}

export interface FetchEmpresasBySectorFailureAction {
  type: typeof FETCH_EMPRESAS_BY_SECTOR_FAILURE;
  payload: string;
}

export type EmpresasBySectorActionTypes =
  | FetchEmpresasBySectorRequestAction
  | FetchEmpresasBySectorSuccessAction
  | FetchEmpresasBySectorFailureAction;
